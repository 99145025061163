import React from 'react';
import { CSSReset, ThemeProvider } from '@chakra-ui/core';
import { ApolloProvider } from '@apollo/client';
import { Global } from '@emotion/core';

import AppRouter from './pages/AppRouter';
import apolloClient from './core/apolloClient';
import Firebase from './core/firebase';
import FirebaseContext from './core/firebaseContext';
import theme from './variables/theme';

const App = () => (
  <FirebaseContext firebase={new Firebase()}>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        {/*
          * @TODO - Need to check, how to consume theme values
            with non-chakra elements
          */}
        <Global
          styles={{
            body:
              { backgroundColor: '#FFFFFF', width: '100%', height: '100%' },
          }}
        />
        <CSSReset />
        <AppRouter />
      </ThemeProvider>
    </ApolloProvider>
  </FirebaseContext>
);

export default App;
