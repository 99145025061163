/* eslint-disable indent */
import React, { memo, useEffect, useCallback, useState } from 'react';
import { Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import {
  Grid,
  Flex,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  Avatar,
  MenuGroup,
} from '@chakra-ui/core';
import { useQuery } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  Button,
  ButtonTypes,
  Loader,
  LoaderTypes,
  Logout,
  Stepper,
  Text,
} from '../../components';
import PrivateRoute from '../../core/privateRoute';
import {
  OnboardingStepStatus,
  useAdvanceCustomerMutation,
  useGetBrandStatusQuery,
  useGetCustomerQuery,
} from '../../graphql';

import CSVUpload from './CSVUpload';
import SellerApproval from './SellerApproval';
import LetterBuilder from './LetterBuilder';
import TermsAndCondition from './TermsAndCondition';
import DownloadDocuments from './DownloadDocuments';
import { OnboardingUIRoute } from '../../variables/types';
import { getUserDetails, handleSignOut } from '../../utils/auth';
import {
  GET_NAVIGATION_BLOCK_VAR, navigationBlockedPayload as handleNavigationBlocked,
} from '../../variables/globalVariables';
import getErrorMessage from '../../utils/getErrorMessage';
import { DOCUMENTS_DOWNLOAD_ROUTE } from '../../variables/contants';
import { getBrandID } from '../../utils/files';

// Available routes/steps for onboarding process.
export const OnBoardingSubRoutes = [
  {
    id: OnboardingUIRoute.Products,
    path: '/onboarding/csv-upload',
    component: CSVUpload,
    title: 'Products',
  },
  {
    id: OnboardingUIRoute.Messaging,
    path: '/onboarding/letter-builder',
    component: LetterBuilder,
    title: 'Messaging',
  },
  {
    id: OnboardingUIRoute.Sellers,
    path: '/onboarding/seller-approval',
    component: SellerApproval,
    title: 'Sellers',
  },
  {
    id: OnboardingUIRoute.Terms,
    path: '/onboarding/terms-and-condition',
    component: TermsAndCondition,
    title: 'Terms',
  },
];
/**
 * Adding this spacing to tweak stepper component's position and to handle
 * proper relativeness of elements.
*/
export const LEFT_SECTION_MAX_WIDTH = '500px';

const Onboarding: React.FC = (_) => {
  const currentLocation = useLocation();
  const history = useHistory();
  const [stepperStatus, setStepperStatus] = useState({
    products: false,
    messaging: false,
    sellers: false,
    terms: false,
  });
  const [
    advanceUser,
    { loading: advancingUserLoader }] = useAdvanceCustomerMutation();
  const userDetails = getUserDetails();
  const {
    customerUser,
    userRoles: { edges: userRoleEdges },
  } = userDetails;
  const [userRoleNode] = userRoleEdges;
  const role = userRoleNode?.node?.role;
  const { firstName, lastName } = userRoleNode?.node?.user;
  const customerId = customerUser?.customer?.id;
  const {
    data: customerData,
    loading,
    // error: customerError,
  } = useGetCustomerQuery({ variables: { id: customerId } });

  const {
    data: brandStatusData,
    refetch,
  } = useGetBrandStatusQuery({
    variables: { id: getBrandID() },
    fetchPolicy: 'network-only',
  });

  const toast = useToast();

  const { data: navigationBlockedData } = useQuery(GET_NAVIGATION_BLOCK_VAR);
  const {
    navigationBlockedPayload: { isNavigationBlocked, navigationBlockMessage },
  } = navigationBlockedData;
  const getActiveStepNumber = useCallback(
    (activePath: string) => {
      const onboardingStep = OnBoardingSubRoutes.findIndex(
        (subRoutes) => subRoutes.path === activePath,
      );

      return onboardingStep >= 0 ? onboardingStep : 0;
    }, [],
  );

  const [activeStepperStep, setActiveStepperStep] = useState<number>(
    getActiveStepNumber(currentLocation.pathname),
  );

  const handleSignout = async () => {
    await handleSignOut();
    history.push('/auth');
  };

  const handleCheckOnboardingPath = (currentPath: string): boolean => {
    const isOnboardingPath = OnBoardingSubRoutes
      .find((route) => route.path === currentPath);
    return Boolean(isOnboardingPath);
  };

  const getBrandStatus = useCallback(() => {
    if (brandStatusData?.brand) {
      const {
        addedProducts,
        approvedImpactLetter,
        letterheadUploaded,
        sellersFound,
      } = brandStatusData.brand;
      OnBoardingSubRoutes.forEach((route) => {
        switch (route.id) {
          case 'products': {
            setStepperStatus((prevStepperStatus) => ({
              ...prevStepperStatus,
              products: addedProducts!,
            }));
            break;
          }
          case 'messaging': {
            setStepperStatus((prevStepperStatus) => ({
              ...prevStepperStatus,
              messaging: approvedImpactLetter!
              && letterheadUploaded!,
            }));
            break;
          }
          case 'sellers': {
            setStepperStatus((prevStepperStatus) => ({
              ...prevStepperStatus,
              sellers: sellersFound!,
            }));
            break;
          }
          case 'terms': {
            if (history.location.pathname === DOCUMENTS_DOWNLOAD_ROUTE) {
              setStepperStatus((prevStepperStatus) => ({
                ...prevStepperStatus,
                terms: addedProducts!
                && approvedImpactLetter! && letterheadUploaded!
                && sellersFound!,
              }));
            } else {
              setStepperStatus((prevStepperStatus) => ({
                ...prevStepperStatus,
                terms: false,
              }));
            }
            break;
          }
          default:
            break;
        }
      });
    }
  }, [brandStatusData, history.location.pathname]);

  useEffect(() => {
    getBrandStatus();
  }, [getBrandStatus]);

  useEffect(() => {
    if (!loading) {
      if (
        customerData?.customer?.customerOnboardingStep
        ?.status === OnboardingStepStatus.Completed
      ) {
        history.push(DOCUMENTS_DOWNLOAD_ROUTE);
      }
      if (customerData?.customer?.customerOnboardingStep
        ?.status !== OnboardingStepStatus.Completed && history.location
          .pathname === DOCUMENTS_DOWNLOAD_ROUTE) {
        history.goBack();
      }
      if ((customerData?.customer?.customerOnboardingStep
        ?.status !== OnboardingStepStatus.Completed)
        && !handleCheckOnboardingPath(history.location.pathname)) {
        history.goBack();
      }
    }
  }, [
    customerData,
    history,
    /*
     * Added the below line to run this 'useEffect' when path changes
     */
    history.location.pathname,
    loading,
  ]);

  const handleNextStep = useCallback(async () => {
    try {
      if (isNavigationBlocked) {
        history.push(OnBoardingSubRoutes[activeStepperStep + 1].path);
      } else {
        const nextstep = OnBoardingSubRoutes[
          activeStepperStep + 1].title.toLowerCase();
        // Update the active step and push new route for stepper.
        setActiveStepperStep((prevStep) => prevStep + 1);
        history.push(OnBoardingSubRoutes[activeStepperStep + 1].path);
        await advanceUser({
          variables: {
            input: {
              id: customerId,
              step: nextstep,
            },
          },
        });
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: getErrorMessage(error),
        status: 'error',
      });
    }
  }, [
    advanceUser,
    customerId,
    toast,
    history,
    activeStepperStep,
    isNavigationBlocked,
  ]);

  const handleSelectStep = useCallback(
    async (step: number, browserNavigation?: boolean,
    ) => {
      const nextstep = OnBoardingSubRoutes[step].title.toLowerCase();
      try {
        if (isNavigationBlocked) {
          history.push(OnBoardingSubRoutes[step].path);
        } else {
          setActiveStepperStep(step);
          !browserNavigation && history.push(OnBoardingSubRoutes[step].path);
          await advanceUser({
            variables: {
              input: {
                id: customerId,
                step: nextstep,
              },
            },
          });
        }
      } catch (error) {
        toast({
          title: 'Error!',
          description: getErrorMessage(error),
          status: 'error',
        });
      }
    }, [advanceUser, customerId, history, toast, isNavigationBlocked],
  );

  useEffect(() => {
    /*
     * This 'useEffect' block was written to handle Stepper UI when browser
     * back/forward buttons are pressed.
     */

    // console.log('onboarding');
    history.listen((location, action) => {
      if (action === 'POP') {
        if (handleCheckOnboardingPath(location.pathname)) {
          const activeStepIndex = getActiveStepNumber(location.pathname);
          handleSelectStep(activeStepIndex, true);
        }
      }
    });
  }, [
    history,
    getActiveStepNumber,
    handleSelectStep,
  ]);

  const renderPromptForNavigationBlock = useCallback(
    (blockNavigation: boolean, message: string) => (
      <NavigationPrompt
        // call the callback function when finished cleaning up
        // beforeConfirm={(clb)=>this.cleanup(clb)}
        // Children will be rendered even if props.when is falsey and isActive
        // is false:
        // renderIfNotActive={true}
        // Confirm navigation if going to a path that does not start with
        // current path:
        when={(crntLocation, nextLocation) => (
          (!nextLocation
            || !nextLocation.pathname.startsWith(crntLocation.pathname))
          && blockNavigation
        )}
        afterConfirm={() => {
          handleNavigationBlocked({
            isNavigationBlocked: false,
            navigationBlockMessage: '',
          });
          const activeStep = OnBoardingSubRoutes
            .findIndex((route) => route.path === history.location.pathname);
          setActiveStepperStep(activeStep);
        }}
        afterCancel={() => {
          const activeStep = OnBoardingSubRoutes
            .findIndex((route) => route.path === history.location.pathname);
          setActiveStepperStep(activeStep);
        }}
      >
        {({ isActive, onCancel, onConfirm }) => {
          if (isActive) {
            return (
              <Modal
                isOpen
                onClose={onCancel}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                // scrollBehavior="inside"
              >
                <ModalOverlay />
                <ModalContent
                  maxHeight="35vh"
                  width="30vw"
                >
                  <ModalHeader textAlign="center">Are you Sure ?</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Text>
                      {message}
                    </Text>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      buttonType={ButtonTypes.Link}
                      onClick={onCancel}
                      width="100%"
                      maxWidth={400}
                    >
                      Cancel
                    </Button>
                    <Button
                      buttonType={ButtonTypes.Primary}
                      onClick={onConfirm}
                      width="100%"
                      maxWidth={400}
                    >
                      Confirm
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            );
          }
          return <div>This is probably an anti-pattern but ya know...</div>;
        }}
      </NavigationPrompt>
    ),
    [history.location.pathname],
  );

  if (!role || (role?.name !== 'Customer')) {
    return (
      <Redirect
        to="/auth"
      />
    );
  }

  if (loading) {
    return <Loader type={LoaderTypes.FullViewModal} large />;
  }
  return (
    <Grid
      position="relative"
      gridTemplateColumns={['100%', '480px 1fr', '560px 1fr', '650px 1fr']}
      gridTemplateRows="100px 1fr"
      width="100%"
      justifyItems="center"
      gridGap={4}
    >
      {
        activeStepperStep > -1 && (
          <>
            <Flex
              justifyContent="center"
              margin="auto"
              width="100%"
              padding={8}
              maxWidth={LEFT_SECTION_MAX_WIDTH}
              style={{
                gridRowStart: 1,
                gridRowEnd: 1,
                gridColumnStart: 1,
                gridColumnEnd: 1,
              }}
            >
              <Stepper
                steps={
                OnBoardingSubRoutes.map(
                  (onBoardingRoutes) => ({
                    title: onBoardingRoutes.title,
                    path: onBoardingRoutes.path,
                    isCompleted: stepperStatus[onBoardingRoutes.id],
                  }),
                )
              }
                enableStepSwitching
                handleSelectStep={handleSelectStep}
                activeStep={activeStepperStep}
              /**
               * @TODO - Need to replace this with the onboarding
               *  status instead of route matching.
               * This will be done once we have completed onboarding
               *  api call in place
              */
                forceCompletedSteps={
                currentLocation.pathname === DOCUMENTS_DOWNLOAD_ROUTE
              }
              />
              {/* <Button
                marginLeft={10}
                buttonType={ButtonTypes.Primary}
                onClick={handleSignout}
              >
                Logout
              </Button> */}
            </Flex>
          </>
        )
      }
      <Switch>
        {
          OnBoardingSubRoutes.map((subRoute) => (
            <PrivateRoute
              path={subRoute.path}
              component={(
                <subRoute.component
                  handleNextStep={handleNextStep}
                  advancingStepLoader={advancingUserLoader}
                  refetchBrandStatus={refetch}
                />
              )}
              key={subRoute.path}
            />
          ))
        }
        <PrivateRoute
          path={DOCUMENTS_DOWNLOAD_ROUTE}
          component={DownloadDocuments}
        />
        <Redirect
          from="/onboarding"
          exact
          to="/onboarding/csv-upload"
        />
      </Switch>
      {renderPromptForNavigationBlock(
        isNavigationBlocked, navigationBlockMessage,
      )}
      <Logout
        handler={handleSignout}
        title={`${firstName} ${lastName}`.toUpperCase()}
        styles={{
          position: 'absolute',
          top: '1%',
          right: '1%',
          padding: '2rem',
          width: '100px',
        }}
      />
      <Flex
        position="absolute"
        top="1%"
        right="1%"
        padding="2rem"
        width="100px"
      >
        <Menu>
          <MenuButton>
            <Avatar
              size="sm"
              name={`${firstName} ${lastName}`}
              backgroundColor="activePrimaryColor"
            />
          </MenuButton>
          <MenuList placement="bottom">
            <MenuGroup
              title={`${firstName} ${lastName}`.toUpperCase()}
            >
              <MenuItem
                onClick={handleSignout}
              >
                Logout
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Flex>
    </Grid>
  );
};

export default memo(Onboarding);
