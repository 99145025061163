import React, { memo } from 'react';
import {
  Box, FormControl, FormHelperText,
  Icon, Flex, useToast,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { useLocation, RouteComponentProps } from 'react-router-dom';
import { SplitWithImage, Input, FormLabel, Button } from '../../components';

import { useFirebaseProvider } from '../../core/firebaseContext';
import { ButtonTypes } from '../../components/button';
import { EMAIL_VALIDATION_PATTERN } from '../../utils/regex';
import { EMAIL_REQUIRED, INVALID_EMAIL } from '../../variables/contants';

interface IForgotPassword extends RouteComponentProps {}

const ForgotPassword: React.FC<IForgotPassword> = (
  _: IForgotPassword,
) => {
  const currentLocation = useLocation<{ email: string }>();

  const { register, handleSubmit, errors } = useForm();
  const { firebase } = useFirebaseProvider();
  const toast = useToast();

  const handleFormSubmit = async (formValues:Record<string, string>) => {
    try {
      await firebase.sendPasswordResetEmail(formValues.email);
      toast({
        title: 'Forgot Password',
        description: 'Reset link has been sent on the email address',
        status: 'success',
      });
    } catch (err) {
      toast({
        title: 'Forgot Password',
        description: err.message,
        status: 'error',
      });
    }
  };

  return (
    <Box>
      <SplitWithImage>
        <Flex
          direction="column"
          justifyContent="space-evenly"
          maxHeight={700}
          maxWidth={400}
          margin="auto"
          height="100%"
          width="100%"
        >
          <Icon
            name="app"
            marginTop={10}
            marginBottom={10}
            width="auto"
            height="auto"
          />
          <Box flexGrow={1}>
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              noValidate
            >
              <FormControl marginBottom={4}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  fontSize="md"
                  name="email"
                  type="email"
                  id="email"
                  aria-describedby="email-helper-text"
                  ref={
                    register({
                      required: { value: true, message: EMAIL_REQUIRED },
                      pattern: {
                        value: EMAIL_VALIDATION_PATTERN,
                        message: INVALID_EMAIL,
                      },
                    })
                  }
                  defaultValue={currentLocation.state?.email || ''}
                />
                {
                    errors?.email && (
                    <FormHelperText fontSize="xs" id="email-helper-text">
                      {errors.email.message}
                    </FormHelperText>
                  )
                }
              </FormControl>
              <FormControl marginBottom={8}>
                <Button
                  width="100%"
                  type="submit"
                  buttonType={ButtonTypes.Primary}
                  marginBottom={4}
                >
                  RESET PASSWORD
                </Button>
              </FormControl>
            </form>
          </Box>
        </Flex>
      </SplitWithImage>
    </Box>
  );
};

export default memo(ForgotPassword);
