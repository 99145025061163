import React, { memo, useEffect } from 'react';
import {
  Box,
  Icon,
  Flex,
  useToast,
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import {
  SplitWithImage, Button,
  ButtonTypes, Text, TextTypes, Loader, LoaderTypes,
} from '../../../components';
import { ICommonStepsPropInterface } from '../interfaces';
import { useGetOnboardingFilesUrlsQuery } from '../../../graphql';
import { getBrandID } from '../../../utils/files';
import getErrorMessage from '../../../utils/getErrorMessage';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  grid-column-start: 1;
  grid-row-start: 2;
  padding: 1rem;
  max-width: 500px;
`;

const DownloadDocuments :React.FC<ICommonStepsPropInterface> = (

  // eslint-disable-next-line no-unused-vars
  props: ICommonStepsPropInterface,
) => {
  const {
    data: files,
    loading: filesLoading,
    error: filesError,
  } = useGetOnboardingFilesUrlsQuery({
    variables: { brandId: getBrandID() },
    fetchPolicy: 'network-only',
  });
  const toast = useToast();

  useEffect(() => {
    if (filesError) {
      toast({
        title: 'Error!',
        description: getErrorMessage(filesError),
        status: 'error',
      });
    }
  }, [filesError, toast]);

  return (
    <SplitWithImage withGrid={{
      enable: true,
      imageWrapperStyles: {
        gridRowStart: 1,
        gridRowEnd: 3,
        width: '100%',
      },
    }}
    >
      <>
        <StyledWrapper>
          <Box
            maxHeight="100%"
            width="100%"
          >
            <Text
              marginBottom={4}
              type={TextTypes.header}
            >
              You are all done!
            </Text>
            <Text
              marginBottom={5}
            >
              Congratulations!
            </Text>
            <Text
              marginY={5}
            >
              At this very moment we are processing your information and
              identifying unauthorized sellers of your brand.
            </Text>
            <Text
              marginY={5}
            >
              We will be reaching out to you soon to schedule a call to discuss
              next steps and answer any questions you might have.
            </Text>
            <Text
              marginY={5}
            >
              Welcome to the Gray Falkon family!
            </Text>
            {files?.getOnboardingFilesUrls
            && files?.getOnboardingFilesUrls?.length > 0
            && files?.getOnboardingFilesUrls.map((file: any, index: any) => (
              <Flex align="center" key={index}>
                <Flex align="left" direction="column" width="50%">
                  <Text
                    type={TextTypes.tertiaryHeader}
                    textTransform="capitalize"
                  >
                    {file?.fileName}
                  </Text>
                </Flex>
                <Flex align="left" direction="column">
                  <Button

                    paddingY={3}
                    paddingLeft={0}
                    paddingRight={3}
                    backgroundColor="red"
                    position="relative"
                    bottom={1}
                    onClick={() => {
                        file?.signedUrl
                        && window.open(file?.signedUrl, '_blank');
                    }}
                    leftIcon={
                      () => (
                        <Icon
                          size="5"
                          name="csvDownloadOrange"
                          marginRight={1}
                        />
                      )
                    }
                    textTransform="capitalize"
                    buttonType={ButtonTypes.PrimaryLink}
                  >
                    {file?.fileName}
                  </Button>
                </Flex>
              </Flex>
            ))}
          </Box>
        </StyledWrapper>
        {
          filesLoading
        && (
          <Loader
            type={LoaderTypes.FullViewModal}
            large
            message="Fetching documents for download. Please wait."
          />
        )
        }
      </>
    </SplitWithImage>
  );
};
export default memo(DownloadDocuments);
