import React, { memo, useState, useEffect, useCallback } from 'react';
import {
  Box,
  /* Icon, */
  FormHelperText,
  FormControl,
  /* Tooltip, */
  useToast,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { ICommonStepsPropInterface, ISeller } from '../interfaces';
import {
  Input, FormLabel, Button,
  ButtonTypes, Text, TextTypes, Loader, LoaderTypes,
} from '../../../components';
import StoreDetails from './StoreDetails';
import {
  INVALID_EMAIL,
  SELLER_EMAIL_REQUIRED,
  SELLER_SCRAPE_MESSAGE,
} from '../../../variables/contants';
import {
  useCreateSellerMutation,
  useFetchRecentBrandTaskQuery,
  useFetchSellersOfABrandQuery,
  useUpdateSellerMarketplaceMutation,
} from '../../../graphql';
import { getBrandID } from '../../../utils/files';
import { EMAIL_VALIDATION_PATTERN } from '../../../utils/regex';
import getErrorMessage from '../../../utils/getErrorMessage';

const SellerApproval: React.FC<ICommonStepsPropInterface> = (
  {
    advancingStepLoader,
    handleNextStep,
    refetchBrandStatus,
  }: ICommonStepsPropInterface,
) => {
  const [sellers, setSellers] = useState<ISeller[]>([]);
  const brandId = getBrandID();
  const {
    data: sellerData,
    loading: sellersLoading,
    error: sellersError,
    refetch: refetchSellerData,
    fetchMore: sellerFetchMore,
  } = useFetchSellersOfABrandQuery({
    variables: {
      id: brandId,
      first: 10,
    },
    // fetchPolicy: 'cache-and-network',
    // nextFetchPolicy: 'cache-first',
  });
  const [createSeller] = useCreateSellerMutation();
  const [updateSellerMarketplace] = useUpdateSellerMarketplaceMutation();
  const {
    data,
    stopPolling,
    loading: brandTaskLoading,
    error: fetchRecentBrandTaskError,
  } = useFetchRecentBrandTaskQuery({
    variables: {
      id: brandId,
    },
    fetchPolicy: 'network-only',
    pollInterval: 15000,
  });

  // @TODO - Need to work on adding handling of more edge cases.
  const scrapingCompleted = (
    data?.brand?.brandTasks?.edges.length === 0
     || data?.brand?.brandTasks?.edges[0]?.node?.status === 'COMPLETE'
  );

  useEffect(() => {
    if (scrapingCompleted) {
      stopPolling();
      refetchSellerData();
    }
    return (): void => stopPolling();
  }, [scrapingCompleted, refetchSellerData, stopPolling]);

  const handleContainerOnBottom = useCallback(() => {
    if (sellerData?.brand?.sellerMarketplaces?.pageInfo?.hasNextPage) {
      sellerFetchMore({
        variables: {
          after: sellerData
            .brand?.sellerMarketplaces?.pageInfo.endCursor,
        },
        // TODO: Replace 'updateQuery' with field-policy in apolloClient.ts file
        updateQuery: (previousResult: any, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult?.brand?.sellerMarketplaces?.edges;
          const pageInfo = fetchMoreResult
            ?.brand?.sellerMarketplaces?.pageInfo;
          return newEdges?.length
            ? {
              brand: {
                id: previousResult?.brand?.id,
                __typename: previousResult?.brand?.__typename,
                sellerMarketplaces: {
                  __typename: previousResult?.brand?.__typename,
                  edges: [
                    ...previousResult?.brand?.sellerMarketplaces?.edges,
                    ...newEdges,
                  ],
                  pageInfo,
                },
              },
            }
            : previousResult;
        },
      });
    }
  }, [sellerData, sellerFetchMore]);

  const { register, handleSubmit, errors, reset } = useForm<{
    sellerName: string,
    sellerEmail: string,
    sellerExternalId: string
  }>();
  const toast = useToast();

  const getSellers = useCallback(() => {
    if (sellerData) {
      /* Using non-null assertion operator (!) for values that
        we are sure of will be received from the back-end.
      */
      const {
        edges: sellerEdges,
      } = sellerData?.brand?.sellerMarketplaces!;
      const sellersPayload = sellerEdges.map((seller) => (
        {
          sellerId: seller?.node?.seller?.id!,
          sellerMarketplaceId: seller?.node?.id!,
          sellerName: seller?.node?.seller?.name || '',
          isAuthorized: seller?.node?.authorized || false,
          contactSeller: seller?.node?.contactSeller || false,
        }
      ));
      setSellers(sellersPayload);
    }
  }, [sellerData]);

  const authorizeSeller = async (seller: ISeller) => {
    const {
      sellerMarketplaceId,
      sellerName,
      contactSeller,
      isAuthorized,
    } = seller;
    const authorizedOrNot = isAuthorized ? 'authorized' : 'unauthorized';
    setSellers(
      (prevSellers) => prevSellers
        .map((item) => (
          item.sellerMarketplaceId === seller.sellerMarketplaceId
            ? seller
            : item
        )),
    );
    try {
      await updateSellerMarketplace({
        variables: {
          input: {
            id: sellerMarketplaceId,
            authorized: isAuthorized,
            contactSeller,
          },
        },
      });
      toast({
        title: `Seller ${sellerName} Updated!`,
        description: `${sellerName} ${authorizedOrNot} successfully`,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error!',
        description: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  const toggleContactSeller = async (seller: ISeller) => {
    const {
      sellerMarketplaceId,
      sellerName,
      contactSeller,
      isAuthorized,
    } = seller;
    const contactSellerOrNot = contactSeller
      ? 'will be contacted'
      : 'won\'t be contacted';
    setSellers(
      (prevSellers) => prevSellers
        .map((item) => (
          item.sellerMarketplaceId === seller.sellerMarketplaceId
            ? seller
            : item
        )),
    );
    try {
      await updateSellerMarketplace({
        variables: {
          input: {
            id: sellerMarketplaceId,
            authorized: isAuthorized,
            contactSeller,
          },
        },
      });
      toast({
        title: `Seller ${sellerName} Updated!`,
        description: `${sellerName} ${contactSellerOrNot}`,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error!',
        description: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  const addAuthorizedSeller = async (formValues: Record<string, string>) => {
    try {
      await createSeller({
        variables: {
          input: {
            brandId: getBrandID(),
            name: formValues.sellerName,
            email: formValues.sellerEmail,
            externalId: formValues.sellerExternalId,
          },
        },
      });
      reset();
      toast({
        title: 'Seller Created Successfully!',
        description: 'Authorized seller created.',
        status: 'success',
      });
      refetchBrandStatus();
      refetchSellerData();
    } catch (error) {
      toast({
        title: 'Error!',
        description: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  useEffect(() => {
    getSellers();
  }, [getSellers]);

  if (sellersError) {
    toast({
      title: 'Error!',
      description: sellersError.message,
      status: 'error',
    });
  }

  if (brandTaskLoading) {
    return <Loader type={LoaderTypes.FullViewModal} large />;
  }

  return (
    <>
      {!scrapingCompleted && fetchRecentBrandTaskError && (
        <Loader
          type={LoaderTypes.FullViewModalNoSpinner}
          message={`Something went wrong while we were
          checking for scraping status. Please try again.`}
        />
      )}
      {!scrapingCompleted && (
        <Loader
          type={LoaderTypes.FullViewModal}
          message={SELLER_SCRAPE_MESSAGE}
          large
        />
      )}
      <Box style={{
        gridColumnStart: 1,
        padding: '0 16px',
        margin: '0 auto',
        maxWidth: '500px',
      }}
      >
        <Text
          marginBottom={6}
          type={TextTypes.header}
        >
          Verify which storefronts are authorized to sell your products.
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          Our technology has run an initial marketplace analysis.
          Based on the uploaded information we generated a list of
          storefronts currently selling your products.
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          Verify which storefronts are authorized to sell your products;
          storefronts that are not selected will be flagged
          as illegitimate and action will be taken.
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          Have a seller who is unauthorized but you don&#39;t want messaged?
          No problem. Select the &quot;Don&#39;t Contact Seller&quot; option
          from the additional options menu.
          &quot;Don&#39;t Contact Seller&quot; means the seller doesn&#39;t
          receive any
          messages from us nor have
          policy violations submitted against them unless you tell us otherwise.
        </Text>
        <Text
          marginY={6}
          type={TextTypes.secondaryHeader}
        >
          Don’t see an authorized seller?
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          If a known authorized seller/storefront isn&#39;t listed,
          don&#39;t stress. It just means they aren&#39;t
          currently selling on the ASINs provided.
          Provide their information below and they will be added to the list.
        </Text>
        <Box flexGrow={1} paddingY={4}>
          <form
            onSubmit={handleSubmit(addAuthorizedSeller)}
            noValidate
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <FormControl marginBottom={4} isRequired>
              <FormLabel htmlFor="sellerName" aria-required>
                Seller Name
              </FormLabel>
              <Input
                name="sellerName"
                type="text"
                id="sellerName"
                aria-describedby="seller-name-helper-text"
                ref={
                  register({
                    required: true,
                  })
                }
              />
              {
                errors.sellerName && (
                  <FormHelperText fontSize="xs" id="seller-name-helper-text">
                    *Seller Name is required
                  </FormHelperText>
                )
              }
            </FormControl>
            <FormControl marginBottom={4} isRequired>
              <FormLabel htmlFor="sellerExternalId" aria-required>
                Seller ID
                {/* TODO: Commenting out Tooltip and Icon until we confirm
                  * whether to keep Seller Id required or mandatory.
                 */}
                {/* <Tooltip
                  aria-label="Don’t know the ID of an authorized seller?
                  That’s alright, we will do our best to
                  track it down on our end."
                  label="Don’t know the ID of an authorized seller?
                  That’s alright, we will do our best to
                  track it down on our end."
                  placement="top"
                >
                  <Icon name="question" marginLeft={1} />
                </Tooltip> */}
              </FormLabel>
              <Input
                name="sellerExternalId"
                type="text"
                id="sellerExternalId"
                aria-describedby="seller-id-helper-text"
                ref={
                  register({
                    required: true,
                  })
                }
              />
              {
                errors.sellerExternalId && (
                  <FormHelperText fontSize="xs" id="seller-name-helper-text">
                    *Seller Id is required
                  </FormHelperText>
                )
              }
            </FormControl>
            <FormControl marginBottom={6} isRequired>
              <FormLabel htmlFor="sellerEmail" aria-required>
                Seller Email
              </FormLabel>
              <Input
                name="sellerEmail"
                type="email"
                id="sellerEmail"
                aria-describedby="seller-email-helper-text"
                ref={
                  register({
                    required: { value: true, message: SELLER_EMAIL_REQUIRED },
                    pattern: {
                      value: EMAIL_VALIDATION_PATTERN,
                      message: INVALID_EMAIL,
                    },
                  })
                }
              />
              {
                errors.sellerEmail && (
                  <FormHelperText fontSize="xs" id="seller-email-helper-text">
                    {errors.sellerEmail.message}
                  </FormHelperText>
                )
              }
            </FormControl>
            <Button
              isDisabled={sellersLoading}
              type="submit"
              buttonType={ButtonTypes.PrimaryOutline}
              marginBottom={6}
              width={100}
            >
              Add
            </Button>
          </form>
        </Box>
      </Box>
      <StoreDetails
        onLoadMore={handleContainerOnBottom}
        sellers={sellers}
        isLoading={sellersLoading}
        authorizeSeller={authorizeSeller}
        toggleContactSeller={toggleContactSeller}
        handleNextStep={handleNextStep}
        advancingUserLoader={advancingStepLoader}
      />
    </>
  );
};

export default memo(SellerApproval);
