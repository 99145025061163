// eslint-disable-next-line import/prefer-default-export
export enum NotifiierType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export enum OnboardingUIRoute {
  Products = 'products',
  Messaging = 'messaging',
  Sellers = 'sellers',
  Terms = 'terms'
}

export type User = {
  first_name: String
  last_name: String
  user_name: String
  role: String
  id: String
};

export enum TypeOfLetterBuilderTabs {
  Review = 'review',
  LetterBuilder = 'letterBuilder',
}
