import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router, Route,
  Switch, Redirect,
} from 'react-router-dom';

import ForgotPassword from './ForgotPassword';
import Auth from './Auth';
import PrivateRoute from '../core/privateRoute';
import { Loader, LoaderTypes } from '../components';
import { refreshAuthentication } from '../utils/auth';
import Onboarding from './OnBoarding';
import AdminDashboard from './Admin';

const AppRouter = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const checkAuthentication = async () => {
      await refreshAuthentication();
      setIsLoading(false);
    };
    checkAuthentication();
  });

  if (isLoading) {
    return <Loader type={LoaderTypes.FullView} />;
  }

  return (
    <Router>
      <Switch>
        {/* @TODO - Need to how we wanted to handle initial login
            (Like what data we should keep in URL and all) */}
        <Route
          path="/auth/:registeredEmail?"
          component={Auth}
        />
        <PrivateRoute path="/admin" component={AdminDashboard} />
        <PrivateRoute path="/onboarding" component={Onboarding} />
        <Route
          path="/reset-password"
          component={ForgotPassword}
        />
        {/* For now redirect user to auth screen. */}
        <Redirect
          from="/"
          exact
          to="/auth"
        />
      </Switch>
    </Router>
  );
};

export default AppRouter;
