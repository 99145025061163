/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import { Button as ButtonComponent, ButtonProps } from '@chakra-ui/core';
import { Text, TextTypes } from '..';

export enum ButtonTypes {
  Primary,
  PrimaryOutline,
  PrimaryLink,
  Secondary,
  Outline,
  Link,
}

interface IButton extends ButtonProps {
  buttonType?: ButtonTypes;
}

const Button: React.FC<IButton> = ({
  children,
  buttonType,
  isDisabled,
  ...restButtonProps
}: IButton) => (
  <ButtonComponent
    fontWeight={600}
    // ID used for unit testcases
    data-testid="button"
    fontSize="sm"
    letterSpacing="0.01em"
    textAlign="center"
    borderRadius={4}
    lineHeight="17px"
    {
      ...(buttonType === ButtonTypes.Primary) && {
        color: 'primaryActionText',
        backgroundColor: isDisabled
          ? 'disabledPrimaryColor'
          : 'activePrimaryColor',
        borderColor: 'defaultBorderColor',
        _active: {
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: 'activePrimaryColor',
          color: 'activePrimaryColor',
        },
        _hover: {
          backgroundColor: isDisabled
            ? 'disabledPrimaryColor'
            : 'primaryButtonHoverColor',
        },
      }
    }
    {
      ...(buttonType === ButtonTypes.PrimaryOutline) && {
        color: 'activePrimaryColor',
        borderColor: 'activePrimaryColor',
        border: '2px solid',
        boxSizing: 'border-box',
        borderRadius: 4,
        backgroundColor: 'transparent',
      }
    }
    {
      ...(buttonType === ButtonTypes.Outline) && {
        color: 'tertiaryActionColor',
        backgroundColor: 'transparent',
        borderColor: 'activeTertiaryColor',
        border: '1px solid',
        boxSizing: 'border-box',
        borderRadius: 4,
      }
    }
    {
      ...(buttonType === ButtonTypes.PrimaryLink) && {
        backgroundColor: 'transparent !important',
        boxShadow: 'none !important',
        height: 'auto',
        _hover: {
          backgroundColor: 'primaryLinkButtonHoverColor',
        },
      }
    }
    {
      ...(buttonType === ButtonTypes.Link) && {
        backgroundColor: 'transparent !important',
        boxShadow: 'none !important',
        height: 'auto',
      }
    }
    isDisabled={isDisabled}
    {...restButtonProps}
    // @TODO - Need to check for handling of font family across board.
    // fontFamily={}
  >
    {
      (buttonType === ButtonTypes.PrimaryLink
          || buttonType === ButtonTypes.Link)
        ? (
          <Text
            type={
              buttonType === ButtonTypes.PrimaryLink
                ? TextTypes.primaryLink
                : TextTypes.link
            }
          >
            {children}
          </Text>
        ) : children
    }
  </ButtonComponent>
);

export default memo(Button);
