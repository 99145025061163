/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import { Checkbox as CheckboxComponent, CheckboxProps } from '@chakra-ui/core';
import { FormLabel, LabelWeight } from '..';

export enum CheckboxTypes {
  Primary
}

interface ICheckbox extends CheckboxProps {
  // ID is mandatory to add for attribute with FormLabel
  id: string;
  children?: React.ReactNode;
  checkboxType?: CheckboxTypes;
}

const Checkbox: React.FC<ICheckbox> = ({
  id,
  children,
  checkboxType,
  ...restCheckboxProps
}: ICheckbox) => (
  <CheckboxComponent
    id={id}
    /**
       * @TODO - Need to work on checkbox's ui to match it
       * with required mock/prototype.
       *
       * backgroundColor="defaultBackgroundColor"
       * borderColor="defaultBorderColor"
       * boxSizing="border-box"
       * borderRadius={4}
       * border="1px solid"
      */
    {...restCheckboxProps}
    {
      ...(checkboxType === CheckboxTypes.Primary) && {
        variantColor: 'primaryRadioCheckboxColor',
      }
    }
  >
    {
      typeof children === 'string'
        ? (
          <FormLabel htmlFor={id} weight={LabelWeight.Normal}>
            {children}
          </FormLabel>
        )
        : children
    }
  </CheckboxComponent>
);

export default memo(Checkbox);
