import {
  Box,
  Flex,
  Radio,
  RadioGroup,
  useToast,
} from '@chakra-ui/core';
import React, { memo, useEffect, useState } from 'react';
import {
  Loader,
  LoaderTypes,
  Text,
  TextTypes,
} from '../../../../components';
import {
  useGetIpViolationListQuery,
  useUpdateCustomerViolationSettingMutation,
} from '../../../../graphql';

interface ISetIPViolation {
  ipViolationId: string,
  customerId: string,
  isLoading: boolean,
  customerHasNotSetIpViolation: boolean,
  refetch: () => void,
}

const SetIPViolation = ({
  customerId,
  ipViolationId,
  isLoading,
  customerHasNotSetIpViolation,
  // refetch,
}: ISetIPViolation) => {
  interface IIPViolationList {
    id: string,
    content: string,
  }

  const toast = useToast();

  const [
    ipViolationList,
    setIpViolationList,
  ] = useState<IIPViolationList[]>([]);

  const [selectedPolicy, setSelectedPolicy] = useState(ipViolationId);

  const {
    data: ipViolationListData,
    loading: ipViolationListLoading,
    error: ipViolationListError,
  } = useGetIpViolationListQuery();
  const [
    updateCustomerViolationSetting, {
      error: updateCustomerViolationSettingError,
      loading: updateCustomerViolationSettingLoading,
    },
  ] = useUpdateCustomerViolationSettingMutation();

  useEffect(() => {
    if (ipViolationListData?.ipViolationList?.edges) {
      const {
        ipViolationList: {
          edges: ipViolationListEdges,
        },
      } = ipViolationListData;
      const ipViolationListPayload = ipViolationListEdges.map((ipViolation) => (
        {
          id: ipViolation?.node?.id!,
          content: ipViolation?.node?.content!,
        }
      ));
      setIpViolationList(ipViolationListPayload);
    }
    if (ipViolationListError) {
      toast({
        title: 'Error!',
        description: ipViolationListError.message,
        status: 'error',
      });
    }
  }, [toast, ipViolationListData, ipViolationListError]);

  const getIPViolationsRadioButtons = () => (
    <RadioGroup
      onChange={handleChange}
      value={selectedPolicy}
      variantColor="primaryRadioCheckboxColor"
      spacing={28}
    >
      {
        ipViolationList.map((ipViolation) => (
          <Radio
            isDisabled={updateCustomerViolationSettingLoading
              || isLoading || customerHasNotSetIpViolation}
            key={ipViolation.id}
            value={ipViolation.id}
          >
            <Text
              type={TextTypes.tertiaryHeader}
              fontSize="14px"
            >
              {ipViolation.content}
            </Text>
          </Radio>
        ))
      }
    </RadioGroup>
  );

  const handleChange = async (e: React.ChangeEvent<any>) => {
    try {
      e.persist();
      setSelectedPolicy(e.target.value);
      await updateCustomerViolationSetting({
        variables: {
          input: {
            customerId,
            ipViolationId: e.target.value,
          },
        },
      });
      toast({
        title: 'IP Violation Updated!',
        description: 'IP Violation Setting updated successfully',
        status: 'success',
      });
    } catch {
      toast({
        title: 'Error!',
        description: updateCustomerViolationSettingError?.message,
        status: 'error',
      });
    }
  };

  return (
    <Box width="100%">
      <Flex
        // justifyContent="space-between"
        alignItems="center"
        marginTop={2}
        marginBottom={6}
      >
        <Text
          type={TextTypes.header}
        >
          IP Violations
        </Text>
        <Text
          marginLeft={3}
          marginTop={1}
          fontSize="xs"
          fontStyle="italic"
          hidden={!customerHasNotSetIpViolation}
        >
          (Customer has not selected the IP Violation option till now.)
        </Text>
      </Flex>
      <Box
        // width="50%"
        flexGrow={1}
      >
        {
          ipViolationListError
            ? (
              <Text>
                Something went wrong while fetching IP Violation
                list. Refresh the page to try again.
              </Text>
            )
            : ipViolationListLoading || isLoading ? (
              <Loader type={LoaderTypes.Content} />
            )
              : getIPViolationsRadioButtons()
        }
      </Box>
    </Box>
  );
};

export default memo(SetIPViolation);
