/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext } from 'react';

// @ts-ignore
const FirebaseContext = createContext();

export default (props: any) => {
  const { firebase } = props;
  const value = React.useMemo(() => ({
    firebase,
  }), [firebase]);

  return <FirebaseContext.Provider value={value} {...props} />;
};

export const useFirebaseProvider = () => {
  const context = useContext(FirebaseContext);

  if (!context) {
    throw new Error(`
      FirebaseContext() has to be used within a child of the Navigation Provider
    `);
  }

  const {
    firebase,
  }:any = context;

  return {
    firebase,
  };
};
