/* eslint-disable no-unused-vars */
import React, { memo, useState, useEffect } from 'react';
import {
  Box,
  Flex,
  RadioGroup,
  useToast,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  FormLabel,
  Icon,
} from '@chakra-ui/core';
// import warningFilled from '@iconify/icons-carbon/warning-filled';
// import warningIcon from '@iconify-icons/carbon/warning';

import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
// import Details from './Details';
import {
  Button, Text, Checkbox,
  TextTypes, ButtonTypes, CheckboxTypes, Radio, Loader, LoaderTypes,
} from '../../../components';
import { ICommonStepsPropInterface } from '../interfaces';
import {
  OnboardingStatus,
  useUpdateCustomerOnboardingStatusMutation,
  useGetCustomerQuery,
  useGetCustomerTermsAndConditionsQuery,
  useGetIpViolationListQuery,
  useUpdateCustomerViolationSettingMutation,
  useCreateProductFileMutation,
  useCreateAuthorizedSellersFileMutation,
  useCreateTermsAndConditionsFileMutation,
  useCreatePriceSheetFileMutation,
  useCreateApprovedLetterFileMutation,
  useGetBrandStatusQuery,
} from '../../../graphql';
import { getBrandID, getCustomerID } from '../../../utils/files';
import {
  DOCUMENTS_DOWNLOAD_ROUTE,
  IP_VIOLATION_OPTIONS,
} from '../../../variables/contants';
import getErrorMessage from '../../../utils/getErrorMessage';

export interface State {
  selectedPolicy: string;
  agreeToTheTermsAndCondition: boolean;
  setupFee?: number | null;
  monthlyFee?: number | null;
  initialTerm?: number | null;
  marketplace: string;
}

const TermsAndCondition: React.FC<ICommonStepsPropInterface> = (
  { advancingStepLoader, refetchBrandStatus }: ICommonStepsPropInterface,
) => {
  // TODO: Replace the state values once API integrated
  const [state, setState] = useState<State>({
    agreeToTheTermsAndCondition: false,
    selectedPolicy: '',
    setupFee: null,
    monthlyFee: null,
    initialTerm: null,
    marketplace: 'Amazon',
  });
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [allStepsCompleted, setAllStepsCompleted] = useState(false);
  const [starttextOnModal, setStartTextOnModal] = useState('');
  const [endttextOnModal, setEndTextOnModal] = useState('');

  const [productsStatus, setproductsStatus] = useState(false);
  const [letterheadUploadStatus, setletterheadUploadStatus] = useState(false);
  const [impactLetterStatus, setimpactLetterStatus] = useState(false);
  const [sellersstatus, setsellersstatus] = useState(false);

  const history = useHistory();
  const {
    agreeToTheTermsAndCondition,
    selectedPolicy,
    setupFee,
    monthlyFee,
    initialTerm,
    marketplace,
  } = state;
  const toast = useToast();

  const [
    updateCustomerOnboardingStatus,
  ] = useUpdateCustomerOnboardingStatusMutation();

  /* useGetCustomerTermsAndConditionsQuery implementation */

  const {
    data: termsAndConditionsData,
    error: termsAndConditionsError,
    loading: termsAndConditionLoading,
  } = useGetCustomerTermsAndConditionsQuery({
    variables: {
      id: getCustomerID(),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    // if (termsAndConditionsData
    //   ?.customer?.customerTermsAndCondition?.termsAndConditions
    // ) {
    //   const {
    //     content,
    //   } = termsAndConditionsData
    //   ?.customer?.customerTermsAndCondition.termsAndConditions;
    //   const [termsAndConditionsNode] = termsAndConditionsEdges;
    //   const content = termsAndConditionsNode
    //     ?.node?.termsAndConditions?.content!;
    //   setTermsAndConditionContent(content);
    //   const name = termsAndConditionsNode?.node?.termsAndConditions?.name!;
    //   setTermsAndConditionName(name);
    // }
    if (termsAndConditionsError) {
      toast({
        title: 'Error!',
        description: termsAndConditionsError.message,
        status: 'error',
      });
    }
  }, [termsAndConditionsError, toast]);

  const toggleTermsAndCondition = () => {
    setState((prevState) => ({
      ...prevState,
      agreeToTheTermsAndCondition: !agreeToTheTermsAndCondition,
    }));
  };

  /* useGetIpViolationListQuery implementation */
  interface IIpViolationList {
    id: string,
    content: string,
  }

  const [
    ipViolationList,
    setIpViolationList,
  ] = useState<IIpViolationList[]>();

  const {
    data: ipViolationListData,
    loading: ipViolationListLoading,
    error: ipViolationListError,
  } = useGetIpViolationListQuery();

  useEffect(() => {
    if (ipViolationListData?.ipViolationList?.edges) {
      const {
        ipViolationList: {
          edges: ipViolationListEdges,
        },
      } = ipViolationListData;
      const ipViolationListPayload = ipViolationListEdges.map((ipViolation) => (
        {
          id: ipViolation?.node?.id!,
          content: ipViolation?.node?.content!,
        }
      ));
      setIpViolationList(ipViolationListPayload);
    }
    if (ipViolationListError) {
      toast({
        title: 'Error!',
        description: ipViolationListError.message,
        status: 'error',
      });
    }
  }, [toast, ipViolationListData, ipViolationListError]);

  const getRadioButtons = (ipViolation: IIpViolationList) => {
    /* eslint-disable indent */
    switch (ipViolation.content) {
      case IP_VIOLATION_OPTIONS[0]:
        return (
          <Radio
            key={ipViolation.id}
            value={ipViolation.id}
          >
            <strong>YES</strong>
            , we authorize you to submit IP Policy Violations
          </Radio>
        );
      case IP_VIOLATION_OPTIONS[1]:
        return (
          <Radio
            key={ipViolation.id}
            value={ipViolation.id}
          >
            <strong>NO</strong>
            , we do&nbsp;
            <strong>NOT</strong>
            &nbsp;
            authorize you to submit IP Policy Violations
          </Radio>
        );
      case IP_VIOLATION_OPTIONS[2]:
        return (
          <Radio key={ipViolation.id} value={ipViolation.id}>
            Contact us to discuss
          </Radio>
        );
      default:
        return null;
    }
  };

  /* implementation for setting selected ip violation
     for a customer when he arrives at the page
  */
  const {
    data: customerData,
    loading: customerDataLoading,
    error: customerError,
  } = useGetCustomerQuery({
    variables: { id: getCustomerID() },
    fetchPolicy: 'network-only',
  });
  const {
    data: brandStatusData,
  } = useGetBrandStatusQuery({
    variables: { id: getBrandID() },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (customerData?.customer) {
      const { ipViolation, customerContract } = customerData?.customer;
      setState((prevState) => ({
        ...prevState,
        selectedPolicy: ipViolation?.id ?? '',
        setupFee: customerContract?.setupFee,
        monthlyFee: customerContract?.monthlyFee,
        initialTerm: customerContract?.term,
      }));
    }
    if (customerError) {
      toast({
        title: 'Error!',
        description: customerError.message,
        status: 'error',
      });
    }
  }, [customerData, customerError, toast]);

  /* useUpdateCustomerViolationSettingMutation implementation */

  const [
    updateCustomerViolationSetting, {
      error: updateCustomerViolationSettingError,
    },
  ] = useUpdateCustomerViolationSettingMutation();
  const [createProductFile] = useCreateProductFileMutation();
  const [
    createAuthorizedSellersFile,
  ] = useCreateAuthorizedSellersFileMutation();
  const [
    createApprovedLetterFile,
  ] = useCreateApprovedLetterFileMutation();
  const [createPriceSheetFile] = useCreatePriceSheetFileMutation();
  const [
    createTermsAndConditionsFile,
  ] = useCreateTermsAndConditionsFileMutation();

  const handleChange = async (e: React.ChangeEvent<any>) => {
    try {
      e.persist();
      setState((prevState) => ({
        ...prevState,
        selectedPolicy: e.target.value,
      }));
      await updateCustomerViolationSetting({
        variables: {
          input: {
            customerId: getCustomerID(),
            ipViolationId: e.target.value,
          },
        },
      });
      toast({
        title: 'IP Violation Updated!',
        description: 'IP Violation Setting updated successfully',
        status: 'success',
      });
    } catch {
      toast({
        title: 'Error!',
        description: updateCustomerViolationSettingError?.message,
        status: 'error',
      });
    }
  };

  const confirmNextStep = async () => {
    // setUpdatingOnboardingStatus(true);
    try {
      setIsOpen(false);
      setLoader(true);
      const input = {
        variables: {
          input: { brandId: getBrandID() },
        },
      };
      // TODO: Replace all five mutation with a single mutation.
      await Promise.all([
        createProductFile(input),
        createAuthorizedSellersFile(input),
        createApprovedLetterFile(input),
        createPriceSheetFile(input),
        createTermsAndConditionsFile(input),
      ]);
      toast({
        title: 'Documents created successfully',
        description: 'All required documents have been created successfully.',
        status: 'success',
      });
      refetchBrandStatus();
      try {
        await updateCustomerOnboardingStatus({
          variables: {
            input: {
              id: getCustomerID(),
              status: OnboardingStatus.Completed,
            },
          },
        });
        toast({
          title: 'Onboarding Completed',
          description: `Onboarding completed successfully.
          Navigating to Documents download page.`,
          status: 'success',
        });
        setLoader(false);
        history.push(DOCUMENTS_DOWNLOAD_ROUTE);
      } catch (error) {
        setLoader(false);
        toast({
          title: 'Error!',
          description: getErrorMessage(error),
          status: 'error',
        });
      }
    } catch (error) {
      setLoader(false);
      toast({
        title: 'Error!',
        description: getErrorMessage(error),
        status: 'error',
      });
    }
  };
  const handleNextStep = async () => {
    setIsOpen(true);
    // eslint-disable-next-line max-len
    if (brandStatusData?.brand?.addedProducts && brandStatusData?.brand?.letterheadUploaded && brandStatusData?.brand.approvedImpactLetter
      && brandStatusData?.brand.sellersFound) {
      setAllStepsCompleted(true);
      // eslint-disable-next-line max-len
      setStartTextOnModal('Congratulations ! You have completed all the steps for onboarding Click Finish below to complete onboarding and download the Document in the next screen.');
    } else {
      // eslint-disable-next-line max-len
      const textmessage = (' Looks like the following  onboarding steps is/are incomplete.');
      setStartTextOnModal(textmessage);

      // eslint-disable-next-line max-len
      const endtextmessage = ('Please complete all the onboarding steps before proceeding to download your documents.');
      setEndTextOnModal(endtextmessage);
    }
    if (brandStatusData?.brand?.sellersFound) {
      setsellersstatus(true);
    }
    if (brandStatusData?.brand?.addedProducts) {
      setproductsStatus(true);
    }
    if (brandStatusData?.brand?.letterheadUploaded) {
      setletterheadUploadStatus(true);
    }
    if (brandStatusData?.brand?.approvedImpactLetter) {
      setimpactLetterStatus(true);
    }
  };

  if (
    termsAndConditionLoading || ipViolationListLoading || customerDataLoading
  ) {
    return <Loader type={LoaderTypes.FullViewModal} large />;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent>

          <ModalHeader
            alignSelf="center"
          >
            Onboarding Status
          </ModalHeader>

          <ModalBody>
            <FormLabel>

              <Text
                type={TextTypes.body}
                color="#333333"
                fontSize="12px"
                lineHeight="14px"
              >
                {starttextOnModal}
              </Text>
              <br />
              <Flex align="center">
                <Flex align="left" direction="column" width="50%">
                  <Text
                    type={TextTypes.tertiaryHeader}
                    textTransform="capitalize"
                  >
                    Impact Letters Approved
                  </Text>
                </Flex>
                <Flex align="left" direction="column">
                  {
impactLetterStatus ? (
  <Box>
    {' '}
    <Icon
      name="successTick"
      marginRight={1}
    />
    <Text
      type={TextTypes.tertiaryHeader}
      color="advancementActionColor"
      as="span"
    >
      Completed
    </Text>
  </Box>
) : (
  <Box>
    <Icon
      name="warning"
      color="activePrimaryColor"
      marginRight={1}
    />
    <Text
      type={TextTypes.tertiaryHeader}
      as="span"
      color="activePrimaryColor"
    >
      Step Incomplete
    </Text>
  </Box>
)
                  }
                </Flex>
              </Flex>
              <br />
              <Flex align="center">
                <Flex align="left" direction="column" width="50%">
                  <Text
                    type={TextTypes.tertiaryHeader}
                    textTransform="capitalize"
                  >
                    Sellers Added
                  </Text>
                </Flex>
                <Flex align="left" direction="column">
                  {
sellersstatus ? (
  <Box>
    {' '}
    <Icon
      name="successTick"
      marginRight={1}
    />
    <Text
      type={TextTypes.tertiaryHeader}
      color="advancementActionColor"
      as="span"
    >
      Completed
    </Text>
  </Box>
) : (
  <Box>
    <Icon
      name="warning"
      color="activePrimaryColor"
      marginRight={1}
    />
    <Text
      type={TextTypes.tertiaryHeader}
      as="span"
      color="activePrimaryColor"
    >
      Step Incomplete
    </Text>
  </Box>
)
                  }
                </Flex>
              </Flex>
              <br />
              <Flex align="center">
                <Flex align="left" direction="column" width="50%">
                  <Text
                    type={TextTypes.tertiaryHeader}
                    textTransform="capitalize"
                  >
                    Products Added
                  </Text>
                </Flex>
                <Flex align="left" direction="column">
                  {
productsStatus ? (
  <Box>
    {' '}
    <Icon
      name="successTick"
      marginRight={1}
    />
    <Text
      type={TextTypes.tertiaryHeader}
      color="advancementActionColor"
      as="span"
    >
      Completed
    </Text>
  </Box>
) : (
  <Box>
    <Icon
      name="warning"
      color="activePrimaryColor"
      marginRight={1}
    />
    <Text
      type={TextTypes.tertiaryHeader}
      as="span"
      color="activePrimaryColor"
    >
      Step Incomplete
    </Text>
  </Box>
)
                  }
                </Flex>
              </Flex>
              <br />
              <Flex align="center">
                <Flex align="left" direction="column" width="50%">
                  <Text
                    type={TextTypes.tertiaryHeader}
                    textTransform="capitalize"
                  >
                    Letterhead Uploaded
                  </Text>
                </Flex>
                <Flex align="left" direction="column">
                  {
letterheadUploadStatus ? (
  <Box>
    {' '}
    <Icon
      name="successTick"
      marginRight={1}
    />
    <Text
      type={TextTypes.tertiaryHeader}
      color="advancementActionColor"
      as="span"
    >
      Completed
    </Text>
  </Box>
) : (
  <Box>
    <Icon
      name="warning"
      color="activePrimaryColor"
      marginRight={1}
    />
    <Text
      type={TextTypes.tertiaryHeader}
      as="span"
      color="activePrimaryColor"
    >
      Step Incomplete
    </Text>
  </Box>
)
                  }
                </Flex>
              </Flex>
              {/* <>
                <span>
                  <Text
                    type={TextTypes.tertiaryHeader}
                    textTransform="capitalize"
                  >
                    Impact Letters Approved
                  </Text>
                  <Icon
                    name="successTick"
                    marginRight={1}
                  />

                </span>
              </> */}
              <br />
              <Text
                type={TextTypes.body}
                color="#333333"
                fontSize="12px"
                lineHeight="14px"
              >
                {endttextOnModal}
              </Text>
            </FormLabel>
            <form
              style={{
                width: '100%',
                marginTop: '10px',
              }}
            >
              <Flex
                justifyContent="center"

              >

                <Button
                  buttonType={ButtonTypes.Link}
                  onClick={() => setIsOpen(false)}
                  marginLeft={25}
                >
                  Cancel
                </Button>
                <Button
                  buttonType={ButtonTypes.Primary}
                  onClick={confirmNextStep}
                  isDisabled={!allStepsCompleted}
                  type="submit"
                  marginLeft={10}
                >
                  Confirm
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>

      </Modal>
      <div
        style={{
          gridColumnStart: 1,
          padding: '0 16px',
          margin: '0 auto',
          maxWidth: '500px',
        }}
      >
        <Text
          marginBottom={6}
          type={TextTypes.header}
        >
          IP Violations
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          Amazon generally acts against sellers who violate
          the Intellectual Property (&quot;IP&quot;) Rights of others.
          We refrain from submitting IP violations unless
          specifically instructed by you.
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          There are two common situations where sellers appear
          to violate Amazon&#39;s IP Policies. We would like your
          authorization to file IP Policy Violations
          in the following circumstances:
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          Copyright - When an unauthorized listing is using one
          or more images taken from your website or other advertisement.
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          Trademark - When, in the brand attribute of the detail page,
          an unauthorized ASIN identifies a brand other
          than your brand as the source of the product.
        </Text>
        <Box flexGrow={1} paddingY={4}>
          <RadioGroup
            onChange={handleChange}
            value={selectedPolicy}
            variantColor="primaryRadioCheckboxColor"
            spacing={28}
          >
            {
                ipViolationList?.map((ipViolation) => (
                  getRadioButtons(ipViolation)
                ))
            }
            {/* <Radio
              value="YES, we authorize you to submit IP Policy Violations"
            >
              <strong>YES</strong>
              , we authorize you to submit IP Policy Violations
            </Radio>
            <Radio
              value="NO, we do NOT authorize you to submit IP Policy Violations"
            >
              <strong>NO</strong>
              , we do&nbsp;
              <strong>NOT</strong>
              &nbsp;
              authorize you to submit IP Policy Violations
            </Radio>
            <Radio value="Contact us to discuss">
              Contact us to discuss
            </Radio> */}
          </RadioGroup>
        </Box>
      </div>
      <div
        style={{
          width: '100%',
          maxWidth: '700px',
          padding: '0 16px',
        }}
      >
        <Text
          marginBottom={6}
          type={TextTypes.header}
        >
          Terms & Conditions
        </Text>
        <Box
          marginBottom={6}
        >
          <Text
            marginBottom={3}
            type={TextTypes.tertiaryHeader}
          >
            <strong>Initial Term:</strong>
            {' '}
            <NumberFormat
              style={{ color: 'formLabel' }}
              value={initialTerm ?? ''}
              displayType="text"
              suffix={initialTerm === 1 ? ' Month' : ' Months'}
            />
          </Text>
          <Text
            marginBottom={3}
            type={TextTypes.tertiaryHeader}
          >
            <strong>Set-up Fee:</strong>
            {' '}
            <NumberFormat
              value={setupFee ?? ''}
              displayType="text"
              prefix="$"
              thousandSeparator
            />
          </Text>
          <Text
            marginBottom={3}
            type={TextTypes.tertiaryHeader}
          >
            <strong>Monthly Fee:</strong>
            {' '}
            <NumberFormat
              value={monthlyFee ?? ''}
              displayType="text"
              prefix="$"
              thousandSeparator
            />
          </Text>
          <Text
            marginBottom={3}
            type={TextTypes.tertiaryHeader}
          >
            <strong>Marketplace:</strong>
            {' '}
            {marketplace}
          </Text>
        </Box>
        <Flex
          justifyContent="center"
          borderBottom="1px solid #333333"
          position="sticky"
          padding={0}
          width="100%"
          background="white"
        >
          <Text type={TextTypes.secondaryHeader} paddingY={2}>
            {/* termsAndConditionName */}
            Terms And Conditions
          </Text>
        </Flex>
        {/* <Details
          error={termsAndConditionsError}
          isLoading={termsAndConditionLoading}
          content={
            termsAndConditionsData?.customer?.customerTermsAndCondition
            ?.termsAndConditions?.content ?? ''
          }
        /> */}
        <Flex
          justifyContent={
            termsAndConditionsError?.message
              ? 'center'
              : undefined
          }
          alignItems={
            termsAndConditionsError?.message
              ? 'center'
              : undefined
          }
          border={1}
          borderRadius={5}
          borderStyle="solid"
          borderColor="#E5E5E5"
          paddingY={3}
          paddingX={5}
          minHeight={500}
          maxHeight={500}
          overflowY="auto"
        >
          <Box dangerouslySetInnerHTML={{
            __html: termsAndConditionsData?.customer?.customerTermsAndCondition
            ?.termsAndConditions?.content ?? '',
          }}
          />
          {termsAndConditionsError?.message
                        && (
                          <Text
                            type={TextTypes.body}
                            color="#333333"
                            fontSize="12px"
                            lineHeight="14px"
                            textAlign="center"
                          >
                            An error occured while fetching the Terms and
                            condition document. Please refresh the page again.
                          </Text>
            )}
        </Flex>
        <Box
          textAlign="center"
          maxWidth={400}
          width="100%"
          margin="auto"
        >
          <Checkbox
            isChecked={agreeToTheTermsAndCondition}
            onChange={toggleTermsAndCondition}
            id="agree_agreement"
            marginY={4}
            checkboxType={CheckboxTypes.Primary}
          >
            Check here to indicate that you have read
            and agree to the terms of the GF Customer Agreement
          </Checkbox>
          <Button
            buttonType={ButtonTypes.Primary}
            isDisabled={
              !(selectedPolicy && agreeToTheTermsAndCondition)
              || advancingStepLoader
            }
            width="100%"
            marginBottom={4}
            onClick={handleNextStep}
            isLoading={advancingStepLoader}
          >
            Finish
          </Button>
        </Box>
      </div>
      {loader && <Loader type={LoaderTypes.FullViewModal} large />}
    </>
  );
};

export default memo(TermsAndCondition);
