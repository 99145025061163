const getErrorMessage = (err: any) => {
  if (err.graphQLErrors) {
    return err.graphQLErrors.join(', ');
  }
  if (err.networkError) {
    return 'Network Error';
  }
  if (typeof err === 'string') {
    return err;
  }
  return err.message ? err.message : 'Something went wrong. Please try again!';
};

export default getErrorMessage;
