import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/core';
import React from 'react';

interface LogoutProps {
  handler: () => void,
  styles?: React.CSSProperties;
  title: string,
}
const Logout = (
  {
    handler,
    styles,
    title,
  }: LogoutProps,
) => (
  <Flex style={styles}>
    <Menu>
      <MenuButton>
        <Avatar
          size="sm"
          name={title}
          backgroundColor="activePrimaryColor"
        />
      </MenuButton>
      <MenuList placement="bottom">
        <MenuGroup
          title={title.toUpperCase()}
        >
          <MenuItem
            onClick={handler}
          >
            Logout
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  </Flex>
);

export default Logout;
