/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import { Radio as RadioComponent, RadioProps } from '@chakra-ui/core';
import styled from '@emotion/styled';

// Overriding some default style to match require UI.
const StyledRadioWrapper = styled(RadioComponent)`
  div[type="radio"] {
    min-width: 20px;
    min-height: 20px;
    background-color: white !important;
    span {
      min-width: 10px;
      min-height: 10px;
      background-color: #FF7A00;
    }
  }
`;

const Radio: React.FC<RadioProps> = ({
  children,
  ...restRadioProps
}: RadioProps) => (
  <StyledRadioWrapper
    {...restRadioProps}
  >
    {children}
  </StyledRadioWrapper>
);

export default memo(Radio);
