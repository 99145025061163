import React, { memo } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import CustomerDashboard from './Dashboard';
import CustomerDetails from './Details/index';

const Customers: React.FC = (_) => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route
          exact
          path={`${path}/:id`}
        >
          <CustomerDetails />
        </Route>
        <Route
          path="/"
        >
          <CustomerDashboard />
        </Route>
        <Redirect from="/*" exact to="/admin/customers" />
      </Switch>
    </div>
  );
};

export default memo(Customers);
