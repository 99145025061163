import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  Icon,
  Text,
  useToast,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as firebaseBase from 'firebase';
import {
  Button,
  ButtonTypes,
  FormLabel,
  Input,
  LoaderTypes,
  SplitWithImage,
} from '../../components';
import Loader from '../../components/loader';

import { useFirebaseProvider } from '../../core/firebaseContext';
import {
  getJWTToken,
  setJWTToken,
  setUserDetails,
  isExpired,
} from '../../utils/auth';
import { EMAIL_VALIDATION_PATTERN } from '../../utils/regex';
import { EMAIL_REQUIRED, INVALID_EMAIL } from '../../variables/contants';
import apolloClient from '../../core/apolloClient';
import meQuery from './meQuery';
import {
  OnboardingStatus,
  useUpdateCustomerOnboardingStatusMutation,
} from '../../graphql';
import { setBrandID, setCustomerID } from '../../utils/files';
import getErrorMessage from '../../utils/getErrorMessage';
import { OnBoardingSubRoutes } from '../OnBoarding';
import { OnboardingUIRoute } from '../../variables/types';

const Auth = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, getValues } = useForm();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const mode = useQuery()?.get('mode');
  const oobCode = useQuery()?.get('oobCode');
  const { firebase } = useFirebaseProvider();
  const toast = useToast();
  const resetPassword = mode && oobCode;
  const [
    updateCustomerOnboardingStatus,
  ] = useUpdateCustomerOnboardingStatusMutation();

  useEffect(() => {
    const validateUserSession = async () => {
      const JWTToken = await getJWTToken();
      if (!isExpired(JWTToken)) {
        getFalkonUser();
      }
    };

    validateUserSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginUser = async (
    invokeFirebaseAuthentication: Promise<firebaseBase.auth.UserCredential>,
  ) => {
    try {
      const userCredential = await invokeFirebaseAuthentication;
      // @ts-ignore
      setJWTToken(await userCredential.user.getIdToken());

      /*  TODO:
            1. Use me-query.graphql & 'useMeQuery' once auth headers works
            in codegen.yml is passed & 'yarn graphql' command runs successfully.
            2. Refactor code below to make user of TypeScript types.
      */
      setIsLoggingIn(true);
      getFalkonUser();
    } catch (error) {
      setIsLoggingIn(false);
      // @TODO - Need to add snackBar implementation to show errors.
      if (error) {
        toast({
          title: 'Error!',
          description: getErrorMessage(error),
          status: 'error',
        });
      }
    }
  };

  const navigateCustomerToActiveRoute = (uiRouteId: OnboardingUIRoute) => {
    const onboardingPath = OnBoardingSubRoutes.find((route) => route.title
      .toLowerCase() === uiRouteId)?.path!;
    history.push(onboardingPath);
  };

  const showLoginSuccessfulToast = () => toast({
    title: 'Login',
    description: 'Login Successful',
    status: 'success',
  });

  const getFalkonUser = async () => {
    try {
      const {
        data: userInfo,
      } = await apolloClient.query({
        query: meQuery, fetchPolicy: 'network-only',
      });
      const userDetails = userInfo?.me;
      setUserDetails(userDetails);
      setIsLoggingIn(false);
      const {
        userRoles: { edges: userRoleEdges },
      } = userDetails;
      const [userRoleNode] = userRoleEdges;
      const role = userRoleNode?.node?.role;
      const { name: roleName } = role;
      if (roleName === 'Admin') {
        showLoginSuccessfulToast();
        history.push('/admin');
      } else {
        const {
          customerUser: {
            customer: {
              id,
              brands: { edges: brandEdges },
              customerOnboardingStep: {
                status, onboardingStep: {
                  uiRouteId,
                },
              },
            },
          },
        } = userDetails;
        const [brandNode] = brandEdges;
        const brandId = brandNode?.node?.id;
        setBrandID(brandId);
        setCustomerID(id);
        if (status === OnboardingStatus.NotStarted) {
          handleUpdateCustomerOnboardingStatus(id, uiRouteId);
        } else {
          showLoginSuccessfulToast();
          navigateCustomerToActiveRoute(uiRouteId);
          // history.push('/onboarding');
        }
      }
    } catch (error) {
      setIsLoggingIn(false);
      // @TODO - Need to add snackBar implementation to show errors.
      if (error) {
        toast({
          title: 'Error!',
          description: getErrorMessage(error),
          status: 'error',
        });
      }
    }
  };

  const onGoogleLogin = async () => {
    setIsLoggingIn(true);
    loginUser(firebase.doSignInGoogle());
  };

  /* TODO:
   * 1. Placement of this function might change in future
   * 2. 'getCustomerID' needs to be replaced with actual customer
   *    id in future.
   * 3. Find the right logic to call this function as per new
   *    implementation of handleUserLogin function
   */
  const handleUpdateCustomerOnboardingStatus = async (
    customerId: string,
    uiRouteId: OnboardingUIRoute,
  ) => {
    // Update onboardingStatus only if its not yet started.
    try {
      await updateCustomerOnboardingStatus({
        variables: {
          input: {
            id: customerId,
            status: OnboardingStatus.InProgress,
          },
        },
      });
      showLoginSuccessfulToast();
      // history.push('/onboarding');
      navigateCustomerToActiveRoute(uiRouteId);
    } catch (error) {
      toast({
        title: 'Error!',
        description: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  const handleUserLogin = async (formValues: Record<string, string>) => {
    if (resetPassword) {
      try {
        setIsLoggingIn(true);
        const email = await firebase.verifyPasswordResetCode(oobCode);
        await firebase.confirmPasswordReset(oobCode, formValues.password);
        loginUser(
          firebase.doSignInWithEmailAndPassword(
            email, formValues.password,
          ),
        );
      } catch (error) {
        setIsLoggingIn(false);
        toast({
          title: 'Error!',
          description: getErrorMessage(error),
          status: 'error',
        });
      }
    } else {
      setIsLoggingIn(true);
      loginUser(
        firebase.doSignInWithEmailAndPassword(
          formValues.email, formValues.password,
        ),
      );
    }
  };

  return (
    <Box width="100%" position="relative">
      <SplitWithImage>
        <Flex
          direction="column"
          justifyContent="space-evenly"
          maxHeight={700}
          maxWidth={400}
          margin="auto"
          height="100%"
          width="100%"
        >
          <Icon
            name="app"
            alignSelf="center"
            marginTop={10}
            marginBottom={10}
            width="90%"
            height="auto"
          />
          <Box flexGrow={1}>
            <form
              onSubmit={handleSubmit(handleUserLogin)}
              noValidate
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box marginBottom={100}>
                {
                  !resetPassword && (
                    <FormControl marginBottom={4}>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Input
                        name="email"
                        type="email"
                        id="email"
                        aria-describedby="email-helper-text"
                        ref={
                          register({
                            required: { value: true, message: EMAIL_REQUIRED },
                            pattern: {
                              value: EMAIL_VALIDATION_PATTERN,
                              message: INVALID_EMAIL,
                            },
                          })
                        }
                      />
                      {
                        errors?.email && (
                          <FormHelperText fontSize="xs" id="email-helper-text">
                            {errors.email.message}
                          </FormHelperText>
                        )
                      }
                    </FormControl>
                  )
                }
                <FormControl marginBottom={4} isRequired>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  {
                    !resetPassword && (
                      <Link
                        to={{
                          pathname: '/reset-password',
                          state: { email: getValues().email },
                        }}
                      >
                        <Text
                          color="activePrimaryColor"
                          float="right"
                          fontSize="xs"
                          fontWeight={500}
                        >
                          Forgot your password?
                        </Text>
                      </Link>
                    )
                  }
                  <Input
                    name="password"
                    type="password"
                    id="password"
                    aria-describedby="password-helper-text"
                    fontSize="md"
                    ref={
                      register({
                        required: true,
                      })
                    }
                  />
                  {
                    errors.password && (
                      <FormHelperText fontSize="xs" id="password-helper-text">
                        *Password is required
                      </FormHelperText>
                    )
                  }
                </FormControl>
                {
                  resetPassword && (
                    <FormControl marginBottom={4} isRequired>
                      <FormLabel htmlFor="confirmPassword">
                        Confirm Password
                      </FormLabel>
                      <Input
                        fontSize="md"
                        name="confirmPassword"
                        type="password"
                        id="confirmPassword"
                        aria-describedby="confirm-password-helper-text"
                        ref={register({
                          required: true,
                        })}
                      />
                      {
                        errors.confirmPassword && (
                          <FormHelperText
                            fontSize="xs"
                            id="confirm-password-helper-text"
                          >
                            *Confirm password is required
                          </FormHelperText>
                        )
                      }
                    </FormControl>
                  )
                }
                {/* <FormControl>
                  <Checkbox
                    id="remember me"
                    checkboxType={CheckboxTypes.Primary}
                  >
                    Remember me
                  </Checkbox>
                </FormControl> */}
              </Box>

              <FormControl marginBottom={8}>
                <Button
                  type="submit"
                  width="100%"
                  buttonType={ButtonTypes.Primary}
                  fontSize="md"
                >
                  {resetPassword ? 'Set Password' : 'Login'}
                </Button>
                {
                  !resetPassword && (
                    <>
                      <Flex textAlign="center" marginTop={8} marginBottom={6}>
                        <Divider
                          flexBasis="100%"
                          borderColor="lightBorderColor"
                          opacity={1}
                        />
                        <span style={{ flexBasis: '100%', fontSize: 12 }}>
                          <FormLabel style={{ paddingRight: 0 }}>
                            OR LOG IN WITH
                          </FormLabel>
                        </span>
                        <Divider
                          flexBasis="100%"
                          borderColor="lightBorderColor"
                          opacity={1}
                        />
                      </Flex>
                      <Button
                        marginBottom={4}
                        width="100%"
                        onClick={onGoogleLogin}
                        paddingLeft="30%"
                        leftIcon={
                          () => (
                            <Icon name="google" size="19px" marginRight={2} />
                          )
                        }
                        buttonType={ButtonTypes.Outline}
                        fontSize="md"
                        justifyContent="flex-start"
                        /**
                         * Passing hardcoded colors as this color is not
                         * mentioned with a color pallet of provided prototype.
                         * @TODO - Need to check with UI/UX for
                         * color pallet related question.
                         * */
                        color="#2D9CDB"
                        borderColor="#2D9CDB"
                      >
                        Login with Google
                      </Button>
                      <Button
                        marginBottom={4}
                        width="100%"
                        paddingLeft="30%"
                        leftIcon={
                          () => <Icon name="key" size="19px" marginRight={2} />
                        }
                        buttonType={ButtonTypes.Outline}
                        fontSize="md"
                        justifyContent="flex-start"
                      >
                        Login with SSO
                      </Button>
                    </>
                  )
                }
              </FormControl>
            </form>
          </Box>
        </Flex>
      </SplitWithImage>
      {isLoggingIn && (
        <Loader
          type={LoaderTypes.FullViewModal}
          message="Logging in. Please wait."
          large
        />
      )}
    </Box>
  );
};

export default memo(Auth);
