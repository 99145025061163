/* eslint-disable no-unused-vars */
import {
  Box,
  Flex,
  FormLabel,
  Stack,
  Switch,
  useToast,
} from '@chakra-ui/core';
import React, { ChangeEvent, memo } from 'react';
import {
  Loader,
  LoaderTypes,
  Text,
  TextTypes,
} from '../../../../components';
import { useUpdateBrandMarketplaceMutation } from '../../../../graphql';
import getErrorMessage from '../../../../utils/getErrorMessage';

interface ISellerEngagementsProps {
  brandId: string,
  isLoading: boolean,
  engageSellers: boolean,
  monitorMarketplace: boolean,
  refetch: () => void,
}

const SellerEngagements = ({
  brandId,
  isLoading,
  engageSellers,
  monitorMarketplace,
  refetch,
}: ISellerEngagementsProps) => {
  const [updateBrandMarketplace, {
    loading: updateBrandMarketplaceLoading,
  }] = useUpdateBrandMarketplaceMutation();

  const toast = useToast();

  const handleUpdateBrandMarketplace = async (
    value: boolean, valueType: 'engageSellers' | 'monitorMarketplace',
  ) => {
    try {
      await updateBrandMarketplace({
        variables: {
          input: {
            id: brandId,
            engageSellers: valueType === 'engageSellers'
              ? value
              : engageSellers,
            monitorMarketplace: valueType === 'monitorMarketplace'
              ? value
              : monitorMarketplace,
          },
        },
      });
      refetch();
      if (valueType === 'engageSellers') {
        toast({
          title: 'Engage Sellers updated successfully for the brand',
          status: 'success',
        });
      } else {
        toast({
          title: 'Monitor marketplace value updated successfully for the brand',
          status: 'success',
        });
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  return (
    <Box width="100%">
      <Flex
        alignItems="center"
        marginBottom={5}
      >
        <Text
          type={TextTypes.header}
        >
          Seller Engagements
        </Text>
      </Flex>
      <Stack
        width="50%"
        flexGrow={1}
      >
        {
          isLoading ? (
            <Loader type={LoaderTypes.Content} />
          ) : (
            <>
              <Flex justifyContent="space-between" mb={5}>
                <FormLabel htmlFor="engage-sellers">
                  Engage Sellers
                </FormLabel>
                <Switch
                  isChecked={engageSellers}
                  isDisabled={updateBrandMarketplaceLoading}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>,
                  ) => handleUpdateBrandMarketplace(
                    event.target.checked, 'engageSellers',
                  )}
                  // TODO: Find a way to use custom color
                  color="orange"
                  size="md"
                  id="engage-sellers-switch"
                />
              </Flex>
              <Flex justifyContent="space-between">
                <FormLabel htmlFor="monitor-marketplace">
                  Monitor Marketplace
                </FormLabel>
                <Switch
                  isChecked={monitorMarketplace}
                  isDisabled={updateBrandMarketplaceLoading}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>,
                  ) => handleUpdateBrandMarketplace(
                    event.target.checked, 'monitorMarketplace',
                  )}
                  color="orange"
                  size="md"
                  id="monitor-marketplace-switch"
                />
              </Flex>
            </>
          )
        }
      </Stack>
    </Box>
  );
};

export default memo(SellerEngagements);
