import { DefaultTheme, theme } from '@chakra-ui/core';
import {
  app, google, key, tick,
  csvDownloadOrange, csvUploadBlack, loader,
  uploadDocument, uploadSuccess, uploadFail,
  options, successTick, revision, warning, companyLogoPlaceholder,
} from '../icons';
import customColors from './colors';

const customIcon = {
  app: {
    path: app,
    viewBox: '0 0 330 106',
  },
  google: {
    path: google,
    viewBox: '0 0 18 18',
  },
  key: {
    path: key,
    viewBox: '0 0 22 12',
  },
  csvDownloadOrange: {
    path: csvDownloadOrange,
    viewBox: '0 0 16 20',
  },
  csvUploadBlack: {
    path: csvUploadBlack,
    viewBox: '0 0 16 20',
  },
  tick: {
    path: tick,
    viewBox: '0 0 10 8',
  },
  successTick: {
    path: successTick,
    viewBox: '0 0 18 18',
  },
  revision: {
    path: revision,
    viewBox: '0 0 18 18',
  },
  loader: {
    path: loader,
    viewBox: '0 0 100 63',
  },
  uploadDocument: {
    path: uploadDocument,
    viewBox: '0 0 384 512',
  },
  uploadSuccess: {
    path: uploadSuccess,
    viewBox: '0 0 16 12',
  },
  uploadFail: {
    path: uploadFail,
    viewBox: '0 0 22 20',
  },
  options: {
    path: options,
    viewBox: '0 0 4 16',
  },
  warning: {
    path: warning,
    viewBox: '0 0 16 16',
  },
  companyLogoPlaceholder: {
    path: companyLogoPlaceholder,
    viewBox: '0 0 80 80',
  },
};

const customTheme: DefaultTheme = {
  ...theme,
  fontSizes: {
    ...theme.fontSizes,
    xs: '.625rem', // ~10px
    sm: '.75rem', // ~12px
    md: '.875rem', // ~14px
    lg: '1.125rem', // ~18px
    xl: '1.5rem', // ~24px
    '2xl': '2rem', // ~32px
    '3xl': '3rem', // ~48px
  },
  fonts: {
    body: `-apple-system,system-ui,BlinkMacSystemFont,
            "Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
    heading: `-apple-system,system-ui,BlinkMacSystemFont,
             "Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
    mono: `-apple-system,system-ui,BlinkMacSystemFont,
            "Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
  },
  colors: {
    ...theme.colors,
    ...customColors,
  },
  icons: {
    ...theme.icons,
    ...customIcon,
  },
};

export default customTheme;
