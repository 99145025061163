import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure, useToast,
} from '@chakra-ui/core';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Button,
  ButtonTypes,
  Loader,
  LoaderTypes,
  Text,
  TextTypes,
} from '../../../../components';
import LetterList from './LetterList';
import {
  BrandLetterNode, DefaultTemplateNode,
  useCreateBrandLetterMutation,
  useGetAllBrandLetterQuery,
  useUpdateBrandLetterMutation,
} from '../../../../graphql';
import getErrorMessage from '../../../../utils/getErrorMessage';

interface Props {
  isOpen: boolean;
  onClose(): void;
  selectedLetter?: string;
  modalTitle: string,
  onLetterSelected: (defaultTemplate: DefaultTemplateNode) => void;
}

const SelectImpactLetter = ({
  isOpen,
  onClose,
  onLetterSelected,
  modalTitle,
}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent minWidth="475px" maxWidth="90%">
      <ModalHeader>{modalTitle}</ModalHeader>
      <ModalCloseButton />
      <ModalBody minWidth="475px">
        <LetterList
          onRowSelected={onLetterSelected}
        />
      </ModalBody>
    </ModalContent>
  </Modal>
);

interface ImpactLetterProps {
  customer: any, // TODO -- need to fix this
}

const StyledUserTable = styled.table`
  width: 100%;
  box-shadow: 3px 3px 5px 6px #ccc;

  thead {
    background-color: #FAFBFF;
    text-align: left;
  }

  tbody tr {
    background-color: white;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }

  th, td {
    padding: 16px;
  }

  tr {
    border-bottom: 1px solid #BDBDBD;
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  // @TODO - Need to remove hardcoded 475px
  tbody {
    display: block;
    max-height: 475px;
    overflow-y: scroll;
  }
`;

const ImpactLetter = ({ customer }: ImpactLetterProps) => {
  const brandId = customer?.customer?.brands?.edges?.[0]?.node?.id;
  const {
    data: templateData,
    loading,
    error,
    refetch,
  } = useGetAllBrandLetterQuery({
    variables: {
      id: brandId,
    },
  });

  const [modalTitle, setModalTitle] = useState('Add Impact Letter');

  const [
    createBrandLetter,
    {
      loading: creatingBrand,
    },
  ] = useCreateBrandLetterMutation();
  const [
    updateBrandLetter,
    { loading: updatingBrand }] = useUpdateBrandLetterMutation();
  const toast = useToast();

  const [
    selectedBrand,
    setSelectedBrand,
  ] = useState<BrandLetterNode | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (loading && !templateData) {
    return <Loader type={LoaderTypes.FullViewModal} large />;
  }

  if (error) {
    return <div>Something went wrong, please try again!</div>;
  }

  const onLetterSelected = async (template: DefaultTemplateNode) => {
    try {
      if (selectedBrand) {
        await updateBrandLetter({
          variables: {
            input: {
              id: selectedBrand.id,
              active: selectedBrand.active,
              letterFormat: template.letterFormat,
              stage: template.stage,
              template: template.template,
            },
          },
        });
        toast({
          title: 'Impact Letter updated!',
          status: 'success',
        });
      } else {
        await createBrandLetter({
          variables: {
            input: {
              defaultTemplateId: template.id,
              brandId,
            },
          },
        });
        toast({
          title: 'Impact Letter created!',
          status: 'success',
        });
      }
      await refetch();
    } catch (err) {
      if (err) {
        toast({
          title: 'Error!',
          description: getErrorMessage(err),
          status: 'error',
        });
      }
    }

    onClose();
  };

  const onLetterUpdated = (brandLetterNode: BrandLetterNode) => {
    setModalTitle('Edit Impact Letter');
    setSelectedBrand(brandLetterNode);
    onOpen();
  };

  const onLetterAdd = () => {
    setModalTitle('Add Impact Letter');
    setSelectedBrand(null);
    onOpen();
  };

  return (
    <>
      { (
        creatingBrand || updatingBrand || loading
      ) && <Loader type={LoaderTypes.FullViewModal} large /> }
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        marginBottom={4}
        marginTop={4}
      >
        <Button
          onClick={onLetterAdd}
          buttonType={ButtonTypes.PrimaryOutline}
          _focus={{
            boxShadow: 'none',
          }}
          leftIcon="add"
        >
          Add Impact Letter
        </Button>
      </Flex>
      <SelectImpactLetter
        modalTitle={modalTitle}
        isOpen={isOpen}
        onClose={onClose}
        onLetterSelected={onLetterSelected}
      />
      <Box
        flexGrow={1}
      >
        <Box
          height="100%"
          flexGrow={1}
          maxHeight="100%"
          paddingBottom={4}
          overflow="auto"
        >
          <StyledUserTable>
            <thead>
              <tr>
                <th>
                  <Text type={TextTypes.secondaryHeader}>
                    {/* Title */}
                    Stage
                  </Text>
                </th>
                <th>
                  <Text type={TextTypes.secondaryHeader}>
                    Letter Content
                  </Text>
                </th>
                <th>
                  <Text type={TextTypes.secondaryHeader}>
                    Letter Format
                  </Text>
                </th>
                <th>
                  <Text type={TextTypes.secondaryHeader}>
                    Revision
                  </Text>
                </th>
              </tr>
            </thead>
            <Box
              overflowY="auto"
              as="tbody"
            >
              {
                templateData?.brand?.brandLetters?.edges?.map(
                  (templateEdge) => templateEdge && templateEdge.node && (
                    <tr
                      key={templateEdge.node.id}
                      // @ts-ignore
                      onClick={() => onLetterUpdated(templateEdge.node)}
                    >
                      <Box as="td">
                        <Text>
                          {/* {`Impact Letter ${templateEdge?.node?.stage}`} */}
                          {templateEdge?.node?.stage}
                        </Text>
                      </Box>
                      <Box as="td">
                        <Flex
                          alignItems="center"
                        >
                          <Flex
                            direction="column"
                          >
                            <Text type={TextTypes.body}>
                              {templateEdge.node.template}
                            </Text>
                          </Flex>
                        </Flex>
                      </Box>
                      <Box as="td">
                        <Text>
                          {templateEdge?.node?.letterFormat}
                        </Text>
                      </Box>
                      <Box as="td">
                        <Text type={TextTypes.body}>
                          {
                          templateEdge
                            .node.brandLetterRevision?.edges[0]?.node
                            ?.templateRevision
                          || ''
                          }
                        </Text>
                      </Box>
                    </tr>
                  ),
                )
              }
            </Box>
          </StyledUserTable>
        </Box>
      </Box>
    </>
  );
};

export default ImpactLetter;
