/* eslint-disable max-len */
export const AUTH_USER_TOKEN_KEY = 'AUTH_USER_TOKEN_KEY';
export const EMAIL_REQUIRED = '*Email Address is required';
export const ADDRESS_REQUIRED = '*Address is required';
export const INVALID_EMAIL = 'Invalid Email Address format';
export const SELLER_EMAIL_REQUIRED = '*Seller Email is required';
export const SELLER_EXTERNAL_ID_REQUIRED = '*Seller External Id is required';
export const CUSTOMER_EMAIL_REQUIRED = '*Customer Email is required';
export const PHONE_NUMBER_REQUIRED = '*Phone number is required';
export const INVALID_PHONE_NUMBER = 'Invalid Phone number format';
export const PHONE_NUMBER_MASK_FORMAT = '999-999-9999';
export const DOWNLOAD_DOCUMENT_URL = 'https://storage.googleapis.com/gf-public-assets/Sample%20Product%20Upload%20Doc.pdf';
export const DEFAULT_LETTER_BUILDER_IMAGE = 'companyLogoPlaceholder';
export const IP_VIOLATION_OPTIONS = [
  'YES, we authorize you to submit IP Policy Violations',
  'NO, we do NOT authorize you to submit IP Policy Violations',
  'Contact us to discuss',
];
export const SELLER_SCRAPE_MESSAGE = `HORUS is currently scraping through
Amazon storefronts in search of any illegitimate sellers that may be
hurting your brand and bottom dollar.`;

// Customer Contract Constants
export const CC_INITIAL_TERM_VALIDATION_MSG = 'Enter whole numbers only. (For e.g. 1, 100, 25 etc.)';
export const CC_MONTHLY_AND_SETUP_FEE_VALIDATION_MSG = 'Enter valid setup fee number. Only 2 decimal places allowed. (For e.g 12, 12.23, etc).';

// Letter Builder Constants
export const UNSAVED_REVISION_REQUEST_PROMPT_MSG = `Unsaved Revision
Request text found in one or more Revision Request Text field(s). Are you
sure you want to navigate away without saving them first?`;

// Documents Download Constants
export const DOCUMENTS_DOWNLOAD_ROUTE = '/onboarding/download-documents';
