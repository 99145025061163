import React, { memo, useEffect } from 'react';
import {
  Flex,
  Tabs, TabList, TabPanels, Tab, TabPanel,
} from '@chakra-ui/core';
import { useParams } from 'react-router-dom';
import CustomerDetails from './CustomerDetails';
import ImpactLetter from './ImpactLetters';
import { useFetchFullCustomerQuery } from '../../../../graphql';
import { Loader, LoaderTypes } from '../../../../components';
import { selectedCustomerName, selectedCustomerId }
  from '../../../../variables/globalVariables';

interface ParamTypes {
  id: string;
}

const Details: React.FC = () => {
  const { id = '' } = useParams<ParamTypes>();

  const {
    data,
    loading,
    error,
    variables,
    refetch,
  } = useFetchFullCustomerQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.customer) {
      const { name } = data.customer;
      selectedCustomerName(name);
      selectedCustomerId(id);
    }
  }, [data, id]);

  if (loading && !data) {
    return <Loader type={LoaderTypes.FullViewModal} large />;
  }

  if (error) {
    return <div>Something went wrong, please try again!</div>;
  }

  return (
    <Flex
      flexDir="row"
      justifyContent="space-between"
      width="100%"
      height="100%"
      // Hardcoded color as mock is not available for admin portal
      backgroundColor="#F7F8FC"
      padding={8}
      // direction="column"
      maxHeight="100%"
      paddingBottom={0}
    >
      <Tabs width="100%">
        <TabList>
          <Tab
            _selected={{
              borderBottomColor: 'activePrimaryColor',
            }}
          >
            Customers Details
          </Tab>
          <Tab
            _selected={{
              borderBottomColor: 'activePrimaryColor',
            }}
          >
            Impact Letters
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CustomerDetails
              customer={data}
              loading={loading}
              customerId={variables?.id || id}
              refetch={refetch}
            />
          </TabPanel>
          <TabPanel>
            <ImpactLetter
              customer={data}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default memo(Details);

