import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getJWTToken } from '../utils/auth';
import {
  selectedCustomerName,
  selectedCustomerId,
  navigationBlockedPayload,
} from '../variables/globalVariables';

/*
* NOTE: Referenced the following link to append operationName to uri value
* https://github.com/apollographql/apollo-link/issues/264#issuecomment-461953427
*/

/*
 * NOTE: Link for error-handling done in @apollo/client
 * https://stackoverflow.com/a/59472340/1682304
 */

/*
 * NOTE: Removed operantionName as it was causing CORS issue in local
 *  development environment. Can revisit later to find viable solution.
 */
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
});
const authLink = setContext(async (_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  const token = await getJWTToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

/*
  TODO: Refactor code to replace 'updateQuery' and add field-policies
  for adding pagination either by 'relayStylePagination' helper method
  or implmenting 'read' & 'merge' functions for required fields of the
  query.
 */
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        selectedCustomerName: () => selectedCustomerName(),
        selectedCustomerId: () => selectedCustomerId(),
        navigationBlockedPayload: () => navigationBlockedPayload(),
      },
    },
  },
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default client;
