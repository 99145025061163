import React, { memo } from 'react';
import { Box } from '@chakra-ui/core';

interface IOverlay {
  children: JSX.Element;
  background?: string;
  opacity?: number;
}

const Overlay: React.FC<IOverlay> = ({
  children,
  opacity = 0.35,
  background = `rgba(0, 0, 0, ${opacity})`,
}: IOverlay) => (
  <Box
    position="relative"
    width="100%"
    height="100%"
  >
    {children}
    <Box
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      background={background}
    />
  </Box>
);

export default memo(Overlay);
