/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable-next-line indent */
import React, { useState } from 'react';
import {
  Flex,
  Icon,
  useToast,
  Box,
  Divider,
  IconButton,
} from '@chakra-ui/core';
import { useDropzone, FileRejection } from 'react-dropzone';
import { Text, TextTypes, Button, ButtonTypes } from '..';
import { bytesToSize } from '../../utils/files';

interface FileAttributesProps {
  name: string,
  styles?: React.CSSProperties,
  handleFileVisibility: () => void
}

interface DragDropProps {
  fileFormat: string | string[],
  maxFileSize?: number,
  multiple?: boolean,
  styles?: React.CSSProperties,
  fileAttributeStyles?: React.CSSProperties,
  onFileDrop: (acceptedFiles: File[]) => void
}

const FilesChosen = (
  {
    name,
    handleFileVisibility,
    styles,
  }: FileAttributesProps,
) => (
  <Box width="100%" marginBottom={3}>
    <Flex
      direction="row"
      justifyContent="space-between"
      marginTop={5}
      style={styles}
    >
      <Text
        type={TextTypes.tertiaryHeader}
        fontSize="sm"
        isTruncated
      >
        {name}
      </Text>
      <IconButton
        aria-label="close file"
        variant="link"
        icon="close"
        color="activePrimaryColor"
        size="sm"
        minWidth={0}
        onClick={handleFileVisibility}
      />
    </Flex>
    <Divider color="lightBorderColor" width="100%" height="2px" />
  </Box>
);

const DragDrop = (
  {
    fileFormat,
    onFileDrop,
    maxFileSize,
    multiple,
    styles,
    fileAttributeStyles,
  }: DragDropProps,
) => {
  const [fileName, setFileName] = useState('');
  const [, setFileSize] = useState('');
  const [fileAttributeVisibility, setFileAttributeVisibility] = useState(false);
  const toast = useToast();

  const fileMetaDataText = (fileType: string | string[],
    maximumFileSize: number | undefined) => {
    let mdTextPart1 = '';
    let mdTextPart2 = '';
    const fileFormatLength = fileType.length;
    if (Array.isArray(fileType)) {
      if (fileFormatLength > 2) {
        mdTextPart1 = `Accepts ${fileType.slice(0, fileFormatLength - 1)
          .join(' ,')} & ${fileType[fileFormatLength - 1]}`;
      } else if (fileFormatLength === 2) {
        mdTextPart1 = `Accepts ${fileType.join(' & ')}`;
      } else {
        mdTextPart1 = `Accepts ${fileType}`;
      }
    } else {
      mdTextPart1 = `Accepts ${fileType}`;
    }
    if (maximumFileSize) {
      mdTextPart2 = ` with a max file size of ${bytesToSize(maximumFileSize)}`;
    }
    return `${mdTextPart1}${mdTextPart2}`;
  };

  const resetFileAttributes = () => {
    setFileName('');
    setFileSize('');
    setFileAttributeVisibility(false);
    onFileDrop([]);
  };

  const handleDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (acceptedFiles.length > 0) {
      const [file] = acceptedFiles;
      const { name, size } = file;
      setFileName(name);
      setFileSize(bytesToSize(size));
      setFileAttributeVisibility(true);
    } else if (fileRejections.length > 0) {
      const [fileRejection] = fileRejections;
      const { errors } = fileRejection;
      const [error] = errors;
      const { message } = error;
      resetFileAttributes();
      toast({ title: 'Error', description: message, status: 'error' });
    }
    onFileDrop(acceptedFiles);
  };

  const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleDrop,
    accept: fileFormat,
    multiple,
    /* Kept only 'Browse' button click. Disabled parent div click.
        Needed to disable parent click since enabling parent and
        'Browse' button clicks would work on Firefox but not on Safari and
        Chrome.
        TODO: Investigate how to enable both parent and child clicks.
        Resources:
        https://react-dropzone.js.org/#section-event-propagation
        https://github.com/react-dropzone/react-dropzone/issues/928
    */
    noClick: true,
  });
  const fileTypeName = Array.isArray(fileFormat)
  && fileFormat.length > 1 ? 'file' : `${fileFormat}`.slice(1);

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        padding={isDragActive ? '3rem' : 3}
        borderStyle={isDragActive ? 'solid' : 'dashed'}
        borderColor={
          isDragActive
            ? 'activePrimaryColor'
            : 'darkContainerBorderColor'
        }
        borderWidth={1}
        borderRadius={4}
        style={styles}
        {...getRootProps(
          /*
          onClick handler added because on click of 'Browse' text,
          the file dialog was getting opened twice.
          Possible solution found here:
          https://github.com/react-dropzone/react-dropzone/issues/
          182#issuecomment-586114746

          But commented this out currently due to using
          'noClick: true' of useDropZone hook above.
            {
            onClick: e => {
              e.stopPropagation();
            }
        }
      */)}
      >
        <input {...getInputProps()} />
        <Icon
          name={isDragActive ? 'attachment' : 'csvUploadBlack'}
          marginBottom={1}
          size={isDragActive ? '6' : '4'}
        />
        <Text
          type={TextTypes.tertiaryHeader}
          fontSize={isDragActive ? '14px' : '12px'}
        >
          {
            isDragActive
              ? (`Drop your ${fileTypeName === 'file'
                ? 'file' : fileTypeName.toUpperCase()} here`)
              : `Drag and drop your ${fileTypeName === 'file'
                ? '' : fileTypeName.toUpperCase()} file`
          }
        </Text>
        {!isDragActive
        && (
          <>
            <Text
              type={TextTypes.tertiaryHeader}
              px={1}
              py={1}
            >
              or
            </Text>
            <Flex>
              <Button
                buttonType={ButtonTypes.PrimaryLink}
                onClick={open}
                paddingLeft={0}
                paddingRight={1}
                // TODO: If possible, find a better way to handle hover
                // states in buttons.
                _hover={{
                  backgroundColor: 'transparent',
                }}
              >
                Browse
              </Button>
              <Text
                type={TextTypes.tertiaryHeader}
              >
                to upload your file
              </Text>
            </Flex>
          </>
        )}
      </Flex>
      {fileAttributeVisibility
        ? (
          <FilesChosen
            styles={fileAttributeStyles}
            name={fileName}
            handleFileVisibility={resetFileAttributes}
          />
        )
        : (
          <Text
            fontSize={12}
            textAlign="center"
            color="disabledText"
          >
            {fileMetaDataText(fileFormat, maxFileSize)}
          </Text>
        )}
    </>
  );
};

export default DragDrop;
