import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  useToast,
  FormHelperText,
} from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
  Button,
  ButtonTypes,
  Input,
  Loader,
  LoaderTypes,
  Text,
  TextTypes,
} from '../../../../components';
import { renderOnboardingStatusData } from '../Dashboard';
import SellerEngagments from './SellerEngagments';
import CustomerContracts from './CustomerContracts';
import SetTermsAndConditions from './SetTermsAndConditions';
import SetIPViolation from './SetIPViolation';
import {
  OnboardingStepStatus,
  useUpdateCustomerMutation,
} from '../../../../graphql';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { EMAIL_VALIDATION_PATTERN } from '../../../../utils/regex';
import { EMAIL_REQUIRED, INVALID_EMAIL } from '../../../../variables/contants';
import { ITermsAndCondition } from '../../interfaces';

interface Props {
  customer: any, // TODO -- need to fix this
  customerId: string,
  loading: boolean,
  refetch: () => void,
}

const CustomerDetails = ({ customer, customerId, loading, refetch }: Props) => {
  const location = useLocation<{
    isEdit: boolean;
    name: string;
    email: string;
    phone: string;
    onBoardingStatus: OnboardingStepStatus;
  }>();
  const [
    updateCustomer,
    { loading: updateCustomerLoading },
  ] = useUpdateCustomerMutation();
  const [impactLetterCount, setImpactLetterCount] = useState<number>(2);
  const [engageSellersValue, setEngageSellersValue] = useState(false);
  const [monitorMarketplaceValue, setMonitorMarketplaceValue] = useState(false);
  const [
    termsAndCondition,
    setTermsAndCondition,
  ] = useState<ITermsAndCondition | null>(null);

  const { register, handleSubmit, errors } = useForm<{
    name: string,
    customerEmail: string,
    address: string,
    number: string,
  }>();
  const toast = useToast();
  const updateCustomerDetails = async (formValues:Record<string, string>) => {
    try {
      await updateCustomer({
        variables: {
          input: {
            id: customerId,
            name: formValues.name,
            address: formValues.address,
            phone: formValues.number,
            email: formValues.customerEmail,
          },
        },
      });
      // reset();
      toast({
        title: 'User Updated Successfully!',
        status: 'success',
      });
      refetch();
    } catch (error) {
      toast({
        title: 'Error!',
        description: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  useEffect(() => {
    if (customer.customer.customerTermsAndCondition) {
      const {
        /*
         * Destructed __typename &  id as _ so that we can destructure
         * 'termsAndConditions' from 'termsAndConditionPayload' on line 111
         */
        __typename,
        id: _,
        ...termsAndConditionPayload
      } = customer.customer.customerTermsAndCondition;
      const { termsAndConditions } = termsAndConditionPayload;
      setTermsAndCondition(termsAndConditions);
    }
  }, [customer]);

  useEffect(() => {
    if (customer?.customer?.brands?.edges) {
      const brandEdges = customer.customer.brands.edges;
      const [brandNode] = brandEdges;
      const [brandMarketplacesNode] = brandNode.node.brandMarketplaces.edges;
      const {
        impactLetterCount: numOfImpactLetters,
        monitorMarketplace,
        engageSellers,
      } = brandMarketplacesNode.node;
      setImpactLetterCount(numOfImpactLetters);
      setEngageSellersValue(engageSellers);
      setMonitorMarketplaceValue(monitorMarketplace);
    }
  }, [customer]);

  return (
    <Flex
      flexDir="row"
      justifyContent="space-between"
      width="100%"
      height="100%"
      // Hardcoded color as mock is not available for admin portal
      backgroundColor="#F7F8FC"
      padding={8}
      // direction="column"
      maxHeight="100%"
      paddingBottom={0}
    >
      <Box width="45%">
        <Flex
          // width="50%"
          justifyContent="space-between"
          alignItems="flex-start"
          marginBottom={2}
        >
          <Text type={TextTypes.header}>
            Customers Details
          </Text>
        </Flex>

        <Box
          // width="50%"
          // marginTop={2}
          flexGrow={1}
        >
          <form
            onSubmit={handleSubmit(updateCustomerDetails)}
            style={{
              width: '100%',
            }}
          >
            {
            /*
            * Keeping disabled fields for now.
            * Need to discuss what fields can be updated from admin side.
            * */
            }
            <FormControl width="100%" marginBottom={4}>
              <FormLabel htmlFor="name" aria-required>
                Customer Name
              </FormLabel>
              <Input
                ref={
                  register({
                    required: true,
                  })
                }
                type="text"
                id="name"
                name="name"
                defaultValue={
                  customer?.customer?.name || location.state?.name
                }
              />
            </FormControl>

            <FormControl marginBottom={4} isRequired isDisabled>
              <FormLabel htmlFor="customerEmail" aria-required isDisabled>
                Customer Email
              </FormLabel>
              <Input
                isDisabled
                type="email"
                id="customerEmail"
                name="customerEmail"
                aria-describedby="customer-email-helper-text"
                defaultValue={
                  customer?.customer?.email
                  || location.state?.email
                  || ''
                }
                ref={
                  register({
                    required: { value: true, message: EMAIL_REQUIRED },
                    pattern: {
                      value: EMAIL_VALIDATION_PATTERN,
                      message: INVALID_EMAIL,
                    },
                  })
                }
              />
              {
                errors?.customerEmail && (
                  <FormHelperText
                    fontSize="xs"
                    id="customer-email-helper-text"
                  >
                    {errors?.customerEmail?.message}
                  </FormHelperText>
                )
              }
            </FormControl>
            <FormControl marginBottom={4}>
              <FormLabel>
                Onboarding status
              </FormLabel>
              {
                renderOnboardingStatusData(
                  customer?.customer?.customerOnboardingStep?.status
                  || location.state?.onBoardingStatus,
                )
              }
            </FormControl>
            {
              loading ? (
                <Loader type={LoaderTypes.Content} />
              ) : (
                <>
                  <FormControl marginBottom={4} isDisabled>
                    <FormLabel>
                      Number of Impact Letters
                    </FormLabel>
                    <Select value={impactLetterCount} isReadOnly>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                    </Select>
                  </FormControl>

                  <FormControl marginBottom={4}>
                    <FormLabel htmlFor="number">
                      Phone Number
                    </FormLabel>
                    <Input
                      name="number"
                      type="number"
                      id="number"
                      aria-describedby="number-helper-text"
                      defaultValue={customer?.customer?.phone || ''}
                    />
                  </FormControl>
                  <FormControl marginBottom={4}>
                    <FormLabel htmlFor="address">
                      Address
                    </FormLabel>
                    <Textarea
                      focusBorderColor="activePrimaryColor"
                      ref={
                        register({
                          required: true,
                        })
                      }
                      name="address"
                      type="text"
                      id="address"
                      aria-describedby="address-helper-text"
                      defaultValue={customer?.customer?.address || ''}
                    />
                  </FormControl>
                </>
              )
            }

            <Flex
              justifyContent="center"
            >
              <Button
                isLoading={loading || updateCustomerLoading}
                isDisabled={loading || updateCustomerLoading}
                type="submit"
                buttonType={ButtonTypes.Primary}
                marginBottom={6}
                width="100%"
                maxWidth="250px"
              >
                Update Customer
              </Button>
            </Flex>
          </form>
        </Box>
        <Divider
          flexBasis="100%"
          borderColor="lightBorderColor"
          opacity={1}
          my={6}
        />
        <SellerEngagments
          isLoading={loading}
          engageSellers={engageSellersValue}
          monitorMarketplace={monitorMarketplaceValue}
          brandId={customer?.customer?.brands?.edges[0]?.node?.id}
          refetch={refetch}
        />
      </Box>
      <Box width="45%">
        <CustomerContracts
          isCreateCustomer={Boolean(!(customer?.customer?.customerContract))}
          isLoading={loading}
          customerId={customerId}
          term={customer?.customer?.customerContract?.term}
          setupFee={customer?.customer?.customerContract?.setupFee}
          monthlyFee={customer?.customer?.customerContract?.monthlyFee}
          refetch={refetch}
        />
        <Divider
          flexBasis="100%"
          borderColor="lightBorderColor"
          opacity={1}
          my={6}
        />
        <SetTermsAndConditions
          customerId={customerId}
          isLoading={loading}
          termsAndCondtion={termsAndCondition}
          onboardingStepStatus={
            customer?.customer?.customerOnboardingStep?.status
            || location.state?.onBoardingStatus
          }
          refetch={refetch}
        />
        <Divider
          flexBasis="100%"
          borderColor="lightBorderColor"
          opacity={1}
          my={6}
        />
        <SetIPViolation
          customerHasNotSetIpViolation={customer.customer.ipViolation === null}
          customerId={customerId}
          isLoading={loading}
          refetch={refetch}
          ipViolationId={customer?.customer?.ipViolation?.id ?? ''}
        />
      </Box>
    </Flex>
  );
};

export default CustomerDetails;
