import React, { memo, CSSProperties, useState, useEffect } from 'react';
import { Flex, Image } from '@chakra-ui/core';
import styled from '@emotion/styled';
import { Overlay } from '../..';

interface ISplitWithImage {
  children: JSX.Element;
  setImageToFirstSection?: boolean;
  withGrid?: {
    enable?: boolean;
    imageWrapperStyles?: CSSProperties;
  }
}

/* Typing emotion styled components using
  type : https://emotion.sh/docs/typescript#passing-props */
type IStyleImageProps = {
  imageLoaded: boolean
};

const SytledImage = styled.img<IStyleImageProps>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 2s opacity;
  opacity: ${(props: IStyleImageProps) => (props.imageLoaded ? 1 : 0)};
`;

/* TODO: Add Progressive Image Loading. Currently, under slow internet speeds,
  background color is visible. Will try to render a low-res version of the image
  with blur effect until the full image is not loaded.
*/

/* Added 'splitContainerImages' outside the component to avoid adding it as
   a useEffect dependency */
const splitContainerImages = [
  '/splitContainerImages/split-image-1.png',
  '/splitContainerImages/split-image-2.jpg',
  '/splitContainerImages/split-image-3.jpg',
  '/splitContainerImages/split-image-4.jpg',
  '/splitContainerImages/split-image-5.jpg',
  '/splitContainerImages/split-image-6.jpg',
  '/splitContainerImages/split-image-7.jpg',
  '/splitContainerImages/split-image-8.jpg',
  '/splitContainerImages/split-image-9.jpg',
  '/splitContainerImages/split-image-10.jpg',
  '/splitContainerImages/split-image-11.jpg',
  '/splitContainerImages/split-image-12.png',
];

const SplitWithImage: React.FC<ISplitWithImage> = ({
  children,
  withGrid,
  setImageToFirstSection,
}: ISplitWithImage) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [splitImage, setSplitImage] = useState(splitContainerImages[0]);
  useEffect(() => {
    setSplitImage(splitContainerImages[Math.floor(
      Math.random() * splitContainerImages.length,
    )]);
  /* Added 'imageLoaded' dependency so that the split image does not change
     on every render */
  }, [imageLoaded]);
  if (withGrid?.enable) {
    return (
      <>
        {children}
        <div style={withGrid.imageWrapperStyles}>
          <Overlay>
            <Image
              src="/splitContainerImages/split-image-1.png"
              height="100%"
              width="100%"
              objectFit="cover"
            />
          </Overlay>
        </div>
      </>
    );
  }
  return (
    <Flex
      direction={setImageToFirstSection ? 'row-reverse' : 'row'}
      flexGrow={1}
      height="100%"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        direction="column"
        overflow="auto"
        minWidth={['100%', 360, 420, 720]}
        padding={4}
      >
        {children}
      </Flex>
      <Flex flexGrow={1} position="relative">
        <Overlay>
          <SytledImage
            imageLoaded={imageLoaded}
            src={splitImage}
            onLoad={() => setImageLoaded(true)}
          />
        </Overlay>
      </Flex>
    </Flex>
  );
};

export default memo(SplitWithImage);
