/* TODO: Look for a way to remove the firebase sdk warning
   while using this import statement
*/
import firebase from 'firebase/app';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  // databaseURL: process.env.REACT_APP_DATABASE_URL,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  auth: any;

  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
      this.auth = firebase.app().auth();
    }
  }

  doSignInWithEmailAndPassword = (
    email: string,
    password: string,
  ) => this.auth.signInWithEmailAndPassword(email, password);

  doSignInGoogle = () => this.auth.signInWithPopup(
    new firebase.auth.GoogleAuthProvider(),
  );

  doSignInMicrosoft = () => this.auth.signInWithPopup(
    new firebase.auth.OAuthProvider('microsoft.com'),
  );

  signOut = () => this.auth.signOut();

  sendPasswordResetEmail = (
    email: string,
  ) => this.auth.sendPasswordResetEmail(email);

  verifyPasswordResetCode = (
    actionCode: string,
  ) => this.auth.verifyPasswordResetCode(actionCode);

  confirmPasswordReset = (
    actionCode: string,
    password: string,
  ) => this.auth.confirmPasswordReset(actionCode, password);

  createUserWithEmailAndPassword = (
    email: string,
    password: string,
  ) => this.auth.createUserWithEmailAndPassword(email, password);
}

export default Firebase;
