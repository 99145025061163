import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import ContentLoader from 'react-content-loader';
import {
  Text, Flex, Modal, ModalOverlay,
  ModalContent, ModalBody, Spinner,
} from '@chakra-ui/core';

export enum LoaderTypes {
  Content,
  Table,
  FullView,
  FullViewModal,
  FullViewModalNoSpinner // TODO : Fix this better
}

interface Props {
  large?: boolean;
  message?: string;
  type?: LoaderTypes;
}

interface FullViewLoaderProps {
  message?: string;
}

interface FullViewModalLoaderProps {
  message?: string;
}

interface Style {
  opacity: number;
}

interface TableLoaderProps {
  key: number;
  style: Style;
}

const TableRow = ({ style }: TableLoaderProps) => {
  const random = Math.random() * (1 - 0.7) + 0.7;
  return (
    <ContentLoader height={20} width={1060} speed={2} style={style}>
      <rect x="34" y="13" rx="3" ry="3" width={200 * random} height="5" />
      <rect x="300" y="13" rx="3" ry="3" width={78 * random} height="5" />
      <rect x="450" y="13" rx="3" ry="3" width={78 * random} height="5" />
      <rect x="603" y="13" rx="3" ry="3" width={78 * random} height="5" />
      <rect x="755" y="13" rx="3" ry="3" width={117 * random} height="5" />
      <rect x="938" y="13" rx="3" ry="3" width={83 * random} height="5" />
    </ContentLoader>
  );
};

const TableLoader = () => (
  <>
    {Array(5)
      .fill('')
      .map((e, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={key} style={{ opacity: Number(2 / key + 1) }} />
      ))}
  </>
);

const CardContentLoader = () => (
  <ContentLoader height={80} width={400} speed={2}>
    <rect x="0" y="0" rx="3" ry="3" width="250" height="3" />
    <rect x="20" y="10" rx="3" ry="3" width="220" height="3" />
    <rect x="20" y="20" rx="3" ry="3" width="170" height="3" />
    <rect x="0" y="30" rx="3" ry="3" width="250" height="3" />
    <rect x="20" y="40" rx="3" ry="3" width="200" height="3" />
    <rect x="20" y="50" rx="3" ry="3" width="80" height="3" />
  </ContentLoader>
);

const FullViewLoader = ({ message }: FullViewLoaderProps) => (
  <Flex
    position="absolute"
    width="100%"
    height="100vh"
    justifyContent="center"
    alignItems="center"
    backgroundColor="rgba(0,0,0,0.5)"
    top={0}
  >
    <Flex alignItems="center">
      <FontAwesomeIcon icon={faCircleNotch} spin size="3x" />
      {message && <Text marginLeft={6}>{message}</Text>}
    </Flex>
  </Flex>
);

const FullViewModalLoader = ({
  message,
}: FullViewModalLoaderProps) => (
  <Modal
    isOpen
    isCentered
  >
    <ModalOverlay />

    <ModalContent borderRadius={10}>
      <ModalBody>
        <Flex
          direction="column"
          textAlign="center"
          alignItems="center"
          marginTop={10}
          marginBottom={6}
        >
          {/* TODO: Commenting this GF logo icon and replacing it with a
               spinner. Need to replace the spinner with animated GF logo
               in future.
           */}
          {/* <Icon
            name="loader"
            width={100}
            height={63}
            marginBottom={6}
          /> */}
          <Spinner
            color="activePrimaryColor"
            size="xl"
            mb={3}
          />
          {
            message ? (
              <Text marginBottom={2}>
                {message}
              </Text>
            ) : (
              <Text>
                This will only take a matter of seconds...
              </Text>
            )
          }
        </Flex>
      </ModalBody>
    </ModalContent>
  </Modal>
);

const FullViewModalNoSpinner = ({
  message,
}: FullViewModalLoaderProps) => (
  <Modal
    isOpen
    isCentered
  >
    <ModalOverlay />

    <ModalContent borderRadius={10}>
      <ModalBody>
        <Flex
          direction="column"
          textAlign="center"
          alignItems="center"
          marginTop={10}
          marginBottom={6}
        >
          {/* TODO: Refactor this modal loader into a better one.
           */}
          {
            message ? (
              <Text marginBottom={2}>
                {message}
              </Text>
            ) : (
              <Text>
                This will only take a matter of seconds...
              </Text>
            )
          }
        </Flex>
      </ModalBody>
    </ModalContent>
  </Modal>
);

const Loader = (props: Props) => {
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldShow(true);
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!shouldShow) {
    return null;
  }

  const { large, message, type } = props;

  switch (type) {
  /* eslint-disable indent */
    case LoaderTypes.Table:
      return <TableLoader />;
    case LoaderTypes.Content:
      return <CardContentLoader />;
    case LoaderTypes.FullView:
      return <FullViewLoader message={message} />;
    case LoaderTypes.FullViewModal:
      return <FullViewModalLoader message={message} />;
    case LoaderTypes.FullViewModalNoSpinner:
      return <FullViewModalNoSpinner message={message} />;
    default:
      return (
        <Text fontSize={large ? 'lg' : 'md'}>
          <FontAwesomeIcon
            icon={faCircleNotch}
            spin
            style={{ marginRight: '.5em' }}
          />
          {message}
        </Text>
      );
  /* eslint-enable indent */
  }
};

export default Loader;
