/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import { Input as InputComponent, InputProps } from '@chakra-ui/core';

export enum InputTypes {
  FormInput
}

export enum InputWeight {
  Normal = 'normal',
  Medium = 'medium',
  Bold = 'bold',
}

interface IFormLabel extends InputProps {
  weight?: InputWeight | string | number;
  inputType?: InputTypes;
}

const FormLabel = React.forwardRef<
HTMLInputElement,
IFormLabel
>(({
  weight = InputWeight.Normal,
  inputType = InputTypes.FormInput,
  ...restInputProps
}: IFormLabel, forwardedRef) => (
  <InputComponent
    fontWeight={weight}
    {
      // Theme configuration for FormInput.
      ...(inputType === InputTypes.FormInput) && {
        // Styling for input container
        border: '1px solid',
        borderRadius: 4,
        boxSizing: 'border-box',

        // Styling for input text
        fontStyle: 'normal',
      }
    }
    color="textFieldTextColor"
    backgroundColor="defaultBackgroundColor"
    borderColor="lightBorderColor"
    focusBorderColor="activePrimaryColor"
    fontSize="16px"
    letterSpacing="0.01em"
    lineHeight="19px"
    ref={forwardedRef}
    {...restInputProps}
    // @TODO - Need to check for handling of font family across board.
    // fontFamily={}
  />
));

export default memo(FormLabel);
