/* eslint-disable @typescript-eslint/indent */
import React, { memo, useCallback, useState } from 'react';
import {
  Flex,
  Box, useDisclosure,
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import {
  DefaultTemplateFragment,
  useDefaultTemplateListQuery,
} from '../../../graphql';
import {
  Button, ButtonTypes,
  Text,
  TextTypes,
} from '../../../components';
import TemplateModal from './TemplateModal';

const StyledUserTable = styled.table`
  width: 100%;
  box-shadow: 3px 3px 5px 6px #ccc;

  thead {
    background-color: #FAFBFF;
    text-align: left;
  }

  tbody tr {
    background-color: white;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }

  th, td {
    padding: 16px;
  }

  tr {
    border-bottom: 1px solid #BDBDBD;
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  // @TODO - Need to remove hardcoded 475px
  tbody {
    display: block;
    max-height: 475px;
    overflow-y: scroll;
  }

  .stage {
    width: 130px;
  }

  .template {
    width: 500px;
  }

  .letterformat {
    width: 300px;
  }

  #letterformathead {
    margin-left: 185px;
  }

`;

const DefaultTemplates: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [
    selectedTemplate, setSelectedTemplate,
  ] = useState<DefaultTemplateFragment | null>();
  const [templateTitle, setTemplateTitle] = useState('Add Default Template');
  const {
    data: templateData,
    // loading: customersLoading,
    error: templateError,
    fetchMore: templateFetchMore,
    loading,
    refetch,
  } = useDefaultTemplateListQuery({
    variables: {
      first: 10,
    },
    // fetchPolicy: 'cache-and-network',
  });

  const handleContainerOnBottom = useCallback(() => {
    if (templateData?.defaultTemplateList?.pageInfo?.hasNextPage) {
      templateFetchMore({
        variables: {
          after: templateData?.defaultTemplateList?.pageInfo.endCursor,
        },
        // TODO: Replace 'updateQuery' with field-policy in apolloClient.ts file
        updateQuery: (prev: any, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult?.defaultTemplateList?.edges;
          const pageInfo = fetchMoreResult?.defaultTemplateList?.pageInfo;
          return newEdges?.length
            ? {
              __typename: prev.defaultTemplateList?.__typename,
              defaultTemplateList: {
                __typename: prev.defaultTemplateList?.__typename,
                edges: [
                  ...prev?.defaultTemplateList?.edges,
                  ...newEdges,
                ],
                pageInfo,
              },
            }
            : prev;
        },
      });
    }
  }, [templateData, templateFetchMore]);

  const containerRef = useBottomScrollListener(handleContainerOnBottom);

  const onDialogOpen = (
    title: string,
    template?: DefaultTemplateFragment,
  ) => {
    setSelectedTemplate(template || null);
    setTemplateTitle(title);
    onOpen();
  };

  const onDialogClose = async (template?: DefaultTemplateFragment) => {
    onClose();
    if (template) {
      await refetch();
    }
  };

  if (templateError) {
    return <div>Something went wrong, please try again!</div>;
  }

  if (!templateData && !loading) {
    return <div>No Data</div>;
  }

  return (
    <Flex
      width="100%"
      height="100%"
      // Hardcoded color as mock is not available for admin portal
      backgroundColor="#F7F8FC"
      padding={8}
      direction="column"
      maxHeight="100%"
      paddingBottom={0}
    >
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        marginBottom={4}
      >
        <div>
          <Button
            buttonType={ButtonTypes.Primary}
            leftIcon="add"
            onClick={() => onDialogOpen('Add Default Template')}
          >
            Add Default Template
          </Button>
        </div>
        <TemplateModal
          templateTitle={templateTitle}
          template={selectedTemplate}
          isOpen={isOpen}
          onClose={onDialogClose}
        />
      </Flex>
      <Box
        flexGrow={1}
      >
        <Box
          height="100%"
          flexGrow={1}
          maxHeight="100%"
          paddingBottom={4}
          overflow="auto"
        >
          <StyledUserTable>
            <thead>
              <tr>
                <th className="stage">
                  <Text type={TextTypes.secondaryHeader}>
                    Stage
                  </Text>
                </th>
                <th>
                  <Text type={TextTypes.secondaryHeader} className="template">
                    Template
                  </Text>
                </th>
                <th>
                  <Text
                    type={TextTypes.secondaryHeader}
                    className="letterformat"
                    id="letterformathead"
                  >
                    Letter Format
                  </Text>
                </th>
                <th>
                  <Text />
                </th>
              </tr>
            </thead>
            <Box
              overflowY="auto"
              as="tbody"
              ref={containerRef}
            >
              {
                templateData?.defaultTemplateList?.edges?.map(
                  (templateEdge) => templateEdge && templateEdge.node && (
                    <tr
                      key={templateEdge.node.id}
                      /* onClick={
                        (event: any) => {
                          event.stopPropagation();
                          onDialogOpen(
                            'Edit Default Template',
                            templateEdge?.node!,
                          );
                        }
                      } */
                    >
                      <Box as="td" className="stage">
                        <Text>
                          {templateEdge.node.stage}
                        </Text>
                      </Box>
                      <Box as="td" className="template">
                        <Flex
                          alignItems="center"
                        >
                          <Flex
                            direction="column"
                          >
                            <Text type={TextTypes.body}>
                              <p>
                                {templateEdge.node.template.length > 128
                                  ? `${
                                    templateEdge.node.template.substring(0, 128)
                                  }...`
                                  : templateEdge.node.template}
                              </p>
                            </Text>
                          </Flex>
                        </Flex>
                      </Box>
                      <Box as="td" className="letterformat">
                        <Text type={TextTypes.body}>
                          {templateEdge.node.letterFormat}
                        </Text>
                      </Box>
                      <Box
                        as="td"
                        style={{ textAlign: 'center' }}
                      >
                        <Box
                          onClick={
                            (event: any) => {
                              event.stopPropagation();
                              onDialogOpen(
                                'Edit Default Template',
                                templateEdge?.node!,
                              );
                            }
                          }
                        >
                          Edit
                        </Box>
                      </Box>
                    </tr>
                  ),
                )
              }
            </Box>
          </StyledUserTable>
        </Box>
      </Box>
    </Flex>
  );
};

export default memo(DefaultTemplates);

