import React, { memo, useCallback, useState, useRef } from 'react';
import {
  Box,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  ButtonGroup,
  Stack,
  Textarea,
  Icon,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  PseudoBox,
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import html2canvas from 'html2canvas';
import dompurify from 'dompurify';
import {
  Button, ButtonTypes, TextTypes,
  Text as AppText,
  Loader,
  LoaderTypes,
} from '../../../components';
import colors from '../../../variables/colors';
import {
  LetterApprovalStatus,
  useUploadLetterHeadMutationMutation,
} from '../../../graphql';
import { getBrandID, getBrandLetterApprovalStatus } from '../../../utils/files';
import { TypeOfLetterBuilderTabs } from '../../../variables/types';
import { IBuilderTab, ILetterTabs } from '../interfaces';
import { DEFAULT_LETTER_BUILDER_IMAGE } from '../../../variables/contants';

const StyledRevisionStatusWrapper = styled.div`
  > div:not(:last-child) {
    border-bottom: 2px solid #E3E8EF;
  }
`;

const LetterTabs: React.FC<ILetterTabs> = ({
  handleNextStep, address, email,
  phoneNumber, letterImage, builderTabs,
  handleRevisionChanges, handleCreateRevisionRequest,
  brandLetterRevisionLoading, advancingUserLoader,
  approveBrandLetterLoading, handleApproveBrandLetter,
  revisionRequestTextPayload, refetchBrandStatus,
}: ILetterTabs) => {
  const toast = useToast();
  const letterHeadRef = useRef<HTMLDivElement>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [enabledRevisionTabId, setEnabledRevisionTabId] = useState<string>('');
  const [
    selectedRevisionTextForModal,
    setSelectedRevisionTextForModal,
  ] = useState({
    title: '', revisionBody: '',
  });
  const [
    uploadLetterHead,
    {
      loading: uploadLetterHeadLoading,
      error: uploadLetterHeadError,
    }] = useUploadLetterHeadMutationMutation();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const isDefaultLetterBuilderImage = useCallback((
    imagePathToCompare: string,
  ) => (imagePathToCompare === DEFAULT_LETTER_BUILDER_IMAGE), []);

  const isValidLetterhead = !isDefaultLetterBuilderImage(letterImage)
    && address
    && phoneNumber
    && email;

  const renderLetterHead = useCallback((isPreview: Boolean = false) => (
    <Flex
      alignItems="center"
      /* NOTE: Added this below prop to handle broken UI on SAFARI browser
      for letterhead  */
      flex="1 0 auto"
      direction="row"
      justifyContent="space-between"
      /** Disabling es-lint rule,
       * as we are checking some condition instead of spreading */
      // eslint-disable-next-line react/jsx-props-no-spreading
      {
        ...isPreview && {
          border: '1px solid',
          borderColor: 'lightBorderColor',
          borderRadius: 4,
          padding: 4,
        }
      }
      marginBottom={6}
      /* NOTE: Pass reference of letterHead element present at Review tab
      . If this is not done, current implementation would miss the element
        when navigating from impact letter tabs to Review tab.
      */
      ref={isPreview ? letterHeadRef : null}
    >
      {
        // Render empty div if user haven't added any image yet
        isPreview && isDefaultLetterBuilderImage(letterImage)
          ? <div style={{ width: 80, height: 80 }} />
          : isDefaultLetterBuilderImage(letterImage) ? (
            <Icon name={letterImage} width={80} height={80} />
          ) : (
            <img
              src={letterImage}
              style={{ width: 80, maxHeight: 80 }}
              alt="Reseller Icon"
            />
          )
      }
      <Box textAlign="right">
        <AppText
          type={TextTypes.tertiaryHeader}
          marginBottom={1}
        >
          {address}
        </AppText>
        <AppText
          type={TextTypes.tertiaryHeader}
          marginBottom={1}
        >
          {phoneNumber}
        </AppText>
        <AppText
          type={TextTypes.tertiaryHeader}
          marginBottom={1}
        >
          {email}
        </AppText>
      </Box>
    </Flex>
  ), [address, email, isDefaultLetterBuilderImage, letterImage, phoneNumber]);

  const renderRevisionStatus = useCallback(
    (builderTab: IBuilderTab) => {
      const isBuilderTab = (
        builderTab.type === TypeOfLetterBuilderTabs.LetterBuilder
      );
      /*
       *  As per the current discussion, we should clear Revision Request text
       *  from the Text-Box after 'Approve' or 'Revise' button action. The
       *  Revision Request Text-box component will only contain local changes
       *  made by the customer. It won't show them the API data saved and
       *  displayed under the 'Review Tab'.
       * Hence 'builderTab.revision?.content' is used below to Toggle Revision
       * Request Text-Box based on local text.
       * Slack Discussion link :
        https://tech9.slack.com/archives/C010H97GYEN/
        p1607450676149300?thread_ts=1607450671.149200&cid=C010H97GYEN
       */
      if (isBuilderTab
        && (
          // enabledRevisionTabId || !!builderTab.revision?.content
          enabledRevisionTabId || !!revisionRequestTextPayload[
            selectedTabIndex
          ].content
        )) {
        return (
          <>
            <AppText type={TextTypes.secondaryHeader} marginBottom={1}>
              Revision Request
            </AppText>
            <Textarea
              // isDisabled={Boolean(builderTab.brandLetterRevisionContent)}
              // _disabled={{ backgroundColor: colors.disabledTertiaryColor }}
              resize="none"
              // borderColor="activePrimaryColor"
              borderColor={colors.defaultBorderColor}
              _hover={{
                borderColor: colors.activePrimaryColor,
              }}
              _focus={{
                borderColor: colors.activePrimaryColor,
              }}
              padding={3}
              minHeight={200}
              value={
                revisionRequestTextPayload[selectedTabIndex].content
                // builderTab.revision?.content
                // ? builderTab.revision?.content
                // : revisionText
              }
              onChange={
                (event: any) => handleRevisionChanges(
                  builderTab.brandLetterId, event.target.value,
                )
              }
            />
          </>
        );
      }

      return null;
    }, [
      enabledRevisionTabId,
      handleRevisionChanges,
      revisionRequestTextPayload,
      selectedTabIndex,
    ],
  );

  const renderRevisionTextModal = useCallback(
    () => (
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          overflow="auto"
          maxHeight="50vh"
          maxWidth="50vw"
        >
          <ModalHeader>{selectedRevisionTextForModal.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AppText>
              {selectedRevisionTextForModal.revisionBody}
            </AppText>
          </ModalBody>
        </ModalContent>
      </Modal>
    ),
    [isOpen, onClose, selectedRevisionTextForModal],
  );

  const renderLetterTab = useCallback((builderTab: IBuilderTab) => {
    const revisionRequested = (
      builderTab.id === enabledRevisionTabId
      || revisionRequestTextPayload[selectedTabIndex].requested
      // || builderTab.revision?.requested
    );
    return (
      <>
        <AppText mb={8} type={TextTypes.header}>{builderTab.title}</AppText>
        <AppText
          marginY={6}
          type={TextTypes.body}
        >
          To show professionalism and the seriousness of these
          actions we ask that you approve the following letters
          that will be communicated on your behalf.
        </AppText>
        {
          builderTab.letterContent && (
            <Stack
              style={{ wordWrap: 'break-word' }}
              border={`1px solid ${colors.defaultBorderColor}`}
              maxHeight="500px"
              overflow="auto"
              mb={4}
              padding={4}
              /** Disabling es-lint rule,
               * as we are checking some condition instead of spreading */
              // eslint-disable-next-line react/jsx-props-no-spreading
              {
                ...revisionRequested && {
                  maxHeight: '300px',
                  overflow: 'auto',
                  marginBottom: 4,
                }
              }
            >
              {renderLetterHead()}
              {/**
             * @TODO - Need to check for alternative way to
             * handle dynamic content.
             * This might leads to XXS attack.
             */}
              <Box
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(builderTab.letterContent
                    .split('\n')
                    .join('<br><br>')
                    .replace(/ {2}/g, '\u00a0\u00a0') || ''),
                }}
              />
            </Stack>
          )
        }
        {renderRevisionStatus(builderTab)}
        {/* {renderRevisionTextModal(
          `${builderTab.title} Revision Request`,
          builderTab.revision?.content
          || builderTab.brandLetterRevisionContent
          || '',
        )} */}
      </>
    );
  }, [
    enabledRevisionTabId,
    renderLetterHead,
    renderRevisionStatus,
    revisionRequestTextPayload,
    selectedTabIndex,
    // renderRevisionTextModal,
  ]);

  const handleSetSelectedRevisionTextInModal = useCallback((
    title: string,
    revisionBody: string,
  ) => {
    onToggle();
    setSelectedRevisionTextForModal({
      title,
      revisionBody,
    });
  }, [onToggle]);

  const renderRevisionStatusForReviewTab = useCallback(
    (builderTab: IBuilderTab) => {
      /*
       * On the Review tab, we only show revision request text submitted by
       * customer by making API request or received by API using API.
       * Modifications made by customer that are not submitted yet are not shown
       * in the Review tab.
       */

      const reviewRevisionText = builderTab.brandLetterRevisionContent ?? '';
      const revisionRequestOrDone = Boolean(
        builderTab.brandLetterRevisionContent,
      );
      const letterApprovalStatus = getBrandLetterApprovalStatus(
        builderTab.approvalStatus,
      );
      const clickableOrNot = reviewRevisionText.length > 50;
      return (
        <PseudoBox
          paddingY={6}
          onClick={
            clickableOrNot
              ? () => handleSetSelectedRevisionTextInModal(
                builderTab.title, builderTab.brandLetterRevisionContent!,
              )
              : undefined
          }
          cursor={clickableOrNot ? 'pointer' : undefined}
          _hover={{
            backgroundColor: clickableOrNot
              ? colors.lightBorderColor
              : 'transparent',
          }}
        >
          <Flex
            width="100%"
            justifyContent="space-between"
          >
            <AppText
              type={TextTypes.secondaryHeader}
              marginBottom={0}
            >
              {builderTab.title}
            </AppText>
            <span>
              {
                builderTab.approvalStatus === LetterApprovalStatus.Approved ? (
                  <Box>
                    <Icon
                      name="successTick"
                      marginRight={1}
                    />
                    <AppText
                      type={TextTypes.body}
                      color="advancementActionColor"
                      as="span"
                    >
                      Approved
                    </AppText>
                  </Box>
                ) : (
                  <Box>
                    <Icon
                      name="revision"
                      marginRight={1}
                    />
                    <AppText
                      type={TextTypes.body}
                      color="neutralActionColor"
                      as="span"
                    >
                      {letterApprovalStatus}
                    </AppText>
                  </Box>
                )
              }
            </span>
          </Flex>
          {
            revisionRequestOrDone
            && (
              /* maxWidth="600px" was added to truncate long
                revision request text in review tab.
              */
              <AppText
                maxWidth="600px"
                isTruncated
                type={TextTypes.body}
                marginTop={2}
              >
                {reviewRevisionText}
              </AppText>
            )
          }
        </PseudoBox>
      );
    },
    [handleSetSelectedRevisionTextInModal],
  );

  /* renderPreviewTab is the tab UI for 'Review' tab. */
  const renderPreviewTab = useCallback(() => (
    <Box>
      <AppText mb={8} type={TextTypes.header}>Review</AppText>
      <AppText
        marginY={6}
        type={TextTypes.body}
      >
        Take a moment to review your personalized letterhead, if
        changes need to be made at a later time you can
        do so by editing them from your Profile in your dashboard.
        Note that we cannot speak on behalf of our clients until
        all documentation has been approved. We will
        contact you through our internal messaging system at a
        later time to approve any revised messaging upon
        which you have requested.
      </AppText>
      <Box
        mb={3}
      >
        <AppText
          display="inline-block"
          type={TextTypes.header}
          marginRight={2}
        >
          Letterhead Preview
        </AppText>
        {
          (!isValidLetterhead) && (
            <Flex display="inline-flex" alignItems="center">
              <Icon name="warning" marginRight={1} />
              <AppText as="span" type={TextTypes.warning}>
                A letterhead is required before proceeding
              </AppText>
            </Flex>
          )
        }
      </Box>
      {renderLetterHead(true)}
      <StyledRevisionStatusWrapper>
        {
          builderTabs.map((
            builderTab: IBuilderTab,
          ) => {
            if (builderTab.type === TypeOfLetterBuilderTabs.LetterBuilder) {
              return (
                <div key={builderTab.id}>
                  {
                    renderRevisionStatusForReviewTab(builderTab)
                    // renderRevisionStatus(builderTab, true)
                  }
                </div>
              );
            }
            return null;
          })
        }
      </StyledRevisionStatusWrapper>
      {
        renderRevisionTextModal()
      }
    </Box>
  ), [
    builderTabs,
    isValidLetterhead,
    renderLetterHead,
    renderRevisionStatusForReviewTab,
    renderRevisionTextModal,
  ]);

  const onTabChange = useCallback((updatedIndex: number) => {
    setSelectedTabIndex(updatedIndex);
    setEnabledRevisionTabId('');
    // setRevisionText('');
  }, []);

  const handleNextTabClick = useCallback(() => {
    setSelectedTabIndex((prevIndex) => (prevIndex + 1));
    // handleNextStep
  }, []);

  const handleRequestSubmitButtonClick = () => {
    handleCreateRevisionRequest(
      builderTabs[selectedTabIndex].brandLetterId,
      revisionRequestTextPayload[selectedTabIndex].content,
    );
    handleNextTabClick();
    setEnabledRevisionTabId('');
    // setRevisionText('');
  };

  const handleApproveButtonClick = () => {
    // Clear Revision Text-Box after Revision request
    handleRevisionChanges(
      enabledRevisionTabId,
      '',
    );
    handleApproveBrandLetter(
      builderTabs[selectedTabIndex].brandLetterId,
    );
    setEnabledRevisionTabId('');
    // setRevisionText('');
    handleNextTabClick();
  };

  const generateLetterHeadScreenshot = useCallback((
    elementToCapture: HTMLDivElement,
  ) => html2canvas(elementToCapture, {
    width: elementToCapture.clientWidth,
    height: elementToCapture.clientHeight,
    // scrollX: 0,
    // scrollY: -window.scrollY,
    logging: true,
  }),
  []);

  const handleNextScreenClick = useCallback(async () => {
    if (letterHeadRef.current) {
      try {
        const canvas = await generateLetterHeadScreenshot(
          letterHeadRef.current,
        );
        // eslint-disable-next-line max-len
        const base64LetterHeadString = `b'${canvas.toDataURL('image/png').toString().split(',')[1]}'`;
        try {
          await uploadLetterHead({
            variables: {
              input: {
                id: getBrandID(),
                encodedLetterhead: base64LetterHeadString,
              },
            },
          });
          toast({
            title: 'Letter head uploaded Successfully!',
            status: 'success',
          });
          refetchBrandStatus();
          handleNextStep();
        } catch {
          toast({
            title: 'Error while uploading the letterhead!',
            description: `${uploadLetterHeadError?.message}`,
            status: 'error',
          });
        }
      } catch (error) {
        toast({
          title: 'Error while generating the letterhead!',
          description: `Error : ${error}`,
          status: 'error',
        });
      }
    }
  }, [
    generateLetterHeadScreenshot,
    uploadLetterHead,
    uploadLetterHeadError,
    toast,
    handleNextStep,
    refetchBrandStatus,
  ]);

  if (approveBrandLetterLoading) {
    return (
      <Loader
        type={LoaderTypes.FullViewModal}
        large
        message="Approving impact letter. Please wait."
      />
    );
  }

  return (
    <Box
      maxHeight="100%"
      padding={4}
    >
      <Box padding={1} marginBottom={8}>
        <Tabs
          onChange={onTabChange}
          index={selectedTabIndex}
          isFitted
          variant="enclosed"
          display="grid"
          gridTemplateRows="70px 1fr"
          gridRowGap="30px"
        >
          <TabList mb="1em">
            {
              builderTabs.map(
                (builderTab: IBuilderTab, currentTabIndex: number) => (
                  <Tab key={builderTab.title} _focus={{ boxShadow: 'none' }}>
                    <AppText type={
                      (selectedTabIndex >= currentTabIndex)
                        ? TextTypes.activeTabTitle
                        : TextTypes.disabledTabTitle
                    }
                    >
                      {builderTab.title}
                    </AppText>
                  </Tab>
                ),
              )
            }
          </TabList>
          <TabPanels>
            {
              builderTabs.map((
                builderTab: IBuilderTab,
              ) => (builderTab.type === TypeOfLetterBuilderTabs.LetterBuilder
                ? (
                  <TabPanel key={`${builderTab.title}_tab_panel`}>
                    {renderLetterTab(builderTab)}
                  </TabPanel>
                ) : (
                  <TabPanel key={`${builderTab.title}_tab_panel`}>
                    {renderPreviewTab()}
                  </TabPanel>
                )))
            }
          </TabPanels>
        </Tabs>
      </Box>
      {
        (builderTabs[selectedTabIndex].type
          === TypeOfLetterBuilderTabs.Review) ? (
            <Flex
              width="100%"
              justifyContent="center"
            >
              <Button
                isLoading={uploadLetterHeadLoading || advancingUserLoader}
                buttonType={ButtonTypes.Primary}
                onClick={handleNextScreenClick}
                width="100%"
                maxWidth={400}
                isDisabled={!isValidLetterhead || advancingUserLoader}
              >
                Save & Continue
              </Button>
            </Flex>
          ) : (
            <Flex
              direction="row-reverse"
              marginBottom={100}
            >
              <ButtonGroup>
                {
                  /*
                   * Revision Request Text-box will be visible when we click on
                   * 'Revise' button. If we enter text in the text-box and move
                   * to another tab and then come back, 'Cancel' & 'Submit' will
                   * be replaced by 'Revise' and 'Approve' with the text in the
                   * text-box intact.
                   */
                  enabledRevisionTabId
                    ? (
                      <>
                        <Button
                          buttonType={ButtonTypes.Link}
                          mr={2}
                          onClick={() => {
                            setEnabledRevisionTabId('');
                            // setRevisionText('');
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          isLoading={brandLetterRevisionLoading}
                          isDisabled={revisionRequestTextPayload[
                            selectedTabIndex].content.length === 0
                            || revisionRequestTextPayload[
                              selectedTabIndex].content.trim().length === 0}
                          buttonType={ButtonTypes.Primary}
                          onClick={handleRequestSubmitButtonClick}
                        >
                          Submit
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          // isDisabled={
                          //   builderTabs[selectedTabIndex].approvalStatus
                          // === LetterApprovalStatus.ReviewRequested
                          // }
                          buttonType={ButtonTypes.Link}
                          mr={2}
                          onClick={
                            () => {
                              setEnabledRevisionTabId(
                                builderTabs[selectedTabIndex].id,
                              );
                            }
                          }
                        >
                          Revise
                        </Button>
                        <Button
                          isDisabled={
                            approveBrandLetterLoading
                          || builderTabs[selectedTabIndex].approvalStatus
                            === LetterApprovalStatus.Approved
                          || builderTabs[selectedTabIndex].approvalStatus
                            === LetterApprovalStatus.ReviewRequested
                          }
                          isLoading={approveBrandLetterLoading}
                          buttonType={ButtonTypes.Primary}
                          onClick={handleApproveButtonClick}
                        >
                          Approve
                        </Button>
                      </>
                    )
                }
              </ButtonGroup>
            </Flex>
          )
      }
    </Box>
  );
};

export default memo(LetterTabs);
