import React, { memo, CSSProperties } from 'react';
import { FormLabel as FormLabelComponent } from '@chakra-ui/core';

export enum LabelTypes {
  Default,
}

export enum LabelWeight {
  Normal = 'normal',
  Medium = 'medium',
  Bold = 'bold',
}

interface IFormLabel {
  children: React.ReactNode;
  htmlFor?: string;
  weight?: LabelWeight | string | number;
  labelType?: LabelTypes;
  style?: CSSProperties;
}

const FormLabel: React.FC<IFormLabel> = ({
  children,
  htmlFor,
  weight = LabelWeight.Medium,
  labelType = LabelTypes.Default,
  style,
}: IFormLabel) => (
  <FormLabelComponent
    htmlFor={htmlFor}
    fontWeight={weight}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {
      // Theme configuration for default label.
      ...(labelType === LabelTypes.Default) && {
        fontSize: 'sm',
        lineHeight: '17px',
        fontStyle: 'normal',
        letterSpacing: '0.01em',
        color: 'formLabel',
      }
    }
    // @TODO - Need to check for handling of font family across board.
    // fontFamily={}
    style={style}
  >
    {children}
  </FormLabelComponent>
);

export default memo(FormLabel);
