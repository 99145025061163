import * as firebase from 'firebase';

export const isExpired = (jwtToken:any) => {
  if (!jwtToken) {
    return true;
  }
  const ts = new Date().getTime();
  const delta = 10 * 60 * 1000; // 10 minutes
  return (jwtToken.exp * 1000) <= ts + delta;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const getJWTToken = async () => {
  let token = localStorage.getItem('token');
  if (token) {
    const jwtToken = parseJwt(token);
    if (isExpired(jwtToken)) {
      // @ts-ignore
      token = await firebase.app().auth().currentUser?.getIdToken(true);
    }
    return token;
  }
  return null;
};

export const refreshAuthentication = async () => {
  try {
    const token = await getJWTToken();
    if (isExpired(token)) {
      clearJWTToken();
      clearUserDetails();
      return false;
    }
  } catch (error) {
    clearJWTToken();
    clearUserDetails();
    return false;
  }
  return true;
};

export const clearJWTToken = () => {
  localStorage.removeItem('token');
};

export const clearUserDetails = () => {
  localStorage.removeItem('user');
};

export const setJWTToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const setUserDetails = (user: any) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const getUserDetails = () => {
  const user = localStorage.getItem('user');
  return JSON.parse(user!);
};

export const getRandomCharacter = (
  availableCharacter: string = `ABCDEFGHIJKLMNOPQRSTUVWXYZ
                        abcdefghijklmnopqrstuvwxyz0123456789@#$`,
) => availableCharacter.charAt(
  Math.floor(Math.random() * availableCharacter.length + 1),
);

/**
 * Basic function to generate password.
 * For now keeping basic implementation only.
 */
export const generateRandomPassword = (
  passwordLength: number = 8,
): string => {
  let generatedPassword = '';
  for (let i = 1; i <= passwordLength; i += 1) {
    generatedPassword += getRandomCharacter();
  }
  return generatedPassword;
};

export const handleSignOut = async () => {
  await firebase.app().auth().signOut();
  localStorage.clear();
};
