/* eslint-disable @typescript-eslint/indent */
import React, { memo, useCallback, useState } from 'react';
import {
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  Select,
  useToast,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
  Text as ChakraText,
  Icon,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { InlineIcon as IconifyIcon } from '@iconify/react';
import chevronSort from '@iconify/icons-carbon/chevron-sort';
import userAvatarFilledAlt from '@iconify/icons-carbon/user-avatar-filled-alt';
import { useHistory } from 'react-router-dom';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useFirebaseProvider } from '../../../../core/firebaseContext';
import {
  useCreateCustomerMutation,
  useFetchCustomersWithMinimumDetailsQuery,
  OnboardingStepStatus,
  useCreateLetterResetLogMutation,
  CustomerNodeSortEnum,
} from '../../../../graphql';
import {
  Button,
  ButtonTypes,
  Text,
  Input,
  TextTypes,
  Loader,
  LoaderTypes,
} from '../../../../components';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { EMAIL_VALIDATION_PATTERN } from '../../../../utils/regex';
import {
  CUSTOMER_EMAIL_REQUIRED,
  INVALID_EMAIL,
} from '../../../../variables/contants';
import { ICustomerSortParameter } from '../../interfaces';

const customerSortParameters: ICustomerSortParameter[] = [
  {
    name: 'Last Updated (New to Old)',
    value: CustomerNodeSortEnum.UpdatedDesc,
    selected: true,
  },
  {
    name: 'Last Updated (Old to New)',
    value: CustomerNodeSortEnum.UpdatedAsc,
    selected: false,
  },
  {
    name: 'Name (A to Z)',
    value: CustomerNodeSortEnum.NameAsc,
    selected: false,
  },
  {
    name: 'Name (Z to A)',
    value: CustomerNodeSortEnum.NameDesc,
    selected: false,
  },
];

const StyledUserTable = styled.table`
  width: 100%;
  box-shadow: 3px 3px 5px 6px #ccc;

  /* table {
    border-width: 10px;
    border-color: black;
    border-style: solid;
  } */

  thead {
    background-color: #FAFBFF;
    text-align: left;
  }

  /* CSS Resource link: https://stackoverflow.com/a/49510703/1682304 */
  /* thead th {
    position: sticky;
    top: 0;
    z-index: 10;
  } */

  /* td box {
    width: 50px;
  } */

  tbody tr {
    background-color: white;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }

  th, td {
    padding: 13px;
  }

  tr {
    border-bottom: 1px solid #BDBDBD;
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  thead th {
    padding: 1rem;
  }

  /* tr td {
    :first-of-type {
      width: 40%;
    }
  } */
  // @TODO - Need to remove hardcoded 475px
  tbody {
    display: block;
    max-height: 70vh;
    overflow-y: scroll;
  }
`;

const Customers: React.FC = () => (
  <Flex
    width="100%"
    height="100%"
    // Hardcoded color as mock is not available for admin portal
    backgroundColor="#F7F8FC"
    padding={8}
    direction="column"
    maxHeight="100%"
    paddingBottom={0}
  >
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      marginBottom={10}
    >
      <CreateCustomersModal />
    </Flex>
    <Box
      flexGrow={1}
    >
      <RenderCustomers />
    </Box>
  </Flex>
);

const CreateCustomersModal: React.FC = () => {
  const [addingCustomer, setAddingCustomer] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [createCustomer, createCustomerResponse] = useCreateCustomerMutation();
  const { firebase } = useFirebaseProvider();
  const history = useHistory();
  const { register, handleSubmit, errors, reset } = useForm<{
    name: string,
    contactFirstName: string,
    contactLastName: string,
    email: string,
    address: string,
    number: string,
  }>();

  const toast = useToast();
  const isSubmittingUser = createCustomerResponse.loading || addingCustomer;

  const updateCustomer = async (formValues:Record<string, string>) => {
    try {
      /**
       * To add new customer we are going through following steps.
       * 1. Create user with firebase.
       * 2. Create user with falkon db
       * 3. Send welcome invite to user.
       *
       * Step 1 and 2 has been taken care by backend.
       * Step 3 is handled by frontend.
      */

      setAddingCustomer(true);
      // Step 1 and 2
      const response = await createCustomer({
        variables: {
          input: {
            contactFirstName: formValues.contactFirstName,
            contactLastName: formValues.contactLastName,
            address: formValues.address,
            email: formValues.email,
            name: formValues.name,
            phone: formValues.number,
            impactLetterCount: Number(formValues.impactLetterCount),
          },
        },
      });

      // 3. Send invite message to user/customer.
      await firebase.sendPasswordResetEmail(
        formValues.email,
      );

      reset();
      onClose();
      toast({
        title: 'User Created Successfully!',
        description: 'Welcome invite has been sent on the email address',
        status: 'success',
      });
      setAddingCustomer(false);

      /* Step 4: Navigate to customer details page of newly created customer
        so that Admin can quickly fill all the required details needed for
        onboarding the customer.
      */
      if (response.data?.createCustomer?.customer?.id) {
        history.push({
          // eslint-disable-next-line max-len
          pathname: `/admin/customers/${response.data.createCustomer.customer.id}`,
        });
      } else {
        toast({
          title: 'Error!',
          description: `Something went wrong, while navigating to
          customer details page for the newly created customer.`,
          status: 'error',
        });
      }
    } catch (error) {
      setAddingCustomer(false);
      if (error) {
        toast({
          title: 'Error!',
          description: getErrorMessage(error),
          status: 'error',
        });
      }
    }
  };

  return (
    <>
      <div>
        <Button
          buttonType={ButtonTypes.Primary}
          leftIcon="add"
          onClick={onOpen}
        >
          Add Customer
        </Button>
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Customer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form
              onSubmit={handleSubmit(updateCustomer)}
              noValidate
              style={{
                width: '100%',
              }}
            >
              <FormControl width="100%" marginBottom={4} isRequired>
                <FormLabel htmlFor="name" aria-required>
                  Customer Name
                </FormLabel>
                <Input
                  name="name"
                  type="text"
                  id="name"
                  aria-describedby="name-helper-text"
                  ref={
                    register({
                      required: true,
                    })
                  }
                  isDisabled={isSubmittingUser}
                />
                {
                  errors.name && (
                    <FormHelperText fontSize="xs" id="name-helper-text">
                      *Customer Name is required
                    </FormHelperText>
                  )
                }
              </FormControl>
              <FormControl marginBottom={4} isRequired>
                <FormLabel htmlFor="impactLetterCount">
                  Number of Impact Letters
                </FormLabel>
                <Select
                  name="impactLetterCount"
                  ref={
                    register({
                      required: true,
                    })
                  }
                  defaultValue={2}
                >
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </Select>
              </FormControl>
              <FormControl width="100%" marginBottom={4} isRequired>
                <FormLabel htmlFor="contactFirstName" aria-required>
                  Contact First Name
                </FormLabel>
                <Input
                  name="contactFirstName"
                  type="text"
                  id="contactFirstName"
                  aria-describedby="contact-first-name-helper-text"
                  ref={
                    register({
                      required: true,
                    })
                  }
                  isDisabled={isSubmittingUser}
                />
                {
                  errors.name && (
                    <FormHelperText
                      fontSize="xs"
                      id="contact-first-name-helper-text"
                    >
                      *Contact First Name is required
                    </FormHelperText>
                  )
                }
              </FormControl>
              <FormControl width="100%" marginBottom={4} isRequired>
                <FormLabel htmlFor="contactLastName" aria-required>
                  Contact Last Name
                </FormLabel>
                <Input
                  name="contactLastName"
                  type="text"
                  id="contactLastName"
                  aria-describedby="contact-last-name-helper-text"
                  ref={
                    register({
                      required: true,
                    })
                  }
                  isDisabled={isSubmittingUser}
                />
                {
                  errors.name && (
                    <FormHelperText
                      fontSize="xs"
                      id="contact-last-name-helper-text"
                    >
                      *Contact Last Name is required
                    </FormHelperText>
                  )
                }
              </FormControl>
              <FormControl marginBottom={4} isRequired>
                <FormLabel htmlFor="email" aria-required>
                  Contact Email
                </FormLabel>
                <Input
                  name="email"
                  type="email"
                  id="email"
                  aria-describedby="contact-email-helper-text"
                  ref={
                    register({
                      required: {
                        value: true,
                        message: CUSTOMER_EMAIL_REQUIRED,
                      },
                      pattern: {
                        value: EMAIL_VALIDATION_PATTERN,
                        message: INVALID_EMAIL,
                      },
                    })
                  }
                  isDisabled={isSubmittingUser}
                />
                {
                  errors.email && (
                    <FormHelperText
                      fontSize="xs"
                      id="contact-email-helper-text"
                    >
                      {errors.email.message}
                    </FormHelperText>
                  )
                }
              </FormControl>
              <FormControl marginBottom={4}>
                <FormLabel htmlFor="number">
                  Phone Number
                </FormLabel>
                <Input
                  name="number"
                  type="number"
                  id="number"
                  aria-describedby="number-helper-text"
                  isDisabled={isSubmittingUser}
                  ref={
                    register({
                      required: true,
                    })
                  }
                />
                {
                  errors.number && (
                    <FormHelperText
                      fontSize="xs"
                      id="number-helper-text"
                    >
                      *Phone number is required
                    </FormHelperText>
                  )
                }
              </FormControl>
              <FormControl marginBottom={4}>
                <FormLabel htmlFor="address">
                  Address
                </FormLabel>
                <Textarea
                  name="address"
                  type="text"
                  id="address"
                  aria-describedby="address-helper-text"
                  isDisabled={isSubmittingUser}
                  ref={register}
                />
              </FormControl>
              <Flex
                justifyContent="center"
              >
                <Button
                  type="submit"
                  buttonType={ButtonTypes.Primary}
                  marginBottom={6}
                  width="100%"
                  maxWidth="250px"
                  isLoading={isSubmittingUser}
                >
                  Create Customer
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export const renderOnboardingStatusData = (
  currentStatus: OnboardingStepStatus | string
  = OnboardingStepStatus.NotStarted,
) => {
  switch (currentStatus) {
  case OnboardingStepStatus.InProgress:
    return <Badge color="neutralActionColor">In Progress</Badge>;
  case OnboardingStepStatus.Completed:
    return <Badge color="advancementActionColor">Completed</Badge>;
  case OnboardingStepStatus.NotStarted:
    return <Badge color="textFieldTextColor">Not started</Badge>;
  default:
    return null;
  }
};

const RenderCustomers: React.FC = () => {
  const [
    sortParameter, setSortParameter,
  ] = useState<ICustomerSortParameter[]>(customerSortParameters);

  const {
    data: customerData,
    loading: customersLoading,
    error: customsersError,
    fetchMore: customersFetchMore,
  } = useFetchCustomersWithMinimumDetailsQuery({
    variables: {
      first: 15,
      sort: [sortParameter.find((sortParam) => sortParam.selected)?.value!],
    },
    /*
     *  fetchPolicy: 'network-only' solves GF-591 and always makes an API call
     *  with 'useFetchCustomersWithMinimumDetailsQuery' when we navigate to
     *  /admin/customers path
     */
    fetchPolicy: 'network-only',
  });

  const history = useHistory();
  const [currentIdPointer, setCurrentIdPointer] = useState('');
  const [
    letterResetLog,
    { loading: letterResetLogLoading },
  ] = useCreateLetterResetLogMutation();
  const toast = useToast();

  const handleSelectSortParameter = (param: ICustomerSortParameter) => {
    setSortParameter((prevSortParameter) => prevSortParameter
      .map((sortParam) => {
        if (sortParam.name === param.name) {
          return { ...sortParam, ...{ selected: true } };
        }
        return { ...sortParam, ...{ selected: false } };
      }));
  };

  const renderSortParameterList = () => (
    <Menu>
      <MenuButton width="100%">
        <Flex alignItems="center" justifyContent="center">
          <Flex alignItems="center" mr={5}>
            <Text type={TextTypes.secondaryHeader} mr={2}>
              Sort By
            </Text>
            <ChakraText fontSize={12.5} fontStyle="italic">
              {sortParameter.find((sortParam) => sortParam.selected)?.name!}
            </ChakraText>
          </Flex>
          <IconifyIcon
            icon={chevronSort}
            width="25px"
            height="25px"
            color="#FF7000"
          />
        </Flex>
      </MenuButton>
      <MenuList placement="bottom" width="15vw">
        <MenuGroup
          title="SORT BY"
        >
          <MenuDivider />
          {
            sortParameter.map((param) => (
              <MenuItem
                width="100%"
                key={`${param.name}${param.value}`}
                onClick={() => handleSelectSortParameter(param)}
                display="flex"
                justifyContent="space-between"
              >
                <ChakraText>{param.name}</ChakraText>
                {
                  param.selected
                && (
                  <Icon
                    name="check"
                    color="activePrimaryColor"
                  />
                )
                }
              </MenuItem>
            ))
          }
        </MenuGroup>
      </MenuList>
    </Menu>
  );

  const resetLetterLog = async (
    customerId: string,
    customerName: string,
  ) => {
    try {
      await letterResetLog({
        variables: {
          input: {
            customerId,
          },
        },
      });
      toast({
        title: `Letters reset for${customerName}`,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error!',
        description: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  const handleContainerOnBottom = useCallback(() => {
    if (customerData?.customerList?.pageInfo?.hasNextPage) {
      customersFetchMore({
        variables: {
          after: customerData?.customerList?.pageInfo.endCursor,
        },
        // TODO: Replace 'updateQuery' with field-policy in apolloClient.ts file
        updateQuery: (prev: any, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult?.customerList?.edges;
          const pageInfo = fetchMoreResult?.customerList?.pageInfo;
          return newEdges?.length
            ? {
              __typename: prev.customerList?.__typename,
              customerList: {
                __typename: prev.customerList?.__typename,
                edges: [
                  ...prev?.customerList?.edges,
                  ...newEdges,
                ],
                pageInfo,
              },
            }
            : prev;
        },
      });
    }
  }, [customerData, customersFetchMore]);

  const containerRef = useBottomScrollListener(handleContainerOnBottom);

  const handleCustomerClick = useCallback(
    (customerNode, isEdit: boolean) => {
      if (!customerNode) return;
      history.push({
        pathname: `/admin/customers/${customerNode.id}`,
        state: {
          name: customerNode.name,
          email: customerNode.email,
          phone: customerNode.phone,
          onBoardingStatus: customerNode.customerOnboardingStep?.status,
          ...isEdit && { isEdit: true },
        },
      });
    }, [history],
  );

  if (customsersError /* || !customerData */) {
    return <div>Something went wrong, please try again!</div>;
  }

  if (customersLoading) {
    return <Loader type={LoaderTypes.FullViewModal} large />;
  }

  if (!customerData?.customerList?.edges.length) {
    return (
      <div>
        <Text type={TextTypes.body}>
          Seems like we don&apos;t have customers added yet.
        </Text>
        <Text type={TextTypes.body}>
          Please consider using
          &nbsp;
          <b>Add Customer</b>
          &nbsp;
          feature.
        </Text>
      </div>
    );
  }

  return (
    <Box
      height="100%"
      // flexGrow={1}
      maxHeight="70vh"
      paddingBottom={4}
      // overflow="auto"
    >
      <StyledUserTable>
        <Box as="thead">
          <Box as="tr">
            <Box width="40%" as="th">
              <Text type={TextTypes.secondaryHeader}>
                Name & Email
              </Text>
            </Box>
            <Box width="30%" as="th">
              <Text type={TextTypes.secondaryHeader}>
                Onboarding Status
              </Text>
            </Box>
            <Box
              as="th"
              width="30%"
              alignItems="center"
              justifyContent="flex-end"
            >
              {
                renderSortParameterList()
              }
            </Box>
          </Box>
        </Box>
        <Box
          overflowY="auto"
          as="tbody"
          ref={containerRef}
        >
          {
            customerData?.customerList.edges.map(
              (customerEdge) => customerEdge && customerEdge.node && (
                <Box
                  as="tr"
                  // width="100%"
                  key={customerEdge.node.id}
                  onMouseEnter={() => setCurrentIdPointer(
                    customerEdge.node?.id!,
                  )}
                  onMouseLeave={() => setCurrentIdPointer('')}
                >
                  <Box
                    as="td"
                    width="40%"
                  >
                    <Flex alignItems="center">
                      <IconifyIcon
                        icon={userAvatarFilledAlt}
                        style={{ marginRight: '16px' }}
                        width={32}
                        height={32}
                      />
                      <Box>
                        <Text type={TextTypes.body}>
                          {customerEdge.node.name}
                        </Text>
                        <Text type={TextTypes.body}>
                          {customerEdge.node.email}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                  <Box
                    as="td"
                    width="30%"
                    style={{
                      // paddingLeft: '30px',
                      // marginRight: '0px',
                      // width: '275px',
                    }}
                  >
                    <Box>
                      {
                        renderOnboardingStatusData(
                        customerEdge.node.customerOnboardingStep?.status
                        || '',
                        )
                      }
                    </Box>
                  </Box>
                  <Box
                    as="td"
                    width="30%"
                    alignItems="center"
                    justifyContent="space-between"
                    // style={{ textAlign: 'center' }}
                  >
                    <Flex alignItems="center">
                      {(currentIdPointer === customerEdge.node?.id)
                        ? (
                          <Button
                            padding="1.1rem"
                            height={0}
                            width="30%"
                            mr={10}
                            isDisabled={letterResetLogLoading}
                            isLoading={letterResetLogLoading}
                            buttonType={ButtonTypes.Primary}
                            onClick={() => resetLetterLog((
                              customerEdge.node?.id!),
                              customerEdge.node?.name!)}
                          >
                            Reset Letters
                          </Button>
                        ) : <Box width="30%" mr={10} />}
                      <Box
                        onClick={
                          (event: any) => {
                            event.stopPropagation();
                            handleCustomerClick(
                              customerEdge.node,
                              false,
                            );
                          }
                        }
                      >
                        Edit
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              ),
            )
          }

        </Box>
      </StyledUserTable>
    </Box>
  );
};

export default memo(Customers);
