import React, { memo, useState, useCallback, useEffect } from 'react';
import { Box, Flex, Divider, Icon, Text } from '@chakra-ui/core';
import { useHistory } from 'react-router-dom';
import { Button, ButtonTypes } from '..';
import { IStep } from '../../pages/OnBoarding/interfaces';
import { DOCUMENTS_DOWNLOAD_ROUTE } from '../../variables/contants';

interface IStepper {
  steps: IStep[];
  enablePreviousButton?: boolean;
  enableStepNumbers?: boolean;
  enableStepSwitching?: boolean;
  activeStep?: number;
  // Prop to force UI of completed all steps scenario.
  forceCompletedSteps?: boolean;
  onFinish?: () => void;
  handleSelectStep?: (
    selectedStep: number,
    browserNavigation?: boolean
  ) => void;
}

const Stepper: React.FC<IStepper> = ({
  steps,
  enablePreviousButton,
  enableStepNumbers,
  enableStepSwitching,
  activeStep: activeStepProp = 0,
  onFinish,
  handleSelectStep,
}: IStepper) => {
  const [activeStep, setActiveStep] = useState<number>(
    activeStepProp > 0 ? activeStepProp - 1 : activeStepProp,
  );

  const isLastStep = activeStep === steps.length - 1;
  const activeComponent = steps[activeStep].component;
  const history = useHistory();
  // Update component's state if we are using controlled version of component.
  useEffect(() => {
    if (activeStepProp !== activeStep) {
      setActiveStep(activeStepProp);
    }
  }, [activeStepProp, activeStep]);

  const stepChangeHandler = useCallback((newStep: number) => {
    if (handleSelectStep) {
      handleSelectStep(newStep, false);
    } else {
      setActiveStep(newStep);
    }
  }, [handleSelectStep]);

  /**
   * Following handler methods are useful if we
   * are not using controlled version of component.
  */
  const nextStepHandler = useCallback(() => {
    setActiveStep((prevState) => {
      if (prevState < steps.length - 1) {
        const nextStepNumber = prevState + 1;
        return nextStepNumber;
      }
      return prevState;
    });
  }, [steps]);

  const previousStepHandler = useCallback(() => {
    setActiveStep((prevState) => {
      if (prevState > 0) {
        const prevStepNumber = prevState - 1;
        return prevStepNumber;
      }
      return prevState;
    });
  }, []);

  const onFinishHandler = useCallback(() => {
    if (onFinish) {
      onFinish();
    }
  }, [onFinish]);

  return (
    <>
      <Flex
        width="100%"
        justify="space-between"
        alignItems="center"
      >
        {
          steps.map((currentStep: IStep, currentStepIndex: number) => {
            // const isCompletedStep = stepperstatus[currentStepIndex];
            const textcolor = (currentStepIndex === activeStep
              ? 'advancementActionColor' : 'black');
            return (
              <Box
                // eslint-disable-next-line react/jsx-props-no-spreading
                {
                // eslint-disable-next-line indent
                ...enableStepSwitching
                && {
                  // Removing handler when clicking stepper step of current path
                  onClick: () => (currentStep.path !== history.location
                    .pathname)
                    && (history.location.pathname !== DOCUMENTS_DOWNLOAD_ROUTE)
                    && stepChangeHandler(currentStepIndex),
                  cursor: 'pointer',
                }

                }
                textAlign="center"
                flexGrow={1}
                display="contents"
                key={currentStep.title}

              >
                <Flex
                  width={6}
                  height={6}
                  borderRadius="50%"
                  borderStyle="solid"
                  borderWidth="2px"
                  justifyContent="center"
                  borderColor={
                    (currentStepIndex === activeStep || currentStep.isCompleted)
                      ? 'advancementActionColor' : 'progressBarDefaultColor'
                  }
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(currentStep.isCompleted)
                  && { backgroundColor: 'advancementActionColor' }}
                  position="relative"
                >
                  {
                    enableStepNumbers
                      ? currentStepIndex + 1
                      : currentStep.isCompleted
                      && <Icon name="tick" height="100%" />
                  }
                  <Text
                    position="absolute"
                    top={6}
                    color={
                      history.location.pathname === DOCUMENTS_DOWNLOAD_ROUTE
                        ? 'black' : textcolor
                    }

                  >
                    {currentStep.title}
                  </Text>
                </Flex>
                {!(currentStepIndex === (steps.length - 1))
                  && (
                    <Divider
                      flexGrow={1}
                      margin="auto"
                      borderColor="progressBarDefaultColor"
                      borderWidth={2}
                    />
                  )}
              </Box>
            );
          })
        }
      </Flex>
      {
        activeComponent && (
          <>
            <Flex alignItems="center" justifyContent="center" padding={8}>
              {
                instanceOfReactNode(activeComponent)
                  ? activeComponent
                  : activeComponent(
                    previousStepHandler, nextStepHandler, isLastStep,
                  )
              }
            </Flex>
            {
              instanceOfReactNode(activeComponent) && (
                <>
                  {
                    enablePreviousButton && (
                      <Button onClick={previousStepHandler}>Previous</Button>
                    )
                  }
                  <Button
                    onClick={isLastStep ? onFinishHandler : nextStepHandler}
                    buttonType={ButtonTypes.Primary}
                  >
                    {isLastStep ? 'Submit' : 'Next'}
                  </Button>
                </>
              )
            }
          </>
        )
      }
    </>
  );
};

const instanceOfReactNode = (element: any):
  element is JSX.Element => React.isValidElement(element);

export default memo(Stepper);
