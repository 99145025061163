import { Box, Flex } from '@chakra-ui/core';
import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { Text, TextTypes } from '../../../../components';
import {
  DefaultTemplateNode,
  useDefaultTemplateListQuery,
} from '../../../../graphql';

const StyledUserTable = styled.table`
  width: 100%;
  box-shadow: 3px 3px 5px 6px #ccc;

  thead {
    background-color: #FAFBFF;
    text-align: left;
  }

  tbody tr {
    background-color: white;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }

  th, td {
    padding: 16px;
  }

  tr {
    border-bottom: 1px solid #BDBDBD;
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  // @TODO - Need to remove hardcoded 475px
  tbody {
    display: block;
    max-height: 475px;
    overflow-y: scroll;
  }
`;

interface Props {
  onRowSelected: (defaultTemplate: DefaultTemplateNode) => void;
}

const LetterList = ({ onRowSelected }: Props) => {
  const {
    data: templateData,
    // loading: customersLoading,
    error: templateError,
    fetchMore: templateFetchMore,
    loading,
  } = useDefaultTemplateListQuery({
    variables: {
      first: 10,
    },
    // fetchPolicy: 'cache-and-network',
  });

  const handleContainerOnBottom = useCallback(() => {
    if (templateData?.defaultTemplateList?.pageInfo?.hasNextPage) {
      templateFetchMore({
        variables: {
          after: templateData?.defaultTemplateList?.pageInfo.endCursor,
        },
        // TODO: Replace 'updateQuery' with field-policy in apolloClient.ts file
        updateQuery: (prev: any, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult?.defaultTemplateList?.edges;
          const pageInfo = fetchMoreResult?.defaultTemplateList?.pageInfo;
          return newEdges?.length
            ? {
              __typename: prev.defaultTemplateList?.__typename,
              defaultTemplateList: {
                __typename: prev.defaultTemplateList?.__typename,
                edges: [
                  ...prev?.defaultTemplateList?.edges,
                  ...newEdges,
                ],
                pageInfo,
              },
            }
            : prev;
        },
      });
    }
  }, [templateData, templateFetchMore]);

  const containerRef = useBottomScrollListener(handleContainerOnBottom);

  if (templateError) {
    return <div>Something went wrong, please try again!</div>;
  }

  if (!templateData && !loading) {
    return <div>No Data</div>;
  }

  return (
    <Box
      flexGrow={1}
    >
      <Box
        height="100%"
        flexGrow={1}
        maxHeight="100%"
        paddingBottom={4}
        overflow="auto"
      >
        <StyledUserTable>
          <thead>
            <tr>
              <th>
                <Text type={TextTypes.secondaryHeader}>
                  Stage
                </Text>
              </th>
              <th>
                <Text type={TextTypes.secondaryHeader}>
                  Template
                </Text>
              </th>
              <th>
                <Text type={TextTypes.secondaryHeader}>
                  Letter Format
                </Text>
              </th>
            </tr>
          </thead>
          <Box
            overflowY="auto"
            as="tbody"
            ref={containerRef}
          >
            {
              templateData?.defaultTemplateList?.edges?.map(
                (templateEdge) => templateEdge && templateEdge.node && (
                  <tr
                    key={templateEdge.node.id}
                    // @ts-ignore
                    onClick={() => onRowSelected(templateEdge?.node)}
                  >
                    <Box as="td">
                      <Text>
                        {templateEdge.node.stage}
                      </Text>
                    </Box>
                    <Box as="td">
                      <Flex
                        alignItems="center"
                      >
                        <Flex
                          direction="column"
                        >
                          <Text type={TextTypes.body}>
                            {templateEdge.node.template}
                          </Text>
                        </Flex>
                      </Flex>
                    </Box>
                    <Box as="td">
                      <Text type={TextTypes.body}>
                        {templateEdge.node.letterFormat}
                      </Text>
                    </Box>
                  </tr>
                ),
              )
            }
          </Box>
        </StyledUserTable>
      </Box>
    </Box>
  );
};

export default LetterList;
