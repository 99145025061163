import { gql } from '@apollo/client';

const query = gql`
  query Me {
    me {
    customerUser {
      customer {
        brands {
          edges {
            node {
              id
              name
            }
          }
        }
        customerOnboardingStep {
          id
          status
          onboardingStep {
            uiRouteId
          }
        }
        id
        name
      }
    }
    userRoles {
      edges {
        node {
          role {
            id
            name
          }
          user {
            id
            email
            firstName
            lastName
          }
        }
      }
    }
  }
  }
`;

export default query;
