export default {
  body: '#F2F2F2',

  // Theme Background or Content colors
  // Available theme colors for actions
  /**
  * Using theme color directly as FF7A00 is primary color and w.r.t mock
  * we don't need primary.500 combination of default chakra theme.
  */
  primaryRadioCheckboxColor: {
    500: '#FF7A00',
  },
  activePrimaryColor: '#FF7A00',
  primaryButtonHoverColor: '#E06B00',
  primaryLinkButtonHoverColor: 'rgba(255, 122, 0, 0.06)',
  activeTertiaryColor: '#F2F2F2',
  // to match mock value '#FF7A00 (30% Opacity)'
  disabledPrimaryColor: 'rgba(255, 122, 0, 0.3)',
  disabledTertiaryColor: 'rgba(242, 242, 242, 0.3)',

  advancementActionColor: '#6FCF97',
  neutralActionColor: '#2F80ED',

  // Available theme colors for file related actions.
  fileErrorBackground: 'rgba(255, 102, 96, 0.3)',
  fileUploadProgressBackground: 'rgba(255, 122, 0, 0.3)',

  warning: '#FF6660',

  // Available theme colors for text
  headerText: '#333333',
  bodyText: '#828282',
  disabledText: '#DADADA',
  formLabel: '#4F4F4F',
  textFieldTextColor: '#333333',
  primaryActionText: '#FFFFFF',

  // Available theme colors for containers.
  // (Color can be used as a border color or background colors of containers)
  defaultBorderColor: '#BDBDBD',
  lightBorderColor: '#E3E8EF',
  defaultBackgroundColor: '#FFFFFF',

  /**
   * Color can be used for default state of progress bar
   * and for progress-bar titles
  */
  progressBarDefaultColor: '#BDBDBD',
  switches: '#828282',
  darkContainerBorderColor: '#333333',

};
