/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import { Text as TextComponent, BoxProps } from '@chakra-ui/core';

export enum TextTypes {
  header,
  secondaryHeader,
  tertiaryHeader,
  body,
  primaryLink,
  link,
  disabled,
  activeTabTitle,
  disabledTabTitle,
  warning,
}

interface IText extends BoxProps {
  type?: TextTypes;
  children?: React.ReactNode;
}

const Text: React.FC<IText> = ({
  type = TextTypes.body,
  children,
  ...restTextProps
}: IText) => (
  <TextComponent
    fontStyle="normal"
    {
      ...(type === TextTypes.header) && {
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        color: 'headerText',
      }
    }
    {
      ...(type === TextTypes.secondaryHeader) && {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        color: 'headerText',
      }
    }
    {
      ...(type === TextTypes.body) && {

        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        color: 'bodyText',
      }
    }
    {
      ...(type === TextTypes.warning) && {

        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '19px',
        color: 'warning',
      }
    }
    {
      ...(type === TextTypes.primaryLink) && {

        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '0.01em',
        color: 'activePrimaryColor',
      }
    }
    {
      ...(type === TextTypes.link) && {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '17px',
        letterSpacing: '0.01em',
        color: 'headerText',
      }
    }
    {
      ...(type === TextTypes.tertiaryHeader) && {
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '0.01em',
        color: 'headerText',
      }
    }
    {
      ...(type === TextTypes.activeTabTitle) && {
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 500,
        letterSpacing: '0.01em',
        color: 'headerText',
      }
    }
    {
      ...(type === TextTypes.disabledTabTitle) && {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.01em',
        color: 'disabledText',
      }
    }
    {
      ...(type === TextTypes.disabled) && {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.01em',
        color: 'disabledText',
      }
    }
    {...restTextProps}
  >
    {children}
  </TextComponent>
);

export default memo(Text);
