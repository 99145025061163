import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Button as ChakraButton,
  Tooltip,
} from '@chakra-ui/core';
import React, { memo, useCallback, useState } from 'react';
import {
  Button,
  ButtonTypes,
  Text,
  TextTypes,
} from '../../../../components';
import DragDrop from '../../../../components/drag-drop';
import {
  OnboardingStepStatus,
  useUploadTermsAndConditionsMutation,
} from '../../../../graphql';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { ITermsAndCondition } from '../../interfaces';

interface ISetTermsAndConditions {
  customerId: string,
  isLoading: boolean,
  termsAndCondtion: ITermsAndCondition | null,
  onboardingStepStatus: OnboardingStepStatus,
  refetch: () => void,
}

const SetTermsAndConditions = ({
  customerId,
  termsAndCondtion,
  onboardingStepStatus,
  refetch,
}: ISetTermsAndConditions) => {
  const toast = useToast();

  const [
    uploadTermsAndCondition,
    {
      loading: uploadTermsAndConditionsLoading,
    },
  ] = useUploadTermsAndConditionsMutation();

  const [
    selectedTermsCondition,
    setSelectedTermsCondition,
  ] = useState({
    title: '', content: '',
  });

  const [uploadModal, setUploadModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [filePayload, setFilePayload] = useState<string>();
  const [fileName, setFileName] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  /* function to extract base64 string of the input file. */
  const getBase64 = useCallback(
    (
      file: File,
    ): Promise<string> => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // return reader.result;
        const encodedString = reader.result?.toString().split(',')[1];
        resolve(encodedString);
      };
      reader.onerror = () => {
        toast({
          title: 'Error!',
          description: reader.error?.message,
          status: 'error',
        });
        reject(new Error('Error while reading HTML file'));
      };
    }),
    [toast],
  );

  const handleFileDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        try {
          const result = await getBase64(file);
          const { name } = file;
          setFileName(name);
          setFilePayload(result);
          setIsSubmitDisabled(false);
        } catch (error) {
          toast({
            title: 'Error!',
            description: getErrorMessage(error),
            status: 'error',
          });
          setIsSubmitDisabled(true);
          setFilePayload('');
        }
      } else {
        setIsSubmitDisabled(true);
      }
    },
    [getBase64, toast],
  );

  const downloadTermsCondition = useCallback((
    content: string,
  ) => {
    const blob = new Blob([content], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    return url;
  }, []);

  const handleSetPreviewTermsCondition = useCallback((
    title: string,
    content: string,
  ) => {
    setPreview(true);
    setSelectedTermsCondition({
      title,
      content,
    });
  }, []);

  const handleUploadTermsConditions = useCallback(
    async () => {
      if (filePayload) {
        try {
          const uploadResponse = await uploadTermsAndCondition({
            variables: {
              input: {
                customerId,
                encodedFile: filePayload,
                name: fileName,
              },
            },
          });
          if (uploadResponse.data?.uploadTermsAndConditions?.errorMessage) {
            toast({
              title: 'Error!',
              description: uploadResponse.data
                ?.uploadTermsAndConditions?.errorMessage,
              status: 'error',
            });
          }
          if (uploadResponse.data?.uploadTermsAndConditions?.success) {
            toast({
              title: 'Upload Successful!',
              description: 'Terms & Conditions uploaded successfully.',
              status: 'success',
            });
            setUploadModal(false);
            refetch();
          }
        } catch (error) {
          toast({
            title: 'Error!',
            description: getErrorMessage(error),
            status: 'error',
          });
        }
      } else {
        toast({
          title: 'Error!',
          description: 'Base64 encoded variant of submitted file not found.',
          status: 'error',
        });
      }
    },
    [
      filePayload,
      fileName,
      refetch,
      customerId,
      uploadTermsAndCondition,
      toast,
    ],
  );

  const renderTermsAndCondition = (
    termsAndCondition: ITermsAndCondition | null,
  ) => (
    <Flex
      width="100%"
      alignItems="center"
    >
      <Tooltip
        aria-label={termsAndCondition?.name
          ?? 'No T&C set for this customer yet.'}
        label={termsAndCondition?.name
          ?? 'No T&C has been set for this customer yet.'}
        hasArrow
        placement="top"
      >
        <ChakraButton
          width="70%"
          as="a"
          /* TODO: Upgrade chakra to v1 for updated typescript
          definitions for Button so that we don't have
          to use ts-ignore construct
          */
          // @ts-ignore
          href={
            downloadTermsCondition(termsAndCondition?.content ?? '')
          }
          download={termsAndCondition?.name ?? ''}
          isDisabled={!termsAndCondition?.name}
          mr={5}
          color="activePrimaryColor"
          borderColor="activePrimaryColor"
          border="2px solid"
          boxSizing="border-box"
          borderRadius={4}
          backgroundColor="transparent"
          leftIcon={
            () => (
              <Icon
                size="5"
                name="csvDownloadOrange"
                marginRight={1}
              />
            )
          }
        >
          <Text
            type={TextTypes.primaryLink}
            isTruncated
            fontStyle={termsAndCondition?.name ? 'normal' : 'italic'}
          >
            {termsAndCondition?.name ?? 'No T&C document found'}
          </Text>
        </ChakraButton>
      </Tooltip>
      <Button
        isDisabled={onboardingStepStatus === OnboardingStepStatus.Completed}
        mr={5}
        buttonType={ButtonTypes.Primary}
        onClick={() => setUploadModal(true)}
      >
        Upload
      </Button>
      <Button
        buttonType={ButtonTypes.Primary}
        isDisabled={!termsAndCondition?.name}
        onClick={termsAndCondition ? () => handleSetPreviewTermsCondition(
          termsAndCondition.name, termsAndCondition.content,
        ) : undefined}
      >
        Preview
      </Button>
    </Flex>
  );

  const renderUploadTermsCondition = useCallback(
    () => (
      <Modal
        isOpen={uploadModal}
        onClose={() => setUploadModal(false)}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          overflow="auto"
          maxHeight="50vh"
          maxWidth="50vw"
        >
          <ModalHeader>Upload Terms & Condition</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DragDrop
              styles={{
                marginBottom: 20,
              }}
              fileAttributeStyles={{
                marginTop: 0,
              }}
              fileFormat=".html"
              onFileDrop={handleFileDrop}
              multiple={false}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              buttonType={ButtonTypes.Link}
              onClick={() => setUploadModal(false)}
              width="100%"
              maxWidth={400}
            >
              Cancel
            </Button>
            <Button
              isLoading={uploadTermsAndConditionsLoading}
              isDisabled={isSubmitDisabled}
              buttonType={ButtonTypes.Primary}
              onClick={handleUploadTermsConditions}
              width="100%"
              maxWidth={400}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    ),
    [
      uploadModal,
      handleFileDrop,
      handleUploadTermsConditions,
      isSubmitDisabled,
      uploadTermsAndConditionsLoading,
    ],
  );

  const renderPreviewTermsCondition = useCallback(
    () => (
      <Modal
        isOpen={preview}
        onClose={() => setPreview(false)}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          overflow="auto"
          maxHeight="100vh"
          maxWidth="50vw"
        >
          <ModalHeader>{selectedTermsCondition.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <Box dangerouslySetInnerHTML={{
                __html: selectedTermsCondition.content,
              }}
              />
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    ),
    [preview, selectedTermsCondition],
  );

  return (
    <Box width="100%">
      <Flex
        // justifyContent="space-between"
        alignItems="center"
        marginTop={2}
        marginBottom={6}
      >
        <Text
          type={TextTypes.header}
        >
          Terms and Conditions
        </Text>
        {
          onboardingStepStatus === OnboardingStepStatus.Completed
        && (
          <Text
            marginLeft={3}
            marginTop={1}
            fontSize="xs"
            fontStyle="italic"
          >
            (Customer has already completed the Onboarding process.)
          </Text>
        )
        }
      </Flex>
      <Box
        // width="100%"
        flexGrow={1}
      >
        {
          renderTermsAndCondition(termsAndCondtion)
        }
      </Box>
      {renderPreviewTermsCondition()}
      {renderUploadTermsCondition()}
    </Box>
  );
};

export default memo(SetTermsAndConditions);
