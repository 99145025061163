/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Route,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
// import { getJWTToken } from '../utils/auth';
import { getUserDetails } from '../utils/auth';

interface Props {
  component: React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any> | JSX.Element;
  path: string;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => (
  <Route
    {...rest}
    render={(props) => (getUserDetails() ? (
      instanceOfReactNode(Component)
        ? Component
        : <Component {...rest} {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/auth',
          state: { from: props.location },
        }}
      />
    ))}
  />
);

const instanceOfReactNode = (element: any) :
element is JSX.Element => React.isValidElement(element);

export default PrivateRoute;
