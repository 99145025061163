/* eslint-disable import/prefer-default-export */
import { makeVar, gql } from '@apollo/client';

/*
   NOTE: Make use of queries if more reactive variables are needed in future.
*/
export const GET_SELECTED_CUSTOMER_NAME = gql`
  query GetSelectedCustomerName {
    selectedCustomerName @client
  }
`;

export const selectedCustomerName = makeVar('');

export const GET_SELECTED_CUSTOMER_ID = gql`
  query GetSelectedCustomerId {
    selectedCustomerId @client
  }
`;

export const selectedCustomerId = makeVar('');

export const GET_NAVIGATION_BLOCK_VAR = gql`
  query GetNavigationBlockVar {
    navigationBlockedPayload @client
  }
`;

export const navigationBlockedPayload = makeVar({
  isNavigationBlocked: false,
  navigationBlockMessage: '',
});
