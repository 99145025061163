import React, { useState, memo } from 'react';
import {
  Box,
  Icon,
  useToast,
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import {
  SplitWithImage, Button,
  ButtonTypes, Text, TextTypes,
} from '../../../components';
import { ICommonStepsPropInterface } from '../interfaces';
import DragDrop from '../../../components/drag-drop';
import { DOWNLOAD_DOCUMENT_URL } from '../../../variables/contants';
import {
  useUploadProductFileMutation,
  useLoadBrandSellersMutation,
} from '../../../graphql';
import { getBrandID } from '../../../utils/files';
import getErrorMessage from '../../../utils/getErrorMessage';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  grid-column-start: 1;
  grid-row-start: 2;
  padding: 1rem;
  max-width: 500px;
`;

const CSVUpload: React.FC<ICommonStepsPropInterface> = (
  {
    advancingStepLoader,
    handleNextStep,
    refetchBrandStatus,
  }: ICommonStepsPropInterface,
) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [filePayload, setFilePayload] = useState<string>();
  const toast = useToast();

  const [
    uploadProductFile,
    uploadProductFileResponse,
  ] = useUploadProductFileMutation();
  const [
    loadbrandsellers,
    {
      loading: loadBrandSellersLoading,
      // error: loadBrandSellersError,
    }] = useLoadBrandSellersMutation();

  /* TODO: Use this function to extract base64 string of the CSV. */
  const getBase64 = (
    file: File,
  ): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // return reader.result;
      const encodedString = reader.result?.toString().split(',')[1];
      resolve(encodedString);
    };
    reader.onerror = () => {
      toast({
        title: 'Error!',
        description: reader.error?.message,
        status: 'error',
      });
      reject(new Error('Error while reading CSV file'));
    };
  });

  const handleFileDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      // const [fileUploaded] = acceptedFiles;
      const file = acceptedFiles[0];
      try {
        const result = await getBase64(file);
        setFilePayload(result);
        setIsSubmitDisabled(false);
      } catch (error) {
        toast({
          title: 'Error!',
          description: getErrorMessage(error),
          status: 'error',
        });
        setIsSubmitDisabled(true);
        setFilePayload('');
      }
    }
  };

  const loadBrandSellers = async () => {
    try {
      await loadbrandsellers({
        variables: {
          input: {
            brandId: getBrandID(),
          },
        },
      });
      toast({
        title: 'Sucess',
        description: `Scraping of seller information
        for the brand started successfully!`,
        status: 'success',
      });
      refetchBrandStatus();
      handleNextStep();
      /*
         TODO:
            Handle loadBrandSeller mutation call here.
            handleNextStep should be called in success of
            loadbrandSellers mutation
       */
    } catch (error) {
      toast({
        title: 'Error!',
        description: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  const handleSubmit = async () => {
    if (filePayload) {
      try {
        const uploadResponse = await uploadProductFile({
          variables: {
            input: {
              brandId: getBrandID(),
              encodedFile: filePayload,
            },
          },
        });
        if (uploadResponse.data?.uploadProductFile?.errorMessage) {
          toast({
            title: 'Error!',
            description: uploadResponse.data?.uploadProductFile?.errorMessage,
            status: 'error',
          });
        }
        if (uploadResponse.data?.uploadProductFile?.success) {
          toast({
            title: 'Upload Successful!',
            description: 'Product list csv uploaded successfully.',
            status: 'success',
          });
          await loadBrandSellers();
        }
      } catch (error) {
        toast({
          title: 'Error!',
          description: getErrorMessage(error),
          status: 'error',
        });
      }
    } else {
      toast({
        title: 'Error!',
        description: 'Base64 encoded variant of submitted file not found.',
        status: 'error',
      });
      // TODO: Handle navigation to next page here
    }
  };

  return (
    <SplitWithImage withGrid={{
      enable: true,
      imageWrapperStyles: {
        gridRowStart: 1,
        gridRowEnd: 3,
        width: '100%',
      },
    }}
    >
      <StyledWrapper>
        <Box
          maxHeight="100%"
          width="100%"
        >
          <Text
            marginBottom={4}
            type={TextTypes.header}
          >
            Upload a CSV file of your product
            information and we’ll do the rest!
          </Text>
          <Text
            marginBottom={5}
          >
            Here is where we will collect information regarding your product.
            In order for our technology to successfully start finding
            illegitimate sellers we will need the following, ASIN list,
            product details (title, Model, UPC), and authorized sellers.
          </Text>
          <Button
            paddingY={3}
            paddingLeft={0}
            paddingRight={3}
            marginBottom={5}
            backgroundColor="red"
            onClick={() => {
              window.open(DOWNLOAD_DOCUMENT_URL, '_blank');
            }}
            leftIcon={
              () => <Icon size="5" name="csvDownloadOrange" marginRight={1} />
            }
            buttonType={ButtonTypes.PrimaryLink}
          >
            Download CSV Template
          </Button>
          <DragDrop
            fileFormat=".csv"
            onFileDrop={handleFileDrop}
            multiple={false}
          />
        </Box>
        <Box width="100%">
          <Button
            isLoading={
              advancingStepLoader
              || uploadProductFileResponse.loading
              || loadBrandSellersLoading
            }
            isDisabled={
              isSubmitDisabled
              || advancingStepLoader
              || uploadProductFileResponse.loading
              || loadBrandSellersLoading
            }
            onClick={handleSubmit}
            width="100%"
            buttonType={ButtonTypes.Primary}
          >
            Save & Continue
          </Button>
        </Box>
      </StyledWrapper>
    </SplitWithImage>
  );
};

export default memo(CSVUpload);
