import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: Date;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  health?: Maybe<Scalars['String']>;
  /** The ID of the object */
  customer?: Maybe<CustomerNode>;
  customerList?: Maybe<CustomerNodeConnection>;
  /** The ID of the object */
  brand?: Maybe<BrandNode>;
  brandList?: Maybe<BrandConnection>;
  defaultTemplateList?: Maybe<DefaultTemplateConnection>;
  ipViolationList?: Maybe<IpViolationsNodeConnection>;
  termsAndConditionsList?: Maybe<TermsAndConditionsNodeConnection>;
  sellerList?: Maybe<SellerNodeConnection>;
  /** The ID of the object */
  user?: Maybe<UserNode>;
  userList?: Maybe<UserNodeConnection>;
  brandTasks?: Maybe<BrandTaskConnection>;
  getOnboardingFilesUrls?: Maybe<Array<Maybe<Dictionary>>>;
  me?: Maybe<UserNode>;
  users?: Maybe<Array<Maybe<UserNode>>>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerListArgs = {
  sort?: Maybe<Array<Maybe<CustomerNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryBrandArgs = {
  id: Scalars['ID'];
};


export type QueryBrandListArgs = {
  filters?: Maybe<BrandFilter>;
  sort?: Maybe<Array<Maybe<BrandNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDefaultTemplateListArgs = {
  filters?: Maybe<DefaultTemplateFilter>;
  sort?: Maybe<Array<Maybe<DefaultTemplateNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryIpViolationListArgs = {
  sort?: Maybe<Array<Maybe<IpViolationsNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTermsAndConditionsListArgs = {
  sort?: Maybe<Array<Maybe<TermsAndConditionsNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySellerListArgs = {
  sort?: Maybe<Array<Maybe<SellerNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserListArgs = {
  sort?: Maybe<Array<Maybe<UserNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryBrandTasksArgs = {
  filters?: Maybe<BrandTaskFilter>;
  sort?: Maybe<Array<Maybe<BrandTaskNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetOnboardingFilesUrlsArgs = {
  brandId?: Maybe<Scalars['String']>;
};

/** Customer node */
export type CustomerNode = Node & {
  __typename?: 'CustomerNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  ipViolationId?: Maybe<Scalars['Int']>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  ipViolation?: Maybe<IpViolationsNode>;
  brands?: Maybe<BrandNodeConnection>;
  customerContract?: Maybe<CustomerContractNode>;
  customerOnboardingStep?: Maybe<CustomerOnboardingStep>;
  customerTermsAndCondition?: Maybe<CustomerTermsAndConditionsNode>;
  customerUsers?: Maybe<CustomerUserNodeConnection>;
};


/** Customer node */
export type CustomerNodeBrandsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Customer node */
export type CustomerNodeCustomerUsersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};


/** IP violations node */
export type IpViolationsNode = Node & {
  __typename?: 'IpViolationsNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  content: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  customer?: Maybe<CustomerNode>;
};

export type BrandNodeConnection = {
  __typename?: 'BrandNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandNodeEdge>>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** A Relay edge containing a `BrandNode` and its cursor. */
export type BrandNodeEdge = {
  __typename?: 'BrandNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BrandNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Brands node */
export type BrandNode = Node & {
  __typename?: 'BrandNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  brandString?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['Int']>;
  dataRelativePath?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  customer?: Maybe<CustomerNode>;
  brandLetters?: Maybe<BrandLetterNodeConnection>;
  brandMarketplaces?: Maybe<BrandMarketplaceNodeConnection>;
  brandTasks?: Maybe<BrandTaskNodeConnection>;
  sellerMarketplaces?: Maybe<SellerMarketplaceNodeConnection>;
  approvedImpactLetter?: Maybe<Scalars['Boolean']>;
  addedProducts?: Maybe<Scalars['Boolean']>;
  sellersFound?: Maybe<Scalars['Boolean']>;
  letterheadUploaded?: Maybe<Scalars['Boolean']>;
};


/** Brands node */
export type BrandNodeBrandLettersArgs = {
  filters?: Maybe<BrandLetterFilter>;
  sort?: Maybe<Array<Maybe<BrandLetterNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Brands node */
export type BrandNodeBrandMarketplacesArgs = {
  sort?: Maybe<Array<Maybe<BrandMarketplaceNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Brands node */
export type BrandNodeBrandTasksArgs = {
  filters?: Maybe<BrandTaskFilter>;
  sort?: Maybe<Array<Maybe<BrandTaskNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Brands node */
export type BrandNodeSellerMarketplacesArgs = {
  sort?: Maybe<Array<Maybe<SellerMarketplaceNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandLetterNodeConnection = {
  __typename?: 'BrandLetterNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandLetterNodeEdge>>;
};

/** A Relay edge containing a `BrandLetterNode` and its cursor. */
export type BrandLetterNodeEdge = {
  __typename?: 'BrandLetterNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BrandLetterNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Brand letter node */
export type BrandLetterNode = Node & {
  __typename?: 'BrandLetterNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  template: Scalars['String'];
  brandId: Scalars['Int'];
  marketplaceId?: Maybe<Scalars['Int']>;
  stage: Scalars['Int'];
  letterFormat?: Maybe<LetterType>;
  active?: Maybe<Scalars['Boolean']>;
  approvalStatus?: Maybe<LetterApprovalStatus>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  brand?: Maybe<BrandNode>;
  brandLetterRevision?: Maybe<BrandLetterRevisionNodeConnection>;
};


/** Brand letter node */
export type BrandLetterNodeBrandLetterRevisionArgs = {
  sort?: Maybe<Array<Maybe<BrandLetterRevisionNodeSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum LetterType {
  Image = 'IMAGE',
  Textbox = 'TEXTBOX'
}

/** An enumeration. */
export enum LetterApprovalStatus {
  Approved = 'APPROVED',
  ReviewRequested = 'REVIEW_REQUESTED',
  Generated = 'GENERATED',
  RevisionApplied = 'REVISION_APPLIED'
}

export type BrandLetterRevisionNodeConnection = {
  __typename?: 'BrandLetterRevisionNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandLetterRevisionNodeEdge>>;
};

/** A Relay edge containing a `BrandLetterRevisionNode` and its cursor. */
export type BrandLetterRevisionNodeEdge = {
  __typename?: 'BrandLetterRevisionNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BrandLetterRevisionNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Brand letter revision node */
export type BrandLetterRevisionNode = Node & {
  __typename?: 'BrandLetterRevisionNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandLetterId: Scalars['Int'];
  templateRevision: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  brandLetter?: Maybe<BrandLetterNode>;
};

/** An enumeration. */
export enum BrandLetterRevisionNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BrandLetterIdAsc = 'BRAND_LETTER_ID_ASC',
  BrandLetterIdDesc = 'BRAND_LETTER_ID_DESC',
  TemplateRevisionAsc = 'TEMPLATE_REVISION_ASC',
  TemplateRevisionDesc = 'TEMPLATE_REVISION_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

export type BrandLetterFilter = {
  /** Exact match. */
  stage?: Maybe<Scalars['Int']>;
  letterFormat?: Maybe<LetterType>;
  /** Conjunction of filters joined by ``AND``. */
  and?: Maybe<Array<BrandLetterFilter>>;
  /** Conjunction of filters joined by ``OR``. */
  or?: Maybe<Array<BrandLetterFilter>>;
  /** Negation of filters. */
  not?: Maybe<BrandLetterFilter>;
};

/** An enumeration. */
export enum BrandLetterNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TemplateAsc = 'TEMPLATE_ASC',
  TemplateDesc = 'TEMPLATE_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  MarketplaceIdAsc = 'MARKETPLACE_ID_ASC',
  MarketplaceIdDesc = 'MARKETPLACE_ID_DESC',
  StageAsc = 'STAGE_ASC',
  StageDesc = 'STAGE_DESC',
  LetterFormatAsc = 'LETTER_FORMAT_ASC',
  LetterFormatDesc = 'LETTER_FORMAT_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  ApprovalStatusAsc = 'APPROVAL_STATUS_ASC',
  ApprovalStatusDesc = 'APPROVAL_STATUS_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

export type BrandMarketplaceNodeConnection = {
  __typename?: 'BrandMarketplaceNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandMarketplaceNodeEdge>>;
};

/** A Relay edge containing a `BrandMarketplaceNode` and its cursor. */
export type BrandMarketplaceNodeEdge = {
  __typename?: 'BrandMarketplaceNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BrandMarketplaceNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Brand Marketplace node */
export type BrandMarketplaceNode = Node & {
  __typename?: 'BrandMarketplaceNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId: Scalars['Int'];
  marketplaceId: Scalars['Int'];
  monitorMarketplace: Scalars['Boolean'];
  engageSellers: Scalars['Boolean'];
  engageMarketplace: Scalars['Boolean'];
  impactLetterCount: Scalars['Int'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  brand?: Maybe<BrandNode>;
};

/** An enumeration. */
export enum BrandMarketplaceNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  MarketplaceIdAsc = 'MARKETPLACE_ID_ASC',
  MarketplaceIdDesc = 'MARKETPLACE_ID_DESC',
  MonitorMarketplaceAsc = 'MONITOR_MARKETPLACE_ASC',
  MonitorMarketplaceDesc = 'MONITOR_MARKETPLACE_DESC',
  EngageSellersAsc = 'ENGAGE_SELLERS_ASC',
  EngageSellersDesc = 'ENGAGE_SELLERS_DESC',
  EngageMarketplaceAsc = 'ENGAGE_MARKETPLACE_ASC',
  EngageMarketplaceDesc = 'ENGAGE_MARKETPLACE_DESC',
  ImpactLetterCountAsc = 'IMPACT_LETTER_COUNT_ASC',
  ImpactLetterCountDesc = 'IMPACT_LETTER_COUNT_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

export type BrandTaskNodeConnection = {
  __typename?: 'BrandTaskNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandTaskNodeEdge>>;
};

/** A Relay edge containing a `BrandTaskNode` and its cursor. */
export type BrandTaskNodeEdge = {
  __typename?: 'BrandTaskNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BrandTaskNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Brand Task Node */
export type BrandTaskNode = Node & {
  __typename?: 'BrandTaskNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId: Scalars['Int'];
  taskName: TaskName;
  status: Status;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  brand?: Maybe<BrandNode>;
};

/** An enumeration. */
export enum TaskName {
  LoadBrandSellers = 'LOAD_BRAND_SELLERS'
}

/** An enumeration. */
export enum Status {
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Failed = 'FAILED'
}

export type BrandTaskFilter = {
  taskName?: Maybe<TaskName>;
  /** Conjunction of filters joined by ``AND``. */
  and?: Maybe<Array<BrandTaskFilter>>;
  /** Conjunction of filters joined by ``OR``. */
  or?: Maybe<Array<BrandTaskFilter>>;
  /** Negation of filters. */
  not?: Maybe<BrandTaskFilter>;
};

/** An enumeration. */
export enum BrandTaskNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  TaskNameAsc = 'TASK_NAME_ASC',
  TaskNameDesc = 'TASK_NAME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  ErrorsAsc = 'ERRORS_ASC',
  ErrorsDesc = 'ERRORS_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

export type SellerMarketplaceNodeConnection = {
  __typename?: 'SellerMarketplaceNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SellerMarketplaceNodeEdge>>;
};

/** A Relay edge containing a `SellerMarketplaceNode` and its cursor. */
export type SellerMarketplaceNodeEdge = {
  __typename?: 'SellerMarketplaceNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SellerMarketplaceNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Seller Marketplaces node */
export type SellerMarketplaceNode = Node & {
  __typename?: 'SellerMarketplaceNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId: Scalars['Int'];
  sellerId: Scalars['Int'];
  marketplaceId: Scalars['Int'];
  productId?: Maybe<Scalars['Int']>;
  authorized?: Maybe<Scalars['Boolean']>;
  contactSeller?: Maybe<Scalars['Boolean']>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  seller?: Maybe<SellerNode>;
  brand?: Maybe<BrandNode>;
};

/** Seller node */
export type SellerNode = Node & {
  __typename?: 'SellerNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  externalId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  sellerMarketplaces?: Maybe<SellerMarketplaceNodeConnection>;
};


/** Seller node */
export type SellerNodeSellerMarketplacesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum SellerMarketplaceNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  SellerIdAsc = 'SELLER_ID_ASC',
  SellerIdDesc = 'SELLER_ID_DESC',
  MarketplaceIdAsc = 'MARKETPLACE_ID_ASC',
  MarketplaceIdDesc = 'MARKETPLACE_ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  AuthorizedAsc = 'AUTHORIZED_ASC',
  AuthorizedDesc = 'AUTHORIZED_DESC',
  ContactSellerAsc = 'CONTACT_SELLER_ASC',
  ContactSellerDesc = 'CONTACT_SELLER_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

/** Customer_Contract Node */
export type CustomerContractNode = Node & {
  __typename?: 'CustomerContractNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  customerId: Scalars['Int'];
  term: Scalars['Int'];
  setupFee?: Maybe<Scalars['Float']>;
  monthlyFee?: Maybe<Scalars['Float']>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  serviceFee?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerNode>;
};

/** Customer onboarding step node */
export type CustomerOnboardingStep = Node & {
  __typename?: 'CustomerOnboardingStep';
  /** The ID of the object. */
  id: Scalars['ID'];
  customerId: Scalars['Int'];
  onboardingStepId: Scalars['Int'];
  status?: Maybe<OnboardingStepStatus>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  customer?: Maybe<CustomerNode>;
  onboardingStep?: Maybe<OnboardingStep>;
};

/** An enumeration. */
export enum OnboardingStepStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED'
}

/** Onboarding step node. */
export type OnboardingStep = Node & {
  __typename?: 'OnboardingStep';
  /** The ID of the object. */
  id: Scalars['ID'];
  uiRouteId: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  customerOnboardingSteps?: Maybe<CustomerOnboardingStepConnection>;
};


/** Onboarding step node. */
export type OnboardingStepCustomerOnboardingStepsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerOnboardingStepConnection = {
  __typename?: 'CustomerOnboardingStepConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerOnboardingStepEdge>>;
};

/** A Relay edge containing a `CustomerOnboardingStep` and its cursor. */
export type CustomerOnboardingStepEdge = {
  __typename?: 'CustomerOnboardingStepEdge';
  /** The item at the end of the edge */
  node?: Maybe<CustomerOnboardingStep>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** CustomerTermsAndConditions node */
export type CustomerTermsAndConditionsNode = Node & {
  __typename?: 'CustomerTermsAndConditionsNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  customerId: Scalars['Int'];
  termsAndConditionsId?: Maybe<Scalars['Int']>;
  accepted?: Maybe<Scalars['DateTime']>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  customer?: Maybe<CustomerNode>;
  termsAndConditions?: Maybe<TermsAndConditionsNode>;
};

/** TermsAndConditions node  */
export type TermsAndConditionsNode = Node & {
  __typename?: 'TermsAndConditionsNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  content: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  customerTermsAndConditions?: Maybe<CustomerTermsAndConditionsNodeConnection>;
};


/** TermsAndConditions node  */
export type TermsAndConditionsNodeCustomerTermsAndConditionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerTermsAndConditionsNodeConnection = {
  __typename?: 'CustomerTermsAndConditionsNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerTermsAndConditionsNodeEdge>>;
};

/** A Relay edge containing a `CustomerTermsAndConditionsNode` and its cursor. */
export type CustomerTermsAndConditionsNodeEdge = {
  __typename?: 'CustomerTermsAndConditionsNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CustomerTermsAndConditionsNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CustomerUserNodeConnection = {
  __typename?: 'CustomerUserNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerUserNodeEdge>>;
};

/** A Relay edge containing a `CustomerUserNode` and its cursor. */
export type CustomerUserNodeEdge = {
  __typename?: 'CustomerUserNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CustomerUserNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Customer User node */
export type CustomerUserNode = Node & {
  __typename?: 'CustomerUserNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  customerId: Scalars['Int'];
  userId: Scalars['Int'];
  customer?: Maybe<CustomerNode>;
  user?: Maybe<UserNode>;
};

/** User node */
export type UserNode = Node & {
  __typename?: 'UserNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  customerUser?: Maybe<CustomerUserNode>;
  userRoles?: Maybe<UserRoleNodeConnection>;
};


/** User node */
export type UserNodeUserRolesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserRoleNodeConnection = {
  __typename?: 'UserRoleNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserRoleNodeEdge>>;
};

/** A Relay edge containing a `UserRoleNode` and its cursor. */
export type UserRoleNodeEdge = {
  __typename?: 'UserRoleNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserRoleNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** UserRole node */
export type UserRoleNode = Node & {
  __typename?: 'UserRoleNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  userId: Scalars['Int'];
  roleId: Scalars['Int'];
  user?: Maybe<UserNode>;
  role?: Maybe<RoleNode>;
};

/** Role node */
export type RoleNode = Node & {
  __typename?: 'RoleNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  userRoles?: Maybe<UserRoleNodeConnection>;
};


/** Role node */
export type RoleNodeUserRolesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerNodeConnection = {
  __typename?: 'CustomerNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerNodeEdge>>;
};

/** A Relay edge containing a `CustomerNode` and its cursor. */
export type CustomerNodeEdge = {
  __typename?: 'CustomerNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CustomerNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum CustomerNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  IpViolationIdAsc = 'IP_VIOLATION_ID_ASC',
  IpViolationIdDesc = 'IP_VIOLATION_ID_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

export type BrandConnection = {
  __typename?: 'BrandConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandEdge>>;
};

/** A Relay edge containing a `Brand` and its cursor. */
export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** The item at the end of the edge */
  node?: Maybe<BrandNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type BrandFilter = {
  /** Exact match. */
  active?: Maybe<Scalars['Boolean']>;
  /** Conjunction of filters joined by ``AND``. */
  and?: Maybe<Array<BrandFilter>>;
  /** Conjunction of filters joined by ``OR``. */
  or?: Maybe<Array<BrandFilter>>;
  /** Negation of filters. */
  not?: Maybe<BrandFilter>;
};

/** An enumeration. */
export enum BrandNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  BrandStringAsc = 'BRAND_STRING_ASC',
  BrandStringDesc = 'BRAND_STRING_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  DataRelativePathAsc = 'DATA_RELATIVE_PATH_ASC',
  DataRelativePathDesc = 'DATA_RELATIVE_PATH_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

/** "Connection for default template */
export type DefaultTemplateConnection = {
  __typename?: 'DefaultTemplateConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DefaultTemplateEdge>>;
};

/** A Relay edge containing a `DefaultTemplate` and its cursor. */
export type DefaultTemplateEdge = {
  __typename?: 'DefaultTemplateEdge';
  /** The item at the end of the edge */
  node?: Maybe<DefaultTemplateNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Default Template Node */
export type DefaultTemplateNode = Node & {
  __typename?: 'DefaultTemplateNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  template: Scalars['String'];
  letterFormat: LetterType;
  stage: Scalars['Int'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
};

export type DefaultTemplateFilter = {
  letterFormat?: Maybe<LetterType>;
  /** Exact match. */
  stage?: Maybe<Scalars['Int']>;
  /** Conjunction of filters joined by ``AND``. */
  and?: Maybe<Array<DefaultTemplateFilter>>;
  /** Conjunction of filters joined by ``OR``. */
  or?: Maybe<Array<DefaultTemplateFilter>>;
  /** Negation of filters. */
  not?: Maybe<DefaultTemplateFilter>;
};

/** An enumeration. */
export enum DefaultTemplateNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TemplateAsc = 'TEMPLATE_ASC',
  TemplateDesc = 'TEMPLATE_DESC',
  LetterFormatAsc = 'LETTER_FORMAT_ASC',
  LetterFormatDesc = 'LETTER_FORMAT_DESC',
  StageAsc = 'STAGE_ASC',
  StageDesc = 'STAGE_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

export type IpViolationsNodeConnection = {
  __typename?: 'IpViolationsNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IpViolationsNodeEdge>>;
};

/** A Relay edge containing a `IpViolationsNode` and its cursor. */
export type IpViolationsNodeEdge = {
  __typename?: 'IpViolationsNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<IpViolationsNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum IpViolationsNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

export type TermsAndConditionsNodeConnection = {
  __typename?: 'TermsAndConditionsNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TermsAndConditionsNodeEdge>>;
};

/** A Relay edge containing a `TermsAndConditionsNode` and its cursor. */
export type TermsAndConditionsNodeEdge = {
  __typename?: 'TermsAndConditionsNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TermsAndConditionsNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum TermsAndConditionsNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

export type SellerNodeConnection = {
  __typename?: 'SellerNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SellerNodeEdge>>;
};

/** A Relay edge containing a `SellerNode` and its cursor. */
export type SellerNodeEdge = {
  __typename?: 'SellerNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SellerNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum SellerNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ExternalIdAsc = 'EXTERNAL_ID_ASC',
  ExternalIdDesc = 'EXTERNAL_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum UserNodeSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

/** Connection for Brand Task */
export type BrandTaskConnection = {
  __typename?: 'BrandTaskConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandTaskEdge>>;
};

/** A Relay edge containing a `BrandTask` and its cursor. */
export type BrandTaskEdge = {
  __typename?: 'BrandTaskEdge';
  /** The item at the end of the edge */
  node?: Maybe<BrandTaskNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Dictionary = {
  __typename?: 'Dictionary';
  fileName?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser?: Maybe<CreateUser>;
  /** Update a user. */
  updateUser?: Maybe<UpdateUser>;
  /** Mutation to create a customer. */
  createCustomer?: Maybe<CreateCustomer>;
  /** Update a customer. */
  updateCustomer?: Maybe<UpdateCustomer>;
  /** Mutation for updating customer onboarding status */
  updateCustomerOnboardingStatus?: Maybe<UpdateCustomerOnboardingStatus>;
  /** Mutation for storing the furthermost onboarding step a customer has reached */
  advanceCustomerOnboardingStep?: Maybe<AdvanceCustomerOnboardingStep>;
  /** Update a brand. */
  updateBrand?: Maybe<UpdateBrand>;
  /** Mutation to create a brand letter. */
  createBrandLetter?: Maybe<CreateBrandLetter>;
  /** Mutation to update a brand letter. */
  updateBrandLetter?: Maybe<UpdateBrandLetter>;
  /** Mutation for updating the customer violation setting */
  updateCustomerViolationSetting?: Maybe<UpdateCustomerViolationSetting>;
  /** Mutation to create a seller. */
  createSeller?: Maybe<CreateSeller>;
  /** Update a seller. */
  updateSeller?: Maybe<UpdateSeller>;
  /** Update a seller marketplace. */
  updateSellerMarketplace?: Maybe<UpdateSellerMarketplace>;
  exampleFileUpload?: Maybe<UploadMutation>;
  /** Upload Product File Mutation */
  uploadProductFile?: Maybe<UploadProductFile>;
  /** Mutation to create a customer contract. */
  createCustomerContract?: Maybe<CreateCustomerContract>;
  /** Update a customer contract */
  updateCustomerContract?: Maybe<UpdateCustomerContract>;
  /** Mutation to create a brand_task */
  loadBrandSellers?: Maybe<LoadBrandSellers>;
  /** Mutation for creating LetterHead for a Brand  */
  uploadLetterHead?: Maybe<UploadLetterHead>;
  /** Mutation to create a customer. */
  createDefaultTemplate?: Maybe<CreateDefaultTemplate>;
  /** Mutation to create a customer. */
  updateDefaultTemplate?: Maybe<UpdateDefaultTemplate>;
  /** Update a brand marketplace. */
  updateBrandMarketplace?: Maybe<UpdateBrandMarketplace>;
  /** Mutation to set CustomerTermsAndConditions. */
  setCustomerTermsAndConditions?: Maybe<SetCustomerTermsAndConditions>;
  /** Mutation to create BrandLetterRevision */
  createBrandLetterRevision?: Maybe<CreateBrandLetterRevision>;
  /** Create Product File Mutation */
  createProductFile?: Maybe<CreateProductFile>;
  /** Create Authorized Sellers File Mutation */
  createAuthorizedSellersFile?: Maybe<CreateAuthorizedSellersFile>;
  /** Create Terms And Conditions File Mutation */
  createTermsAndConditionsFile?: Maybe<CreateTermsAndConditionsFile>;
  /** Create Price Sheet File Mutation */
  createPriceSheetFile?: Maybe<CreatePriceSheetFile>;
  /** Create Approved Letter File Mutation */
  createApprovedLetterFile?: Maybe<CreateApprovedLetterFile>;
  /** Mutation to create LetterResetLog */
  createLetterResetLog?: Maybe<CreateLetterResetLog>;
  /** Mutation to approve a brand letter. */
  approveBrandLetter?: Maybe<ApproveBrandLetter>;
  /** Upload Terms and Conditions Mutation */
  uploadTermsAndConditions?: Maybe<UploadTermsAndConditions>;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateCustomerOnboardingStatusArgs = {
  input: UpdateCustomerOnboardingStatusInput;
};


export type MutationAdvanceCustomerOnboardingStepArgs = {
  input: AdvanceCustomerOnboardingStepInput;
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};


export type MutationCreateBrandLetterArgs = {
  input: CreateBrandLetterInput;
};


export type MutationUpdateBrandLetterArgs = {
  input: UpdateBrandLetterInput;
};


export type MutationUpdateCustomerViolationSettingArgs = {
  input: UpdateCustomerViolationSettingInput;
};


export type MutationCreateSellerArgs = {
  input: CreateSellerInput;
};


export type MutationUpdateSellerArgs = {
  input: UpdateSellerInput;
};


export type MutationUpdateSellerMarketplaceArgs = {
  input: UpdateSellerMarketplaceInput;
};


export type MutationExampleFileUploadArgs = {
  fileIn: Scalars['Upload'];
};


export type MutationUploadProductFileArgs = {
  input: UploadProductFileInput;
};


export type MutationCreateCustomerContractArgs = {
  input: CreateContractInput;
};


export type MutationUpdateCustomerContractArgs = {
  input: UpdateCustomerContractInput;
};


export type MutationLoadBrandSellersArgs = {
  input: LoadBrandSellersInput;
};


export type MutationUploadLetterHeadArgs = {
  input: UploadLetterHeadInput;
};


export type MutationCreateDefaultTemplateArgs = {
  input: CreateDefaultTemplateInput;
};


export type MutationUpdateDefaultTemplateArgs = {
  input: UpdateDefaultTemplateInput;
};


export type MutationUpdateBrandMarketplaceArgs = {
  input: UpdateBrandMarketplaceInput;
};


export type MutationSetCustomerTermsAndConditionsArgs = {
  input: SetCustomerTermsAndConditionsInput;
};


export type MutationCreateBrandLetterRevisionArgs = {
  input: BrandLetterRevisionInput;
};


export type MutationCreateProductFileArgs = {
  input: CreateFileDownloadInput;
};


export type MutationCreateAuthorizedSellersFileArgs = {
  input: CreateFileDownloadInput;
};


export type MutationCreateTermsAndConditionsFileArgs = {
  input: CreateFileDownloadInput;
};


export type MutationCreatePriceSheetFileArgs = {
  input: CreateFileDownloadInput;
};


export type MutationCreateApprovedLetterFileArgs = {
  input: CreateFileDownloadInput;
};


export type MutationCreateLetterResetLogArgs = {
  input: LetterResetLogInput;
};


export type MutationApproveBrandLetterArgs = {
  input: ApproveBrandLetterInput;
};


export type MutationUploadTermsAndConditionsArgs = {
  input: UploadTermsAndConditionsInput;
};

export type CreateUser = {
  __typename?: 'CreateUser';
  /** User created by this mutation. */
  user?: Maybe<UserNode>;
};

/** Arguments to create a user. */
export type CreateUserInput = {
  /** User first name */
  firstName?: Maybe<Scalars['String']>;
  /** User last name */
  lastName?: Maybe<Scalars['String']>;
  /** User email */
  email?: Maybe<Scalars['String']>;
};

/** Update a user. */
export type UpdateUser = {
  __typename?: 'UpdateUser';
  /** User updated by this mutation. */
  user?: Maybe<UserNode>;
};

/** Arguments to update a user. */
export type UpdateUserInput = {
  /** User first name */
  firstName?: Maybe<Scalars['String']>;
  /** User last name */
  lastName?: Maybe<Scalars['String']>;
  /** User email */
  email?: Maybe<Scalars['String']>;
  /** Global Id of the user. */
  id: Scalars['ID'];
};

/** Mutation to create a customer. */
export type CreateCustomer = {
  __typename?: 'CreateCustomer';
  /** Customer created by this mutation. */
  customer?: Maybe<CustomerNode>;
};

/** Arguments to create a customer. */
export type CreateCustomerInput = {
  /** Customer name */
  name: Scalars['String'];
  /** Customer email */
  email: Scalars['String'];
  /** Customer phone number */
  phone: Scalars['String'];
  /** Customer address */
  address?: Maybe<Scalars['String']>;
  /** Contact first name */
  contactFirstName: Scalars['String'];
  /** Contact last name */
  contactLastName: Scalars['String'];
  /** Impact letter count */
  impactLetterCount?: Maybe<Scalars['Int']>;
};

/** Update a customer. */
export type UpdateCustomer = {
  __typename?: 'UpdateCustomer';
  /** Customer updated by this mutation. */
  customer?: Maybe<CustomerNode>;
};

/** Arguments to update a customer. */
export type UpdateCustomerInput = {
  /** Global Id of the customer. */
  id: Scalars['ID'];
  /** Customer name */
  name?: Maybe<Scalars['String']>;
  /** Customer email */
  email?: Maybe<Scalars['String']>;
  /** Customer phone number */
  phone?: Maybe<Scalars['String']>;
  /** Customer address */
  address?: Maybe<Scalars['String']>;
};

/** Mutation for updating customer onboarding status */
export type UpdateCustomerOnboardingStatus = {
  __typename?: 'UpdateCustomerOnboardingStatus';
  /** Customer updated by this mutation. */
  customer?: Maybe<CustomerNode>;
};

/** Input for updating customer onboarding status */
export type UpdateCustomerOnboardingStatusInput = {
  /** Global Id of the customer. */
  id: Scalars['ID'];
  /** Onboarding status of the customer. */
  status: OnboardingStatus;
};

export enum OnboardingStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED'
}

/** Mutation for storing the furthermost onboarding step a customer has reached */
export type AdvanceCustomerOnboardingStep = {
  __typename?: 'AdvanceCustomerOnboardingStep';
  /** Customer updated by this mutation. */
  customer?: Maybe<CustomerNode>;
};

/** Input for advance customer onboarding step mutation */
export type AdvanceCustomerOnboardingStepInput = {
  /** Global Id of the customer. */
  id: Scalars['ID'];
  /** UI route from customer onboarding portal */
  step: Scalars['String'];
};

/** Update a brand. */
export type UpdateBrand = {
  __typename?: 'UpdateBrand';
  /** Brand updated by this mutation. */
  brand?: Maybe<BrandNode>;
};

/** Arguments to update a brand. */
export type UpdateBrandInput = {
  /** Brand name */
  name?: Maybe<Scalars['String']>;
  /** Boolean representing whether the brand is active or not */
  active?: Maybe<Scalars['Boolean']>;
  /** Global Id of the brand. */
  id: Scalars['ID'];
};

/** Mutation to create a brand letter. */
export type CreateBrandLetter = {
  __typename?: 'CreateBrandLetter';
  /** Brand letter created by this mutation. */
  brandLetter?: Maybe<BrandLetterNode>;
};

/** Arguments to create a brand letter. */
export type CreateBrandLetterInput = {
  /** Global Id of the brand. */
  brandId: Scalars['ID'];
  /** Global Id of the default template */
  defaultTemplateId: Scalars['ID'];
};

/** Mutation to update a brand letter. */
export type UpdateBrandLetter = {
  __typename?: 'UpdateBrandLetter';
  /** Brand letter updated by this mutation. */
  brandLetter?: Maybe<BrandLetterNode>;
};

/** Arguments to create a brand letter. */
export type UpdateBrandLetterInput = {
  /** Global Id of the brand letter. */
  id: Scalars['ID'];
  /** Template for brand letter. */
  template: Scalars['String'];
  /** Stage of the brand letter. */
  stage: Scalars['Int'];
  letterFormat: LetterType;
  /** Boolean representing whether the brand letter is active. */
  active?: Maybe<Scalars['Boolean']>;
};

/** Mutation for updating the customer violation setting */
export type UpdateCustomerViolationSetting = {
  __typename?: 'UpdateCustomerViolationSetting';
  /** Customer updated by this mutation. */
  customer?: Maybe<CustomerNode>;
};

/** Input for updating customer violation setting */
export type UpdateCustomerViolationSettingInput = {
  /** Global Id of the customer. */
  customerId: Scalars['ID'];
  /** Global Id of the IP violation */
  ipViolationId: Scalars['ID'];
};

/** Mutation to create a seller. */
export type CreateSeller = {
  __typename?: 'CreateSeller';
  /** Seller created by this mutation. */
  seller?: Maybe<SellerNode>;
};

/** Arguments to create a seller. */
export type CreateSellerInput = {
  /** External id */
  externalId: Scalars['String'];
  /** Seller name */
  name: Scalars['String'];
  /** Seller email */
  email: Scalars['String'];
  /** Seller url */
  url?: Maybe<Scalars['String']>;
  /** The global id of Brand */
  brandId: Scalars['ID'];
};

/** Update a seller. */
export type UpdateSeller = {
  __typename?: 'UpdateSeller';
  /** Seller updated by this mutation. */
  seller?: Maybe<SellerNode>;
};

/** Arguments to update a seller. */
export type UpdateSellerInput = {
  /** Global Id of the seller. */
  id: Scalars['ID'];
  /** External id */
  externalId?: Maybe<Scalars['String']>;
  /** Seller name */
  name?: Maybe<Scalars['String']>;
  /** Seller email */
  email?: Maybe<Scalars['String']>;
  /** Seller url */
  url?: Maybe<Scalars['String']>;
};

/** Update a seller marketplace. */
export type UpdateSellerMarketplace = {
  __typename?: 'UpdateSellerMarketplace';
  /** Seller marketplace updated by this mutation. */
  sellerMarketplace?: Maybe<SellerMarketplaceNode>;
};

/** Arguments to update a seller marketplace. */
export type UpdateSellerMarketplaceInput = {
  /** Global Id of the seller marketplace. */
  id: Scalars['ID'];
  /** Contact Seller */
  contactSeller?: Maybe<Scalars['Boolean']>;
  /** Authorized */
  authorized?: Maybe<Scalars['Boolean']>;
};

export type UploadMutation = {
  __typename?: 'UploadMutation';
  success?: Maybe<Scalars['Boolean']>;
  dataType?: Maybe<Scalars['String']>;
  directory?: Maybe<Scalars['String']>;
};


/** Upload Product File Mutation */
export type UploadProductFile = {
  __typename?: 'UploadProductFile';
  success?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

/** Arguments to Upload Product File */
export type UploadProductFileInput = {
  /** Global ID of the brand */
  brandId: Scalars['ID'];
  /** Base64 encoded string of product file */
  encodedFile: Scalars['String'];
};

/** Mutation to create a customer contract. */
export type CreateCustomerContract = {
  __typename?: 'CreateCustomerContract';
  /** Customer contract created by this mutation. */
  customerContract?: Maybe<CustomerContractNode>;
};

/** Arguments to create a customer contract. */
export type CreateContractInput = {
  /** The global id of Customer this Contract is related to */
  id: Scalars['ID'];
  /** The setup fee of this contract */
  setupFee: Scalars['Float'];
  /** The monthly fee of this contract */
  monthlyFee?: Maybe<Scalars['Float']>;
  /** Term */
  term: Scalars['Int'];
};

/** Update a customer contract */
export type UpdateCustomerContract = {
  __typename?: 'UpdateCustomerContract';
  /** CustomerContract updated by this mutation. */
  customerContract?: Maybe<CustomerContractNode>;
};

/** Arguments to update a customer contract. */
export type UpdateCustomerContractInput = {
  /** Global Id of the customer  */
  id: Scalars['ID'];
  /** The setup fee of this contract */
  setupFee?: Maybe<Scalars['Float']>;
  /** The monthly fee of this contract */
  monthlyFee?: Maybe<Scalars['Float']>;
  /** Term */
  term?: Maybe<Scalars['Int']>;
};

/** Mutation to create a brand_task */
export type LoadBrandSellers = {
  __typename?: 'LoadBrandSellers';
  /** Brand Task created by this mutation */
  brandTask?: Maybe<BrandTaskNode>;
};

/** Arguments to create a brand_task */
export type LoadBrandSellersInput = {
  /** Global ID of the brand */
  brandId: Scalars['ID'];
};

/** Mutation for creating LetterHead for a Brand  */
export type UploadLetterHead = {
  __typename?: 'UploadLetterHead';
  /** Returns true if the upload process is successful */
  ok?: Maybe<Scalars['Boolean']>;
};

/** Arguments to create or update the LetterHead of a Brand  */
export type UploadLetterHeadInput = {
  /** The global id of Brand this LetterHead is related to */
  id: Scalars['ID'];
  /** Base64 encoded string of letterhead image */
  encodedLetterhead: Scalars['String'];
};

/** Mutation to create a customer. */
export type CreateDefaultTemplate = {
  __typename?: 'CreateDefaultTemplate';
  /** Default Template created by this mutation. */
  defaultTemplate?: Maybe<DefaultTemplateNode>;
};

/** Arguments to create a default template  */
export type CreateDefaultTemplateInput = {
  /** Template text for default template */
  template: Scalars['String'];
  /** stage for default template */
  stage: Scalars['Int'];
  letterFormat?: Maybe<LetterType>;
};

/** Mutation to create a customer. */
export type UpdateDefaultTemplate = {
  __typename?: 'UpdateDefaultTemplate';
  /** Default Template created by this mutation. */
  defaultTemplate?: Maybe<DefaultTemplateNode>;
};

/** " Attributes to update default template  */
export type UpdateDefaultTemplateInput = {
  /** Template text for default template */
  template: Scalars['String'];
  /** stage for default template */
  stage: Scalars['Int'];
  letterFormat?: Maybe<LetterType>;
  /** Global ID of the Default Template */
  id: Scalars['ID'];
};

/** Update a brand marketplace. */
export type UpdateBrandMarketplace = {
  __typename?: 'UpdateBrandMarketplace';
  /** Brand marketplace updated by this mutation */
  brandMarketplace?: Maybe<BrandMarketplaceNode>;
};

/** Arguments to update brand marketplace. */
export type UpdateBrandMarketplaceInput = {
  /** Global Id of the brand marketplace */
  id: Scalars['ID'];
  /** Monitor Marketplace */
  monitorMarketplace?: Maybe<Scalars['Boolean']>;
  /** Engage Sellers */
  engageSellers?: Maybe<Scalars['Boolean']>;
};

/** Mutation to set CustomerTermsAndConditions. */
export type SetCustomerTermsAndConditions = {
  __typename?: 'SetCustomerTermsAndConditions';
  /** CustomerTermsAndConditions set by this mutation. */
  customerTermsAndConditions?: Maybe<CustomerTermsAndConditionsNode>;
};

/** Arguments to set CustomerTermsAndConditions. */
export type SetCustomerTermsAndConditionsInput = {
  /** The global id of Customer this TermsAndConditions is related to */
  customerId: Scalars['ID'];
  /** The global id of TermsAndConditions this Customer is related to */
  termsAndConditionsId: Scalars['ID'];
};

/** Mutation to create BrandLetterRevision */
export type CreateBrandLetterRevision = {
  __typename?: 'CreateBrandLetterRevision';
  /** Brand letter revision updated by this mutation. */
  brandLetterRevision?: Maybe<BrandLetterRevisionNode>;
};

/** Arguments to create a BrandLetterRevision */
export type BrandLetterRevisionInput = {
  /** Global Id of the brand letter */
  id: Scalars['ID'];
  /** Template revision for brand letter */
  templateRevision: Scalars['String'];
};

/** Create Product File Mutation */
export type CreateProductFile = {
  __typename?: 'CreateProductFile';
  /** Returns true if the upload process is successful */
  success?: Maybe<Scalars['Boolean']>;
};

/** Arguments to create a File to Download */
export type CreateFileDownloadInput = {
  /** Global ID of the brand */
  brandId: Scalars['ID'];
};

/** Create Authorized Sellers File Mutation */
export type CreateAuthorizedSellersFile = {
  __typename?: 'CreateAuthorizedSellersFile';
  /** Returns true if the upload process is successful */
  success?: Maybe<Scalars['Boolean']>;
};

/** Create Terms And Conditions File Mutation */
export type CreateTermsAndConditionsFile = {
  __typename?: 'CreateTermsAndConditionsFile';
  /** Returns true if the upload process is successful */
  success?: Maybe<Scalars['Boolean']>;
};

/** Create Price Sheet File Mutation */
export type CreatePriceSheetFile = {
  __typename?: 'CreatePriceSheetFile';
  /** Returns true if the upload process is successful */
  success?: Maybe<Scalars['Boolean']>;
};

/** Create Approved Letter File Mutation */
export type CreateApprovedLetterFile = {
  __typename?: 'CreateApprovedLetterFile';
  /** Returns true if the upload process is successful */
  success?: Maybe<Scalars['Boolean']>;
};

/** Mutation to create LetterResetLog */
export type CreateLetterResetLog = {
  __typename?: 'CreateLetterResetLog';
  /** Letter reset log created by this mutation. */
  letterResetLog?: Maybe<LetterResetLogNode>;
};

/** Letter reset log node */
export type LetterResetLogNode = Node & {
  __typename?: 'LetterResetLogNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId: Scalars['Int'];
  created: Scalars['DateTime'];
  brand?: Maybe<BrandNode>;
};

/** Arguments to create a LetterResetLog */
export type LetterResetLogInput = {
  /** Global ID of the customer */
  customerId: Scalars['ID'];
};

/** Mutation to approve a brand letter. */
export type ApproveBrandLetter = {
  __typename?: 'ApproveBrandLetter';
  /** Brand letter approved by this mutation. */
  brandLetter?: Maybe<BrandLetterNode>;
};

/** Input to approve a brand letter */
export type ApproveBrandLetterInput = {
  /** Global Id of the brand letter */
  id: Scalars['ID'];
};

/** Upload Terms and Conditions Mutation */
export type UploadTermsAndConditions = {
  __typename?: 'UploadTermsAndConditions';
  success?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
};

/** Arguments to Upload Terms And Conditions File */
export type UploadTermsAndConditionsInput = {
  /** Global ID of customer */
  customerId: Scalars['ID'];
  /** Name of the Terms and Conditions */
  name: Scalars['String'];
  /** Base64 encoded string of terms and conditions file */
  encodedFile: Scalars['String'];
};

export type CreateCustomerContractMutationVariables = Exact<{
  input: CreateContractInput;
}>;


export type CreateCustomerContractMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerContract?: Maybe<(
    { __typename?: 'CreateCustomerContract' }
    & { customerContract?: Maybe<(
      { __typename?: 'CustomerContractNode' }
      & Pick<CustomerContractNode, 'id' | 'term' | 'setupFee' | 'monthlyFee'>
    )> }
  )> }
);

export type GetTermsAndConditionsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTermsAndConditionsListQuery = (
  { __typename?: 'Query' }
  & { termsAndConditionsList?: Maybe<(
    { __typename?: 'TermsAndConditionsNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'TermsAndConditionsNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'TermsAndConditionsNode' }
        & Pick<TermsAndConditionsNode, 'id' | 'name' | 'content'>
      )> }
    )>> }
  )> }
);

export type SetTermsAndConditionsMutationVariables = Exact<{
  input: SetCustomerTermsAndConditionsInput;
}>;


export type SetTermsAndConditionsMutation = (
  { __typename?: 'Mutation' }
  & { setCustomerTermsAndConditions?: Maybe<(
    { __typename?: 'SetCustomerTermsAndConditions' }
    & { customerTermsAndConditions?: Maybe<(
      { __typename?: 'CustomerTermsAndConditionsNode' }
      & { termsAndConditions?: Maybe<(
        { __typename?: 'TermsAndConditionsNode' }
        & Pick<TermsAndConditionsNode, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type UpdateBrandMarketplaceMutationVariables = Exact<{
  input: UpdateBrandMarketplaceInput;
}>;


export type UpdateBrandMarketplaceMutation = (
  { __typename?: 'Mutation' }
  & { updateBrandMarketplace?: Maybe<(
    { __typename?: 'UpdateBrandMarketplace' }
    & { brandMarketplace?: Maybe<(
      { __typename?: 'BrandMarketplaceNode' }
      & Pick<BrandMarketplaceNode, 'id' | 'engageSellers' | 'monitorMarketplace'>
    )> }
  )> }
);

export type UpdateCustomerContractMutationVariables = Exact<{
  input: UpdateCustomerContractInput;
}>;


export type UpdateCustomerContractMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerContract?: Maybe<(
    { __typename?: 'UpdateCustomerContract' }
    & { customerContract?: Maybe<(
      { __typename?: 'CustomerContractNode' }
      & Pick<CustomerContractNode, 'id' | 'term' | 'setupFee' | 'monthlyFee'>
    )> }
  )> }
);

export type UploadTermsAndConditionsMutationVariables = Exact<{
  input: UploadTermsAndConditionsInput;
}>;


export type UploadTermsAndConditionsMutation = (
  { __typename?: 'Mutation' }
  & { uploadTermsAndConditions?: Maybe<(
    { __typename?: 'UploadTermsAndConditions' }
    & Pick<UploadTermsAndConditions, 'success' | 'errorMessage'>
  )> }
);

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer?: Maybe<(
    { __typename?: 'CreateCustomer' }
    & { customer?: Maybe<(
      { __typename?: 'CustomerNode' }
      & Pick<CustomerNode, 'id'>
    )> }
  )> }
);

export type FetchCustomersWithMinimumDetailsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<CustomerNodeSortEnum>>>;
}>;


export type FetchCustomersWithMinimumDetailsQuery = (
  { __typename?: 'Query' }
  & { customerList?: Maybe<(
    { __typename?: 'CustomerNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'CustomerNodeEdge' }
      & Pick<CustomerNodeEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'CustomerNode' }
        & Pick<CustomerNode, 'id' | 'name' | 'email'>
        & { customerOnboardingStep?: Maybe<(
          { __typename?: 'CustomerOnboardingStep' }
          & Pick<CustomerOnboardingStep, 'id' | 'status'>
        )> }
      )> }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type FetchFullCustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FetchFullCustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & Pick<CustomerNode, 'id' | 'name' | 'email' | 'phone' | 'address' | 'ipViolationId'>
    & { brands?: Maybe<(
      { __typename?: 'BrandNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'BrandNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'BrandNode' }
          & Pick<BrandNode, 'id'>
          & { brandMarketplaces?: Maybe<(
            { __typename?: 'BrandMarketplaceNodeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'BrandMarketplaceNodeEdge' }
              & { node?: Maybe<(
                { __typename?: 'BrandMarketplaceNode' }
                & Pick<BrandMarketplaceNode, 'id' | 'impactLetterCount' | 'monitorMarketplace' | 'engageSellers'>
              )> }
            )>> }
          )> }
        )> }
      )>> }
    )>, customerTermsAndCondition?: Maybe<(
      { __typename?: 'CustomerTermsAndConditionsNode' }
      & Pick<CustomerTermsAndConditionsNode, 'id'>
      & { termsAndConditions?: Maybe<(
        { __typename?: 'TermsAndConditionsNode' }
        & Pick<TermsAndConditionsNode, 'id' | 'name' | 'content'>
      )> }
    )>, ipViolation?: Maybe<(
      { __typename?: 'IpViolationsNode' }
      & Pick<IpViolationsNode, 'id'>
    )>, customerOnboardingStep?: Maybe<(
      { __typename?: 'CustomerOnboardingStep' }
      & Pick<CustomerOnboardingStep, 'id' | 'status'>
    )>, customerContract?: Maybe<(
      { __typename?: 'CustomerContractNode' }
      & Pick<CustomerContractNode, 'id' | 'term' | 'setupFee' | 'monthlyFee'>
    )> }
  )> }
);

export type GetAllBrandLetterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAllBrandLetterQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'BrandNode' }
    & Pick<BrandNode, 'id' | 'name'>
    & { brandLetters?: Maybe<(
      { __typename?: 'BrandLetterNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'BrandLetterNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'BrandLetterNode' }
          & Pick<BrandLetterNode, 'id' | 'stage' | 'letterFormat' | 'template' | 'approvalStatus'>
          & { brandLetterRevision?: Maybe<(
            { __typename?: 'BrandLetterRevisionNodeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'BrandLetterRevisionNodeEdge' }
              & { node?: Maybe<(
                { __typename?: 'BrandLetterRevisionNode' }
                & Pick<BrandLetterRevisionNode, 'id' | 'templateRevision'>
              )> }
            )>> }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer?: Maybe<(
    { __typename?: 'UpdateCustomer' }
    & { customer?: Maybe<(
      { __typename?: 'CustomerNode' }
      & Pick<CustomerNode, 'id' | 'name' | 'email' | 'phone' | 'address' | 'ipViolationId'>
    )> }
  )> }
);

export type CreateBrandLetterMutationVariables = Exact<{
  input: CreateBrandLetterInput;
}>;


export type CreateBrandLetterMutation = (
  { __typename?: 'Mutation' }
  & { createBrandLetter?: Maybe<(
    { __typename?: 'CreateBrandLetter' }
    & { brandLetter?: Maybe<(
      { __typename?: 'BrandLetterNode' }
      & Pick<BrandLetterNode, 'id'>
    )> }
  )> }
);

export type UpdateBrandLetterMutationVariables = Exact<{
  input: UpdateBrandLetterInput;
}>;


export type UpdateBrandLetterMutation = (
  { __typename?: 'Mutation' }
  & { updateBrandLetter?: Maybe<(
    { __typename?: 'UpdateBrandLetter' }
    & { brandLetter?: Maybe<(
      { __typename?: 'BrandLetterNode' }
      & Pick<BrandLetterNode, 'id'>
    )> }
  )> }
);

export type CreateDefaultTemplateMutationVariables = Exact<{
  input: CreateDefaultTemplateInput;
}>;


export type CreateDefaultTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createDefaultTemplate?: Maybe<(
    { __typename?: 'CreateDefaultTemplate' }
    & { defaultTemplate?: Maybe<(
      { __typename?: 'DefaultTemplateNode' }
      & DefaultTemplateFragment
    )> }
  )> }
);

export type DefaultTemplateListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type DefaultTemplateListQuery = (
  { __typename?: 'Query' }
  & { defaultTemplateList?: Maybe<(
    { __typename?: 'DefaultTemplateConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'DefaultTemplateEdge' }
      & Pick<DefaultTemplateEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'DefaultTemplateNode' }
        & DefaultTemplateFragment
      )> }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type DefaultTemplateFragment = (
  { __typename?: 'DefaultTemplateNode' }
  & Pick<DefaultTemplateNode, 'id' | 'template' | 'letterFormat' | 'stage'>
);

export type UpdateDefaultTemplateMutationVariables = Exact<{
  input: UpdateDefaultTemplateInput;
}>;


export type UpdateDefaultTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateDefaultTemplate?: Maybe<(
    { __typename?: 'UpdateDefaultTemplate' }
    & { defaultTemplate?: Maybe<(
      { __typename?: 'DefaultTemplateNode' }
      & DefaultTemplateFragment
    )> }
  )> }
);

export type CreateLetterResetLogMutationVariables = Exact<{
  input: LetterResetLogInput;
}>;


export type CreateLetterResetLogMutation = (
  { __typename?: 'Mutation' }
  & { createLetterResetLog?: Maybe<(
    { __typename?: 'CreateLetterResetLog' }
    & { letterResetLog?: Maybe<(
      { __typename?: 'LetterResetLogNode' }
      & { brand?: Maybe<(
        { __typename?: 'BrandNode' }
        & Pick<BrandNode, 'name'>
      )> }
    )> }
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UserNode' }
    & { customerUser?: Maybe<(
      { __typename?: 'CustomerUserNode' }
      & { customer?: Maybe<(
        { __typename?: 'CustomerNode' }
        & Pick<CustomerNode, 'id' | 'name'>
        & { brands?: Maybe<(
          { __typename?: 'BrandNodeConnection' }
          & { edges: Array<Maybe<(
            { __typename?: 'BrandNodeEdge' }
            & { node?: Maybe<(
              { __typename?: 'BrandNode' }
              & Pick<BrandNode, 'id' | 'name'>
            )> }
          )>> }
        )>, customerOnboardingStep?: Maybe<(
          { __typename?: 'CustomerOnboardingStep' }
          & Pick<CustomerOnboardingStep, 'id' | 'status'>
          & { onboardingStep?: Maybe<(
            { __typename?: 'OnboardingStep' }
            & Pick<OnboardingStep, 'uiRouteId'>
          )> }
        )> }
      )> }
    )>, userRoles?: Maybe<(
      { __typename?: 'UserRoleNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'UserRoleNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'UserRoleNode' }
          & { role?: Maybe<(
            { __typename?: 'RoleNode' }
            & Pick<RoleNode, 'id' | 'name'>
          )>, user?: Maybe<(
            { __typename?: 'UserNode' }
            & Pick<UserNode, 'id' | 'email' | 'firstName' | 'lastName'>
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type LoadBrandSellersMutationVariables = Exact<{
  input: LoadBrandSellersInput;
}>;


export type LoadBrandSellersMutation = (
  { __typename?: 'Mutation' }
  & { loadBrandSellers?: Maybe<(
    { __typename?: 'LoadBrandSellers' }
    & { brandTask?: Maybe<(
      { __typename?: 'BrandTaskNode' }
      & Pick<BrandTaskNode, 'id' | 'taskName' | 'status'>
    )> }
  )> }
);

export type UploadProductFileMutationVariables = Exact<{
  input: UploadProductFileInput;
}>;


export type UploadProductFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadProductFile?: Maybe<(
    { __typename?: 'UploadProductFile' }
    & Pick<UploadProductFile, 'success' | 'brandId' | 'errorMessage'>
  )> }
);

export type GetOnboardingFilesUrlsQueryVariables = Exact<{
  brandId: Scalars['String'];
}>;


export type GetOnboardingFilesUrlsQuery = (
  { __typename?: 'Query' }
  & { getOnboardingFilesUrls?: Maybe<Array<Maybe<(
    { __typename?: 'Dictionary' }
    & Pick<Dictionary, 'fileName' | 'signedUrl'>
  )>>> }
);

export type ApproveBrandLetterMutationVariables = Exact<{
  input: ApproveBrandLetterInput;
}>;


export type ApproveBrandLetterMutation = (
  { __typename?: 'Mutation' }
  & { approveBrandLetter?: Maybe<(
    { __typename?: 'ApproveBrandLetter' }
    & { brandLetter?: Maybe<(
      { __typename?: 'BrandLetterNode' }
      & Pick<BrandLetterNode, 'id' | 'approvalStatus' | 'stage'>
    )> }
  )> }
);

export type CreateBrandLetterRevisionMutationVariables = Exact<{
  input: BrandLetterRevisionInput;
}>;


export type CreateBrandLetterRevisionMutation = (
  { __typename?: 'Mutation' }
  & { createBrandLetterRevision?: Maybe<(
    { __typename?: 'CreateBrandLetterRevision' }
    & { brandLetterRevision?: Maybe<(
      { __typename?: 'BrandLetterRevisionNode' }
      & Pick<BrandLetterRevisionNode, 'brandLetterId' | 'templateRevision' | 'id'>
    )> }
  )> }
);

export type GetBrandLetterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBrandLetterQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'BrandNode' }
    & Pick<BrandNode, 'id' | 'name'>
    & { brandLetters?: Maybe<(
      { __typename?: 'BrandLetterNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'BrandLetterNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'BrandLetterNode' }
          & Pick<BrandLetterNode, 'id' | 'stage' | 'template' | 'approvalStatus'>
          & { brandLetterRevision?: Maybe<(
            { __typename?: 'BrandLetterRevisionNodeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'BrandLetterRevisionNodeEdge' }
              & { node?: Maybe<(
                { __typename?: 'BrandLetterRevisionNode' }
                & Pick<BrandLetterRevisionNode, 'id' | 'templateRevision'>
              )> }
            )>> }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type UploadLetterHeadMutationMutationVariables = Exact<{
  input: UploadLetterHeadInput;
}>;


export type UploadLetterHeadMutationMutation = (
  { __typename?: 'Mutation' }
  & { uploadLetterHead?: Maybe<(
    { __typename?: 'UploadLetterHead' }
    & Pick<UploadLetterHead, 'ok'>
  )> }
);

export type CreateSellerMutationVariables = Exact<{
  input: CreateSellerInput;
}>;


export type CreateSellerMutation = (
  { __typename?: 'Mutation' }
  & { createSeller?: Maybe<(
    { __typename?: 'CreateSeller' }
    & { seller?: Maybe<(
      { __typename?: 'SellerNode' }
      & Pick<SellerNode, 'id' | 'name' | 'url' | 'externalId' | 'email'>
      & { sellerMarketplaces?: Maybe<(
        { __typename?: 'SellerMarketplaceNodeConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'SellerMarketplaceNodeEdge' }
          & { node?: Maybe<(
            { __typename?: 'SellerMarketplaceNode' }
            & Pick<SellerMarketplaceNode, 'id' | 'authorized' | 'contactSeller'>
          )> }
        )>> }
      )> }
    )> }
  )> }
);

export type FetchRecentBrandTaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FetchRecentBrandTaskQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'BrandNode' }
    & Pick<BrandNode, 'id'>
    & { brandTasks?: Maybe<(
      { __typename?: 'BrandTaskNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'BrandTaskNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'BrandTaskNode' }
          & Pick<BrandTaskNode, 'id' | 'status' | 'errors'>
        )> }
      )>> }
    )> }
  )> }
);

export type FetchSellersOfABrandQueryVariables = Exact<{
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type FetchSellersOfABrandQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'BrandNode' }
    & Pick<BrandNode, 'id'>
    & { sellerMarketplaces?: Maybe<(
      { __typename?: 'SellerMarketplaceNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'SellerMarketplaceNodeEdge' }
        & Pick<SellerMarketplaceNodeEdge, 'cursor'>
        & { node?: Maybe<(
          { __typename?: 'SellerMarketplaceNode' }
          & Pick<SellerMarketplaceNode, 'id' | 'contactSeller' | 'authorized'>
          & { seller?: Maybe<(
            { __typename?: 'SellerNode' }
            & Pick<SellerNode, 'name' | 'id'>
          )> }
        )> }
      )>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  )> }
);

export type UpdateSellerMarketplaceMutationVariables = Exact<{
  input: UpdateSellerMarketplaceInput;
}>;


export type UpdateSellerMarketplaceMutation = (
  { __typename?: 'Mutation' }
  & { updateSellerMarketplace?: Maybe<(
    { __typename?: 'UpdateSellerMarketplace' }
    & { sellerMarketplace?: Maybe<(
      { __typename?: 'SellerMarketplaceNode' }
      & Pick<SellerMarketplaceNode, 'authorized' | 'contactSeller'>
      & { seller?: Maybe<(
        { __typename?: 'SellerNode' }
        & Pick<SellerNode, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type CreateProductFileMutationVariables = Exact<{
  input: CreateFileDownloadInput;
}>;


export type CreateProductFileMutation = (
  { __typename?: 'Mutation' }
  & { createProductFile?: Maybe<(
    { __typename?: 'CreateProductFile' }
    & Pick<CreateProductFile, 'success'>
  )> }
);

export type CreateAuthorizedSellersFileMutationVariables = Exact<{
  input: CreateFileDownloadInput;
}>;


export type CreateAuthorizedSellersFileMutation = (
  { __typename?: 'Mutation' }
  & { createAuthorizedSellersFile?: Maybe<(
    { __typename?: 'CreateAuthorizedSellersFile' }
    & Pick<CreateAuthorizedSellersFile, 'success'>
  )> }
);

export type CreateApprovedLetterFileMutationVariables = Exact<{
  input: CreateFileDownloadInput;
}>;


export type CreateApprovedLetterFileMutation = (
  { __typename?: 'Mutation' }
  & { createApprovedLetterFile?: Maybe<(
    { __typename?: 'CreateApprovedLetterFile' }
    & Pick<CreateApprovedLetterFile, 'success'>
  )> }
);

export type CreatePriceSheetFileMutationVariables = Exact<{
  input: CreateFileDownloadInput;
}>;


export type CreatePriceSheetFileMutation = (
  { __typename?: 'Mutation' }
  & { createPriceSheetFile?: Maybe<(
    { __typename?: 'CreatePriceSheetFile' }
    & Pick<CreatePriceSheetFile, 'success'>
  )> }
);

export type CreateTermsAndConditionsFileMutationVariables = Exact<{
  input: CreateFileDownloadInput;
}>;


export type CreateTermsAndConditionsFileMutation = (
  { __typename?: 'Mutation' }
  & { createTermsAndConditionsFile?: Maybe<(
    { __typename?: 'CreateTermsAndConditionsFile' }
    & Pick<CreateTermsAndConditionsFile, 'success'>
  )> }
);

export type GetBrandStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBrandStatusQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'BrandNode' }
    & Pick<BrandNode, 'id' | 'approvedImpactLetter' | 'addedProducts' | 'sellersFound' | 'letterheadUploaded'>
  )> }
);

export type GetCustomerTermsAndConditionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCustomerTermsAndConditionsQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & Pick<CustomerNode, 'id' | 'name' | 'email'>
    & { customerTermsAndCondition?: Maybe<(
      { __typename?: 'CustomerTermsAndConditionsNode' }
      & Pick<CustomerTermsAndConditionsNode, 'id'>
      & { termsAndConditions?: Maybe<(
        { __typename?: 'TermsAndConditionsNode' }
        & Pick<TermsAndConditionsNode, 'id' | 'name' | 'content'>
      )> }
    )> }
  )> }
);

export type GetIpViolationListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIpViolationListQuery = (
  { __typename?: 'Query' }
  & { ipViolationList?: Maybe<(
    { __typename?: 'IpViolationsNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'IpViolationsNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'IpViolationsNode' }
        & Pick<IpViolationsNode, 'id' | 'content'>
      )> }
    )>> }
  )> }
);

export type UpdateCustomerViolationSettingMutationVariables = Exact<{
  input: UpdateCustomerViolationSettingInput;
}>;


export type UpdateCustomerViolationSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerViolationSetting?: Maybe<(
    { __typename?: 'UpdateCustomerViolationSetting' }
    & { customer?: Maybe<(
      { __typename?: 'CustomerNode' }
      & { ipViolation?: Maybe<(
        { __typename?: 'IpViolationsNode' }
        & Pick<IpViolationsNode, 'id' | 'content'>
      )> }
    )> }
  )> }
);

export type AdvanceCustomerMutationVariables = Exact<{
  input: AdvanceCustomerOnboardingStepInput;
}>;


export type AdvanceCustomerMutation = (
  { __typename?: 'Mutation' }
  & { advanceCustomerOnboardingStep?: Maybe<(
    { __typename?: 'AdvanceCustomerOnboardingStep' }
    & { customer?: Maybe<(
      { __typename?: 'CustomerNode' }
      & Pick<CustomerNode, 'id' | 'name'>
    )> }
  )> }
);

export type GetCustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerNode' }
    & Pick<CustomerNode, 'id' | 'name'>
    & { customerOnboardingStep?: Maybe<(
      { __typename?: 'CustomerOnboardingStep' }
      & Pick<CustomerOnboardingStep, 'id' | 'status'>
      & { onboardingStep?: Maybe<(
        { __typename?: 'OnboardingStep' }
        & Pick<OnboardingStep, 'uiRouteId'>
      )> }
    )>, ipViolation?: Maybe<(
      { __typename?: 'IpViolationsNode' }
      & Pick<IpViolationsNode, 'id'>
    )>, customerContract?: Maybe<(
      { __typename?: 'CustomerContractNode' }
      & Pick<CustomerContractNode, 'id' | 'customerId' | 'term' | 'setupFee' | 'monthlyFee'>
    )> }
  )> }
);

export type UpdateCustomerOnboardingStatusMutationVariables = Exact<{
  input: UpdateCustomerOnboardingStatusInput;
}>;


export type UpdateCustomerOnboardingStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerOnboardingStatus?: Maybe<(
    { __typename?: 'UpdateCustomerOnboardingStatus' }
    & { customer?: Maybe<(
      { __typename?: 'CustomerNode' }
      & Pick<CustomerNode, 'id'>
      & { customerOnboardingStep?: Maybe<(
        { __typename?: 'CustomerOnboardingStep' }
        & Pick<CustomerOnboardingStep, 'id' | 'onboardingStepId' | 'customerId' | 'status'>
        & { onboardingStep?: Maybe<(
          { __typename?: 'OnboardingStep' }
          & Pick<OnboardingStep, 'uiRouteId'>
        )> }
      )> }
    )> }
  )> }
);

export const DefaultTemplateFragmentDoc = gql`
    fragment DefaultTemplate on DefaultTemplateNode {
  id
  template
  letterFormat
  stage
}
    `;
export const CreateCustomerContractDocument = gql`
    mutation CreateCustomerContract($input: CreateContractInput!) {
  createCustomerContract(input: $input) {
    customerContract {
      id
      term
      setupFee
      monthlyFee
    }
  }
}
    `;
export type CreateCustomerContractMutationFn = Apollo.MutationFunction<CreateCustomerContractMutation, CreateCustomerContractMutationVariables>;

/**
 * __useCreateCustomerContractMutation__
 *
 * To run a mutation, you first call `useCreateCustomerContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerContractMutation, { data, loading, error }] = useCreateCustomerContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerContractMutation, CreateCustomerContractMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerContractMutation, CreateCustomerContractMutationVariables>(CreateCustomerContractDocument, baseOptions);
      }
export type CreateCustomerContractMutationHookResult = ReturnType<typeof useCreateCustomerContractMutation>;
export type CreateCustomerContractMutationResult = Apollo.MutationResult<CreateCustomerContractMutation>;
export type CreateCustomerContractMutationOptions = Apollo.BaseMutationOptions<CreateCustomerContractMutation, CreateCustomerContractMutationVariables>;
export const GetTermsAndConditionsListDocument = gql`
    query GetTermsAndConditionsList {
  termsAndConditionsList {
    edges {
      node {
        id
        name
        content
      }
    }
  }
}
    `;

/**
 * __useGetTermsAndConditionsListQuery__
 *
 * To run a query within a React component, call `useGetTermsAndConditionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermsAndConditionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermsAndConditionsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTermsAndConditionsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTermsAndConditionsListQuery, GetTermsAndConditionsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTermsAndConditionsListQuery, GetTermsAndConditionsListQueryVariables>(GetTermsAndConditionsListDocument, baseOptions);
      }
export function useGetTermsAndConditionsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTermsAndConditionsListQuery, GetTermsAndConditionsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTermsAndConditionsListQuery, GetTermsAndConditionsListQueryVariables>(GetTermsAndConditionsListDocument, baseOptions);
        }
export type GetTermsAndConditionsListQueryHookResult = ReturnType<typeof useGetTermsAndConditionsListQuery>;
export type GetTermsAndConditionsListLazyQueryHookResult = ReturnType<typeof useGetTermsAndConditionsListLazyQuery>;
export type GetTermsAndConditionsListQueryResult = Apollo.QueryResult<GetTermsAndConditionsListQuery, GetTermsAndConditionsListQueryVariables>;
export const SetTermsAndConditionsDocument = gql`
    mutation SetTermsAndConditions($input: SetCustomerTermsAndConditionsInput!) {
  setCustomerTermsAndConditions(input: $input) {
    customerTermsAndConditions {
      termsAndConditions {
        id
        name
      }
    }
  }
}
    `;
export type SetTermsAndConditionsMutationFn = Apollo.MutationFunction<SetTermsAndConditionsMutation, SetTermsAndConditionsMutationVariables>;

/**
 * __useSetTermsAndConditionsMutation__
 *
 * To run a mutation, you first call `useSetTermsAndConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTermsAndConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTermsAndConditionsMutation, { data, loading, error }] = useSetTermsAndConditionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTermsAndConditionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetTermsAndConditionsMutation, SetTermsAndConditionsMutationVariables>) {
        return ApolloReactHooks.useMutation<SetTermsAndConditionsMutation, SetTermsAndConditionsMutationVariables>(SetTermsAndConditionsDocument, baseOptions);
      }
export type SetTermsAndConditionsMutationHookResult = ReturnType<typeof useSetTermsAndConditionsMutation>;
export type SetTermsAndConditionsMutationResult = Apollo.MutationResult<SetTermsAndConditionsMutation>;
export type SetTermsAndConditionsMutationOptions = Apollo.BaseMutationOptions<SetTermsAndConditionsMutation, SetTermsAndConditionsMutationVariables>;
export const UpdateBrandMarketplaceDocument = gql`
    mutation UpdateBrandMarketplace($input: UpdateBrandMarketplaceInput!) {
  updateBrandMarketplace(input: $input) {
    brandMarketplace {
      id
      engageSellers
      monitorMarketplace
    }
  }
}
    `;
export type UpdateBrandMarketplaceMutationFn = Apollo.MutationFunction<UpdateBrandMarketplaceMutation, UpdateBrandMarketplaceMutationVariables>;

/**
 * __useUpdateBrandMarketplaceMutation__
 *
 * To run a mutation, you first call `useUpdateBrandMarketplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandMarketplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandMarketplaceMutation, { data, loading, error }] = useUpdateBrandMarketplaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandMarketplaceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBrandMarketplaceMutation, UpdateBrandMarketplaceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBrandMarketplaceMutation, UpdateBrandMarketplaceMutationVariables>(UpdateBrandMarketplaceDocument, baseOptions);
      }
export type UpdateBrandMarketplaceMutationHookResult = ReturnType<typeof useUpdateBrandMarketplaceMutation>;
export type UpdateBrandMarketplaceMutationResult = Apollo.MutationResult<UpdateBrandMarketplaceMutation>;
export type UpdateBrandMarketplaceMutationOptions = Apollo.BaseMutationOptions<UpdateBrandMarketplaceMutation, UpdateBrandMarketplaceMutationVariables>;
export const UpdateCustomerContractDocument = gql`
    mutation UpdateCustomerContract($input: UpdateCustomerContractInput!) {
  updateCustomerContract(input: $input) {
    customerContract {
      id
      term
      setupFee
      monthlyFee
    }
  }
}
    `;
export type UpdateCustomerContractMutationFn = Apollo.MutationFunction<UpdateCustomerContractMutation, UpdateCustomerContractMutationVariables>;

/**
 * __useUpdateCustomerContractMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerContractMutation, { data, loading, error }] = useUpdateCustomerContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerContractMutation, UpdateCustomerContractMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerContractMutation, UpdateCustomerContractMutationVariables>(UpdateCustomerContractDocument, baseOptions);
      }
export type UpdateCustomerContractMutationHookResult = ReturnType<typeof useUpdateCustomerContractMutation>;
export type UpdateCustomerContractMutationResult = Apollo.MutationResult<UpdateCustomerContractMutation>;
export type UpdateCustomerContractMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerContractMutation, UpdateCustomerContractMutationVariables>;
export const UploadTermsAndConditionsDocument = gql`
    mutation UploadTermsAndConditions($input: UploadTermsAndConditionsInput!) {
  uploadTermsAndConditions(input: $input) {
    success
    errorMessage
  }
}
    `;
export type UploadTermsAndConditionsMutationFn = Apollo.MutationFunction<UploadTermsAndConditionsMutation, UploadTermsAndConditionsMutationVariables>;

/**
 * __useUploadTermsAndConditionsMutation__
 *
 * To run a mutation, you first call `useUploadTermsAndConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTermsAndConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTermsAndConditionsMutation, { data, loading, error }] = useUploadTermsAndConditionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadTermsAndConditionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadTermsAndConditionsMutation, UploadTermsAndConditionsMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadTermsAndConditionsMutation, UploadTermsAndConditionsMutationVariables>(UploadTermsAndConditionsDocument, baseOptions);
      }
export type UploadTermsAndConditionsMutationHookResult = ReturnType<typeof useUploadTermsAndConditionsMutation>;
export type UploadTermsAndConditionsMutationResult = Apollo.MutationResult<UploadTermsAndConditionsMutation>;
export type UploadTermsAndConditionsMutationOptions = Apollo.BaseMutationOptions<UploadTermsAndConditionsMutation, UploadTermsAndConditionsMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($input: CreateCustomerInput!) {
  createCustomer(input: $input) {
    customer {
      id
    }
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, baseOptions);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const FetchCustomersWithMinimumDetailsDocument = gql`
    query FetchCustomersWithMinimumDetails($first: Int, $after: String, $before: String, $sort: [CustomerNodeSortEnum]) {
  customerList(first: $first, after: $after, before: $before, sort: $sort) {
    edges {
      node {
        id
        name
        email
        customerOnboardingStep {
          id
          status
        }
      }
      cursor
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useFetchCustomersWithMinimumDetailsQuery__
 *
 * To run a query within a React component, call `useFetchCustomersWithMinimumDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCustomersWithMinimumDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCustomersWithMinimumDetailsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchCustomersWithMinimumDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchCustomersWithMinimumDetailsQuery, FetchCustomersWithMinimumDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchCustomersWithMinimumDetailsQuery, FetchCustomersWithMinimumDetailsQueryVariables>(FetchCustomersWithMinimumDetailsDocument, baseOptions);
      }
export function useFetchCustomersWithMinimumDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchCustomersWithMinimumDetailsQuery, FetchCustomersWithMinimumDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchCustomersWithMinimumDetailsQuery, FetchCustomersWithMinimumDetailsQueryVariables>(FetchCustomersWithMinimumDetailsDocument, baseOptions);
        }
export type FetchCustomersWithMinimumDetailsQueryHookResult = ReturnType<typeof useFetchCustomersWithMinimumDetailsQuery>;
export type FetchCustomersWithMinimumDetailsLazyQueryHookResult = ReturnType<typeof useFetchCustomersWithMinimumDetailsLazyQuery>;
export type FetchCustomersWithMinimumDetailsQueryResult = Apollo.QueryResult<FetchCustomersWithMinimumDetailsQuery, FetchCustomersWithMinimumDetailsQueryVariables>;
export const FetchFullCustomerDocument = gql`
    query FetchFullCustomer($id: ID!) {
  customer(id: $id) {
    id
    name
    email
    phone
    address
    brands {
      edges {
        node {
          id
          brandMarketplaces {
            edges {
              node {
                id
                impactLetterCount
                monitorMarketplace
                engageSellers
              }
            }
          }
        }
      }
    }
    customerTermsAndCondition {
      id
      termsAndConditions {
        id
        name
        content
      }
    }
    ipViolationId
    ipViolation {
      id
    }
    customerOnboardingStep {
      id
      status
    }
    customerContract {
      id
      term
      setupFee
      monthlyFee
    }
    brands {
      edges {
        node {
          brandMarketplaces {
            edges {
              node {
                id
                impactLetterCount
                monitorMarketplace
                engageSellers
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFetchFullCustomerQuery__
 *
 * To run a query within a React component, call `useFetchFullCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFullCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFullCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchFullCustomerQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FetchFullCustomerQuery, FetchFullCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchFullCustomerQuery, FetchFullCustomerQueryVariables>(FetchFullCustomerDocument, baseOptions);
      }
export function useFetchFullCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchFullCustomerQuery, FetchFullCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchFullCustomerQuery, FetchFullCustomerQueryVariables>(FetchFullCustomerDocument, baseOptions);
        }
export type FetchFullCustomerQueryHookResult = ReturnType<typeof useFetchFullCustomerQuery>;
export type FetchFullCustomerLazyQueryHookResult = ReturnType<typeof useFetchFullCustomerLazyQuery>;
export type FetchFullCustomerQueryResult = Apollo.QueryResult<FetchFullCustomerQuery, FetchFullCustomerQueryVariables>;
export const GetAllBrandLetterDocument = gql`
    query GetAllBrandLetter($id: ID!) {
  brand(id: $id) {
    id
    name
    brandLetters(sort: STAGE_ASC) {
      edges {
        node {
          id
          stage
          letterFormat
          template
          approvalStatus
          brandLetterRevision(sort: CREATED_DESC, first: 1) {
            edges {
              node {
                id
                templateRevision
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllBrandLetterQuery__
 *
 * To run a query within a React component, call `useGetAllBrandLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBrandLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBrandLetterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllBrandLetterQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAllBrandLetterQuery, GetAllBrandLetterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllBrandLetterQuery, GetAllBrandLetterQueryVariables>(GetAllBrandLetterDocument, baseOptions);
      }
export function useGetAllBrandLetterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllBrandLetterQuery, GetAllBrandLetterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllBrandLetterQuery, GetAllBrandLetterQueryVariables>(GetAllBrandLetterDocument, baseOptions);
        }
export type GetAllBrandLetterQueryHookResult = ReturnType<typeof useGetAllBrandLetterQuery>;
export type GetAllBrandLetterLazyQueryHookResult = ReturnType<typeof useGetAllBrandLetterLazyQuery>;
export type GetAllBrandLetterQueryResult = Apollo.QueryResult<GetAllBrandLetterQuery, GetAllBrandLetterQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($input: UpdateCustomerInput!) {
  updateCustomer(input: $input) {
    customer {
      id
      name
      email
      phone
      address
      ipViolationId
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const CreateBrandLetterDocument = gql`
    mutation CreateBrandLetter($input: CreateBrandLetterInput!) {
  createBrandLetter(input: $input) {
    brandLetter {
      id
    }
  }
}
    `;
export type CreateBrandLetterMutationFn = Apollo.MutationFunction<CreateBrandLetterMutation, CreateBrandLetterMutationVariables>;

/**
 * __useCreateBrandLetterMutation__
 *
 * To run a mutation, you first call `useCreateBrandLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandLetterMutation, { data, loading, error }] = useCreateBrandLetterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandLetterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBrandLetterMutation, CreateBrandLetterMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBrandLetterMutation, CreateBrandLetterMutationVariables>(CreateBrandLetterDocument, baseOptions);
      }
export type CreateBrandLetterMutationHookResult = ReturnType<typeof useCreateBrandLetterMutation>;
export type CreateBrandLetterMutationResult = Apollo.MutationResult<CreateBrandLetterMutation>;
export type CreateBrandLetterMutationOptions = Apollo.BaseMutationOptions<CreateBrandLetterMutation, CreateBrandLetterMutationVariables>;
export const UpdateBrandLetterDocument = gql`
    mutation UpdateBrandLetter($input: UpdateBrandLetterInput!) {
  updateBrandLetter(input: $input) {
    brandLetter {
      id
    }
  }
}
    `;
export type UpdateBrandLetterMutationFn = Apollo.MutationFunction<UpdateBrandLetterMutation, UpdateBrandLetterMutationVariables>;

/**
 * __useUpdateBrandLetterMutation__
 *
 * To run a mutation, you first call `useUpdateBrandLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandLetterMutation, { data, loading, error }] = useUpdateBrandLetterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandLetterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBrandLetterMutation, UpdateBrandLetterMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBrandLetterMutation, UpdateBrandLetterMutationVariables>(UpdateBrandLetterDocument, baseOptions);
      }
export type UpdateBrandLetterMutationHookResult = ReturnType<typeof useUpdateBrandLetterMutation>;
export type UpdateBrandLetterMutationResult = Apollo.MutationResult<UpdateBrandLetterMutation>;
export type UpdateBrandLetterMutationOptions = Apollo.BaseMutationOptions<UpdateBrandLetterMutation, UpdateBrandLetterMutationVariables>;
export const CreateDefaultTemplateDocument = gql`
    mutation CreateDefaultTemplate($input: CreateDefaultTemplateInput!) {
  createDefaultTemplate(input: $input) {
    defaultTemplate {
      ...DefaultTemplate
    }
  }
}
    ${DefaultTemplateFragmentDoc}`;
export type CreateDefaultTemplateMutationFn = Apollo.MutationFunction<CreateDefaultTemplateMutation, CreateDefaultTemplateMutationVariables>;

/**
 * __useCreateDefaultTemplateMutation__
 *
 * To run a mutation, you first call `useCreateDefaultTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefaultTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefaultTemplateMutation, { data, loading, error }] = useCreateDefaultTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDefaultTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDefaultTemplateMutation, CreateDefaultTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDefaultTemplateMutation, CreateDefaultTemplateMutationVariables>(CreateDefaultTemplateDocument, baseOptions);
      }
export type CreateDefaultTemplateMutationHookResult = ReturnType<typeof useCreateDefaultTemplateMutation>;
export type CreateDefaultTemplateMutationResult = Apollo.MutationResult<CreateDefaultTemplateMutation>;
export type CreateDefaultTemplateMutationOptions = Apollo.BaseMutationOptions<CreateDefaultTemplateMutation, CreateDefaultTemplateMutationVariables>;
export const DefaultTemplateListDocument = gql`
    query DefaultTemplateList($first: Int, $after: String, $before: String) {
  defaultTemplateList(
    first: $first
    after: $after
    before: $before
    sort: STAGE_ASC
  ) {
    edges {
      node {
        ...DefaultTemplate
      }
      cursor
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${DefaultTemplateFragmentDoc}`;

/**
 * __useDefaultTemplateListQuery__
 *
 * To run a query within a React component, call `useDefaultTemplateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultTemplateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultTemplateListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useDefaultTemplateListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DefaultTemplateListQuery, DefaultTemplateListQueryVariables>) {
        return ApolloReactHooks.useQuery<DefaultTemplateListQuery, DefaultTemplateListQueryVariables>(DefaultTemplateListDocument, baseOptions);
      }
export function useDefaultTemplateListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefaultTemplateListQuery, DefaultTemplateListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DefaultTemplateListQuery, DefaultTemplateListQueryVariables>(DefaultTemplateListDocument, baseOptions);
        }
export type DefaultTemplateListQueryHookResult = ReturnType<typeof useDefaultTemplateListQuery>;
export type DefaultTemplateListLazyQueryHookResult = ReturnType<typeof useDefaultTemplateListLazyQuery>;
export type DefaultTemplateListQueryResult = Apollo.QueryResult<DefaultTemplateListQuery, DefaultTemplateListQueryVariables>;
export const UpdateDefaultTemplateDocument = gql`
    mutation UpdateDefaultTemplate($input: UpdateDefaultTemplateInput!) {
  updateDefaultTemplate(input: $input) {
    defaultTemplate {
      ...DefaultTemplate
    }
  }
}
    ${DefaultTemplateFragmentDoc}`;
export type UpdateDefaultTemplateMutationFn = Apollo.MutationFunction<UpdateDefaultTemplateMutation, UpdateDefaultTemplateMutationVariables>;

/**
 * __useUpdateDefaultTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultTemplateMutation, { data, loading, error }] = useUpdateDefaultTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefaultTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDefaultTemplateMutation, UpdateDefaultTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDefaultTemplateMutation, UpdateDefaultTemplateMutationVariables>(UpdateDefaultTemplateDocument, baseOptions);
      }
export type UpdateDefaultTemplateMutationHookResult = ReturnType<typeof useUpdateDefaultTemplateMutation>;
export type UpdateDefaultTemplateMutationResult = Apollo.MutationResult<UpdateDefaultTemplateMutation>;
export type UpdateDefaultTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultTemplateMutation, UpdateDefaultTemplateMutationVariables>;
export const CreateLetterResetLogDocument = gql`
    mutation CreateLetterResetLog($input: LetterResetLogInput!) {
  createLetterResetLog(input: $input) {
    letterResetLog {
      brand {
        name
      }
    }
  }
}
    `;
export type CreateLetterResetLogMutationFn = Apollo.MutationFunction<CreateLetterResetLogMutation, CreateLetterResetLogMutationVariables>;

/**
 * __useCreateLetterResetLogMutation__
 *
 * To run a mutation, you first call `useCreateLetterResetLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLetterResetLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLetterResetLogMutation, { data, loading, error }] = useCreateLetterResetLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLetterResetLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLetterResetLogMutation, CreateLetterResetLogMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLetterResetLogMutation, CreateLetterResetLogMutationVariables>(CreateLetterResetLogDocument, baseOptions);
      }
export type CreateLetterResetLogMutationHookResult = ReturnType<typeof useCreateLetterResetLogMutation>;
export type CreateLetterResetLogMutationResult = Apollo.MutationResult<CreateLetterResetLogMutation>;
export type CreateLetterResetLogMutationOptions = Apollo.BaseMutationOptions<CreateLetterResetLogMutation, CreateLetterResetLogMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    customerUser {
      customer {
        brands {
          edges {
            node {
              id
              name
            }
          }
        }
        customerOnboardingStep {
          id
          status
          onboardingStep {
            uiRouteId
          }
        }
        id
        name
      }
    }
    userRoles {
      edges {
        node {
          role {
            id
            name
          }
          user {
            id
            email
            firstName
            lastName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoadBrandSellersDocument = gql`
    mutation LoadBrandSellers($input: LoadBrandSellersInput!) {
  loadBrandSellers(input: $input) {
    brandTask {
      id
      taskName
      status
    }
  }
}
    `;
export type LoadBrandSellersMutationFn = Apollo.MutationFunction<LoadBrandSellersMutation, LoadBrandSellersMutationVariables>;

/**
 * __useLoadBrandSellersMutation__
 *
 * To run a mutation, you first call `useLoadBrandSellersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadBrandSellersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadBrandSellersMutation, { data, loading, error }] = useLoadBrandSellersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadBrandSellersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoadBrandSellersMutation, LoadBrandSellersMutationVariables>) {
        return ApolloReactHooks.useMutation<LoadBrandSellersMutation, LoadBrandSellersMutationVariables>(LoadBrandSellersDocument, baseOptions);
      }
export type LoadBrandSellersMutationHookResult = ReturnType<typeof useLoadBrandSellersMutation>;
export type LoadBrandSellersMutationResult = Apollo.MutationResult<LoadBrandSellersMutation>;
export type LoadBrandSellersMutationOptions = Apollo.BaseMutationOptions<LoadBrandSellersMutation, LoadBrandSellersMutationVariables>;
export const UploadProductFileDocument = gql`
    mutation UploadProductFile($input: UploadProductFileInput!) {
  uploadProductFile(input: $input) {
    success
    brandId
    errorMessage
  }
}
    `;
export type UploadProductFileMutationFn = Apollo.MutationFunction<UploadProductFileMutation, UploadProductFileMutationVariables>;

/**
 * __useUploadProductFileMutation__
 *
 * To run a mutation, you first call `useUploadProductFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProductFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProductFileMutation, { data, loading, error }] = useUploadProductFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadProductFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProductFileMutation, UploadProductFileMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadProductFileMutation, UploadProductFileMutationVariables>(UploadProductFileDocument, baseOptions);
      }
export type UploadProductFileMutationHookResult = ReturnType<typeof useUploadProductFileMutation>;
export type UploadProductFileMutationResult = Apollo.MutationResult<UploadProductFileMutation>;
export type UploadProductFileMutationOptions = Apollo.BaseMutationOptions<UploadProductFileMutation, UploadProductFileMutationVariables>;
export const GetOnboardingFilesUrlsDocument = gql`
    query GetOnboardingFilesUrls($brandId: String!) {
  getOnboardingFilesUrls(brandId: $brandId) {
    fileName
    signedUrl
  }
}
    `;

/**
 * __useGetOnboardingFilesUrlsQuery__
 *
 * To run a query within a React component, call `useGetOnboardingFilesUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingFilesUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingFilesUrlsQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useGetOnboardingFilesUrlsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetOnboardingFilesUrlsQuery, GetOnboardingFilesUrlsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOnboardingFilesUrlsQuery, GetOnboardingFilesUrlsQueryVariables>(GetOnboardingFilesUrlsDocument, baseOptions);
      }
export function useGetOnboardingFilesUrlsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOnboardingFilesUrlsQuery, GetOnboardingFilesUrlsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOnboardingFilesUrlsQuery, GetOnboardingFilesUrlsQueryVariables>(GetOnboardingFilesUrlsDocument, baseOptions);
        }
export type GetOnboardingFilesUrlsQueryHookResult = ReturnType<typeof useGetOnboardingFilesUrlsQuery>;
export type GetOnboardingFilesUrlsLazyQueryHookResult = ReturnType<typeof useGetOnboardingFilesUrlsLazyQuery>;
export type GetOnboardingFilesUrlsQueryResult = Apollo.QueryResult<GetOnboardingFilesUrlsQuery, GetOnboardingFilesUrlsQueryVariables>;
export const ApproveBrandLetterDocument = gql`
    mutation ApproveBrandLetter($input: ApproveBrandLetterInput!) {
  approveBrandLetter(input: $input) {
    brandLetter {
      id
      approvalStatus
      stage
    }
  }
}
    `;
export type ApproveBrandLetterMutationFn = Apollo.MutationFunction<ApproveBrandLetterMutation, ApproveBrandLetterMutationVariables>;

/**
 * __useApproveBrandLetterMutation__
 *
 * To run a mutation, you first call `useApproveBrandLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveBrandLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveBrandLetterMutation, { data, loading, error }] = useApproveBrandLetterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveBrandLetterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveBrandLetterMutation, ApproveBrandLetterMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveBrandLetterMutation, ApproveBrandLetterMutationVariables>(ApproveBrandLetterDocument, baseOptions);
      }
export type ApproveBrandLetterMutationHookResult = ReturnType<typeof useApproveBrandLetterMutation>;
export type ApproveBrandLetterMutationResult = Apollo.MutationResult<ApproveBrandLetterMutation>;
export type ApproveBrandLetterMutationOptions = Apollo.BaseMutationOptions<ApproveBrandLetterMutation, ApproveBrandLetterMutationVariables>;
export const CreateBrandLetterRevisionDocument = gql`
    mutation CreateBrandLetterRevision($input: BrandLetterRevisionInput!) {
  createBrandLetterRevision(input: $input) {
    brandLetterRevision {
      brandLetterId
      templateRevision
      id
    }
  }
}
    `;
export type CreateBrandLetterRevisionMutationFn = Apollo.MutationFunction<CreateBrandLetterRevisionMutation, CreateBrandLetterRevisionMutationVariables>;

/**
 * __useCreateBrandLetterRevisionMutation__
 *
 * To run a mutation, you first call `useCreateBrandLetterRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandLetterRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandLetterRevisionMutation, { data, loading, error }] = useCreateBrandLetterRevisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandLetterRevisionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBrandLetterRevisionMutation, CreateBrandLetterRevisionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBrandLetterRevisionMutation, CreateBrandLetterRevisionMutationVariables>(CreateBrandLetterRevisionDocument, baseOptions);
      }
export type CreateBrandLetterRevisionMutationHookResult = ReturnType<typeof useCreateBrandLetterRevisionMutation>;
export type CreateBrandLetterRevisionMutationResult = Apollo.MutationResult<CreateBrandLetterRevisionMutation>;
export type CreateBrandLetterRevisionMutationOptions = Apollo.BaseMutationOptions<CreateBrandLetterRevisionMutation, CreateBrandLetterRevisionMutationVariables>;
export const GetBrandLetterDocument = gql`
    query GetBrandLetter($id: ID!) {
  brand(id: $id) {
    id
    name
    brandLetters(filters: {letterFormat: IMAGE}, sort: STAGE_ASC) {
      edges {
        node {
          id
          stage
          template
          approvalStatus
          brandLetterRevision(sort: CREATED_DESC, first: 1) {
            edges {
              node {
                id
                templateRevision
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBrandLetterQuery__
 *
 * To run a query within a React component, call `useGetBrandLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandLetterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBrandLetterQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetBrandLetterQuery, GetBrandLetterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBrandLetterQuery, GetBrandLetterQueryVariables>(GetBrandLetterDocument, baseOptions);
      }
export function useGetBrandLetterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBrandLetterQuery, GetBrandLetterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBrandLetterQuery, GetBrandLetterQueryVariables>(GetBrandLetterDocument, baseOptions);
        }
export type GetBrandLetterQueryHookResult = ReturnType<typeof useGetBrandLetterQuery>;
export type GetBrandLetterLazyQueryHookResult = ReturnType<typeof useGetBrandLetterLazyQuery>;
export type GetBrandLetterQueryResult = Apollo.QueryResult<GetBrandLetterQuery, GetBrandLetterQueryVariables>;
export const UploadLetterHeadMutationDocument = gql`
    mutation UploadLetterHeadMutation($input: UploadLetterHeadInput!) {
  uploadLetterHead(input: $input) {
    ok
  }
}
    `;
export type UploadLetterHeadMutationMutationFn = Apollo.MutationFunction<UploadLetterHeadMutationMutation, UploadLetterHeadMutationMutationVariables>;

/**
 * __useUploadLetterHeadMutationMutation__
 *
 * To run a mutation, you first call `useUploadLetterHeadMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadLetterHeadMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadLetterHeadMutationMutation, { data, loading, error }] = useUploadLetterHeadMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadLetterHeadMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadLetterHeadMutationMutation, UploadLetterHeadMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadLetterHeadMutationMutation, UploadLetterHeadMutationMutationVariables>(UploadLetterHeadMutationDocument, baseOptions);
      }
export type UploadLetterHeadMutationMutationHookResult = ReturnType<typeof useUploadLetterHeadMutationMutation>;
export type UploadLetterHeadMutationMutationResult = Apollo.MutationResult<UploadLetterHeadMutationMutation>;
export type UploadLetterHeadMutationMutationOptions = Apollo.BaseMutationOptions<UploadLetterHeadMutationMutation, UploadLetterHeadMutationMutationVariables>;
export const CreateSellerDocument = gql`
    mutation CreateSeller($input: CreateSellerInput!) {
  createSeller(input: $input) {
    seller {
      id
      name
      url
      externalId
      email
      sellerMarketplaces {
        edges {
          node {
            id
            authorized
            contactSeller
          }
        }
      }
    }
  }
}
    `;
export type CreateSellerMutationFn = Apollo.MutationFunction<CreateSellerMutation, CreateSellerMutationVariables>;

/**
 * __useCreateSellerMutation__
 *
 * To run a mutation, you first call `useCreateSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSellerMutation, { data, loading, error }] = useCreateSellerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSellerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSellerMutation, CreateSellerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSellerMutation, CreateSellerMutationVariables>(CreateSellerDocument, baseOptions);
      }
export type CreateSellerMutationHookResult = ReturnType<typeof useCreateSellerMutation>;
export type CreateSellerMutationResult = Apollo.MutationResult<CreateSellerMutation>;
export type CreateSellerMutationOptions = Apollo.BaseMutationOptions<CreateSellerMutation, CreateSellerMutationVariables>;
export const FetchRecentBrandTaskDocument = gql`
    query FetchRecentBrandTask($id: ID!) {
  brand(id: $id) {
    id
    brandTasks(
      filters: {taskName: LOAD_BRAND_SELLERS}
      sort: CREATED_DESC
      first: 1
    ) {
      edges {
        node {
          id
          status
          errors
        }
      }
    }
  }
}
    `;

/**
 * __useFetchRecentBrandTaskQuery__
 *
 * To run a query within a React component, call `useFetchRecentBrandTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRecentBrandTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRecentBrandTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchRecentBrandTaskQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FetchRecentBrandTaskQuery, FetchRecentBrandTaskQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchRecentBrandTaskQuery, FetchRecentBrandTaskQueryVariables>(FetchRecentBrandTaskDocument, baseOptions);
      }
export function useFetchRecentBrandTaskLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchRecentBrandTaskQuery, FetchRecentBrandTaskQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchRecentBrandTaskQuery, FetchRecentBrandTaskQueryVariables>(FetchRecentBrandTaskDocument, baseOptions);
        }
export type FetchRecentBrandTaskQueryHookResult = ReturnType<typeof useFetchRecentBrandTaskQuery>;
export type FetchRecentBrandTaskLazyQueryHookResult = ReturnType<typeof useFetchRecentBrandTaskLazyQuery>;
export type FetchRecentBrandTaskQueryResult = Apollo.QueryResult<FetchRecentBrandTaskQuery, FetchRecentBrandTaskQueryVariables>;
export const FetchSellersOfABrandDocument = gql`
    query FetchSellersOfABrand($id: ID!, $first: Int, $after: String, $before: String) {
  brand(id: $id) {
    id
    sellerMarketplaces(
      first: $first
      after: $after
      before: $before
      sort: CREATED_DESC
    ) {
      edges {
        node {
          id
          seller {
            name
            id
          }
          contactSeller
          authorized
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    `;

/**
 * __useFetchSellersOfABrandQuery__
 *
 * To run a query within a React component, call `useFetchSellersOfABrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSellersOfABrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSellersOfABrandQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useFetchSellersOfABrandQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FetchSellersOfABrandQuery, FetchSellersOfABrandQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchSellersOfABrandQuery, FetchSellersOfABrandQueryVariables>(FetchSellersOfABrandDocument, baseOptions);
      }
export function useFetchSellersOfABrandLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchSellersOfABrandQuery, FetchSellersOfABrandQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchSellersOfABrandQuery, FetchSellersOfABrandQueryVariables>(FetchSellersOfABrandDocument, baseOptions);
        }
export type FetchSellersOfABrandQueryHookResult = ReturnType<typeof useFetchSellersOfABrandQuery>;
export type FetchSellersOfABrandLazyQueryHookResult = ReturnType<typeof useFetchSellersOfABrandLazyQuery>;
export type FetchSellersOfABrandQueryResult = Apollo.QueryResult<FetchSellersOfABrandQuery, FetchSellersOfABrandQueryVariables>;
export const UpdateSellerMarketplaceDocument = gql`
    mutation UpdateSellerMarketplace($input: UpdateSellerMarketplaceInput!) {
  updateSellerMarketplace(input: $input) {
    sellerMarketplace {
      seller {
        id
        name
      }
      authorized
      contactSeller
    }
  }
}
    `;
export type UpdateSellerMarketplaceMutationFn = Apollo.MutationFunction<UpdateSellerMarketplaceMutation, UpdateSellerMarketplaceMutationVariables>;

/**
 * __useUpdateSellerMarketplaceMutation__
 *
 * To run a mutation, you first call `useUpdateSellerMarketplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSellerMarketplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSellerMarketplaceMutation, { data, loading, error }] = useUpdateSellerMarketplaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSellerMarketplaceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSellerMarketplaceMutation, UpdateSellerMarketplaceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSellerMarketplaceMutation, UpdateSellerMarketplaceMutationVariables>(UpdateSellerMarketplaceDocument, baseOptions);
      }
export type UpdateSellerMarketplaceMutationHookResult = ReturnType<typeof useUpdateSellerMarketplaceMutation>;
export type UpdateSellerMarketplaceMutationResult = Apollo.MutationResult<UpdateSellerMarketplaceMutation>;
export type UpdateSellerMarketplaceMutationOptions = Apollo.BaseMutationOptions<UpdateSellerMarketplaceMutation, UpdateSellerMarketplaceMutationVariables>;
export const CreateProductFileDocument = gql`
    mutation CreateProductFile($input: CreateFileDownloadInput!) {
  createProductFile(input: $input) {
    success
  }
}
    `;
export type CreateProductFileMutationFn = Apollo.MutationFunction<CreateProductFileMutation, CreateProductFileMutationVariables>;

/**
 * __useCreateProductFileMutation__
 *
 * To run a mutation, you first call `useCreateProductFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductFileMutation, { data, loading, error }] = useCreateProductFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductFileMutation, CreateProductFileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductFileMutation, CreateProductFileMutationVariables>(CreateProductFileDocument, baseOptions);
      }
export type CreateProductFileMutationHookResult = ReturnType<typeof useCreateProductFileMutation>;
export type CreateProductFileMutationResult = Apollo.MutationResult<CreateProductFileMutation>;
export type CreateProductFileMutationOptions = Apollo.BaseMutationOptions<CreateProductFileMutation, CreateProductFileMutationVariables>;
export const CreateAuthorizedSellersFileDocument = gql`
    mutation CreateAuthorizedSellersFile($input: CreateFileDownloadInput!) {
  createAuthorizedSellersFile(input: $input) {
    success
  }
}
    `;
export type CreateAuthorizedSellersFileMutationFn = Apollo.MutationFunction<CreateAuthorizedSellersFileMutation, CreateAuthorizedSellersFileMutationVariables>;

/**
 * __useCreateAuthorizedSellersFileMutation__
 *
 * To run a mutation, you first call `useCreateAuthorizedSellersFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthorizedSellersFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthorizedSellersFileMutation, { data, loading, error }] = useCreateAuthorizedSellersFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuthorizedSellersFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAuthorizedSellersFileMutation, CreateAuthorizedSellersFileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAuthorizedSellersFileMutation, CreateAuthorizedSellersFileMutationVariables>(CreateAuthorizedSellersFileDocument, baseOptions);
      }
export type CreateAuthorizedSellersFileMutationHookResult = ReturnType<typeof useCreateAuthorizedSellersFileMutation>;
export type CreateAuthorizedSellersFileMutationResult = Apollo.MutationResult<CreateAuthorizedSellersFileMutation>;
export type CreateAuthorizedSellersFileMutationOptions = Apollo.BaseMutationOptions<CreateAuthorizedSellersFileMutation, CreateAuthorizedSellersFileMutationVariables>;
export const CreateApprovedLetterFileDocument = gql`
    mutation CreateApprovedLetterFile($input: CreateFileDownloadInput!) {
  createApprovedLetterFile(input: $input) {
    success
  }
}
    `;
export type CreateApprovedLetterFileMutationFn = Apollo.MutationFunction<CreateApprovedLetterFileMutation, CreateApprovedLetterFileMutationVariables>;

/**
 * __useCreateApprovedLetterFileMutation__
 *
 * To run a mutation, you first call `useCreateApprovedLetterFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovedLetterFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovedLetterFileMutation, { data, loading, error }] = useCreateApprovedLetterFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApprovedLetterFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApprovedLetterFileMutation, CreateApprovedLetterFileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApprovedLetterFileMutation, CreateApprovedLetterFileMutationVariables>(CreateApprovedLetterFileDocument, baseOptions);
      }
export type CreateApprovedLetterFileMutationHookResult = ReturnType<typeof useCreateApprovedLetterFileMutation>;
export type CreateApprovedLetterFileMutationResult = Apollo.MutationResult<CreateApprovedLetterFileMutation>;
export type CreateApprovedLetterFileMutationOptions = Apollo.BaseMutationOptions<CreateApprovedLetterFileMutation, CreateApprovedLetterFileMutationVariables>;
export const CreatePriceSheetFileDocument = gql`
    mutation CreatePriceSheetFile($input: CreateFileDownloadInput!) {
  createPriceSheetFile(input: $input) {
    success
  }
}
    `;
export type CreatePriceSheetFileMutationFn = Apollo.MutationFunction<CreatePriceSheetFileMutation, CreatePriceSheetFileMutationVariables>;

/**
 * __useCreatePriceSheetFileMutation__
 *
 * To run a mutation, you first call `useCreatePriceSheetFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePriceSheetFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPriceSheetFileMutation, { data, loading, error }] = useCreatePriceSheetFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePriceSheetFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePriceSheetFileMutation, CreatePriceSheetFileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePriceSheetFileMutation, CreatePriceSheetFileMutationVariables>(CreatePriceSheetFileDocument, baseOptions);
      }
export type CreatePriceSheetFileMutationHookResult = ReturnType<typeof useCreatePriceSheetFileMutation>;
export type CreatePriceSheetFileMutationResult = Apollo.MutationResult<CreatePriceSheetFileMutation>;
export type CreatePriceSheetFileMutationOptions = Apollo.BaseMutationOptions<CreatePriceSheetFileMutation, CreatePriceSheetFileMutationVariables>;
export const CreateTermsAndConditionsFileDocument = gql`
    mutation CreateTermsAndConditionsFile($input: CreateFileDownloadInput!) {
  createTermsAndConditionsFile(input: $input) {
    success
  }
}
    `;
export type CreateTermsAndConditionsFileMutationFn = Apollo.MutationFunction<CreateTermsAndConditionsFileMutation, CreateTermsAndConditionsFileMutationVariables>;

/**
 * __useCreateTermsAndConditionsFileMutation__
 *
 * To run a mutation, you first call `useCreateTermsAndConditionsFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTermsAndConditionsFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTermsAndConditionsFileMutation, { data, loading, error }] = useCreateTermsAndConditionsFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTermsAndConditionsFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTermsAndConditionsFileMutation, CreateTermsAndConditionsFileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTermsAndConditionsFileMutation, CreateTermsAndConditionsFileMutationVariables>(CreateTermsAndConditionsFileDocument, baseOptions);
      }
export type CreateTermsAndConditionsFileMutationHookResult = ReturnType<typeof useCreateTermsAndConditionsFileMutation>;
export type CreateTermsAndConditionsFileMutationResult = Apollo.MutationResult<CreateTermsAndConditionsFileMutation>;
export type CreateTermsAndConditionsFileMutationOptions = Apollo.BaseMutationOptions<CreateTermsAndConditionsFileMutation, CreateTermsAndConditionsFileMutationVariables>;
export const GetBrandStatusDocument = gql`
    query GetBrandStatus($id: ID!) {
  brand(id: $id) {
    id
    approvedImpactLetter
    addedProducts
    sellersFound
    letterheadUploaded
  }
}
    `;

/**
 * __useGetBrandStatusQuery__
 *
 * To run a query within a React component, call `useGetBrandStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBrandStatusQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetBrandStatusQuery, GetBrandStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBrandStatusQuery, GetBrandStatusQueryVariables>(GetBrandStatusDocument, baseOptions);
      }
export function useGetBrandStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBrandStatusQuery, GetBrandStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBrandStatusQuery, GetBrandStatusQueryVariables>(GetBrandStatusDocument, baseOptions);
        }
export type GetBrandStatusQueryHookResult = ReturnType<typeof useGetBrandStatusQuery>;
export type GetBrandStatusLazyQueryHookResult = ReturnType<typeof useGetBrandStatusLazyQuery>;
export type GetBrandStatusQueryResult = Apollo.QueryResult<GetBrandStatusQuery, GetBrandStatusQueryVariables>;
export const GetCustomerTermsAndConditionsDocument = gql`
    query GetCustomerTermsAndConditions($id: ID!) {
  customer(id: $id) {
    id
    name
    email
    customerTermsAndCondition {
      id
      termsAndConditions {
        id
        name
        content
      }
    }
  }
}
    `;

/**
 * __useGetCustomerTermsAndConditionsQuery__
 *
 * To run a query within a React component, call `useGetCustomerTermsAndConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerTermsAndConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerTermsAndConditionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerTermsAndConditionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCustomerTermsAndConditionsQuery, GetCustomerTermsAndConditionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerTermsAndConditionsQuery, GetCustomerTermsAndConditionsQueryVariables>(GetCustomerTermsAndConditionsDocument, baseOptions);
      }
export function useGetCustomerTermsAndConditionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerTermsAndConditionsQuery, GetCustomerTermsAndConditionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerTermsAndConditionsQuery, GetCustomerTermsAndConditionsQueryVariables>(GetCustomerTermsAndConditionsDocument, baseOptions);
        }
export type GetCustomerTermsAndConditionsQueryHookResult = ReturnType<typeof useGetCustomerTermsAndConditionsQuery>;
export type GetCustomerTermsAndConditionsLazyQueryHookResult = ReturnType<typeof useGetCustomerTermsAndConditionsLazyQuery>;
export type GetCustomerTermsAndConditionsQueryResult = Apollo.QueryResult<GetCustomerTermsAndConditionsQuery, GetCustomerTermsAndConditionsQueryVariables>;
export const GetIpViolationListDocument = gql`
    query GetIPViolationList {
  ipViolationList {
    edges {
      node {
        id
        content
      }
    }
  }
}
    `;

/**
 * __useGetIpViolationListQuery__
 *
 * To run a query within a React component, call `useGetIpViolationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIpViolationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIpViolationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIpViolationListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIpViolationListQuery, GetIpViolationListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIpViolationListQuery, GetIpViolationListQueryVariables>(GetIpViolationListDocument, baseOptions);
      }
export function useGetIpViolationListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIpViolationListQuery, GetIpViolationListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIpViolationListQuery, GetIpViolationListQueryVariables>(GetIpViolationListDocument, baseOptions);
        }
export type GetIpViolationListQueryHookResult = ReturnType<typeof useGetIpViolationListQuery>;
export type GetIpViolationListLazyQueryHookResult = ReturnType<typeof useGetIpViolationListLazyQuery>;
export type GetIpViolationListQueryResult = Apollo.QueryResult<GetIpViolationListQuery, GetIpViolationListQueryVariables>;
export const UpdateCustomerViolationSettingDocument = gql`
    mutation UpdateCustomerViolationSetting($input: UpdateCustomerViolationSettingInput!) {
  updateCustomerViolationSetting(input: $input) {
    customer {
      ipViolation {
        id
        content
      }
    }
  }
}
    `;
export type UpdateCustomerViolationSettingMutationFn = Apollo.MutationFunction<UpdateCustomerViolationSettingMutation, UpdateCustomerViolationSettingMutationVariables>;

/**
 * __useUpdateCustomerViolationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerViolationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerViolationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerViolationSettingMutation, { data, loading, error }] = useUpdateCustomerViolationSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerViolationSettingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerViolationSettingMutation, UpdateCustomerViolationSettingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerViolationSettingMutation, UpdateCustomerViolationSettingMutationVariables>(UpdateCustomerViolationSettingDocument, baseOptions);
      }
export type UpdateCustomerViolationSettingMutationHookResult = ReturnType<typeof useUpdateCustomerViolationSettingMutation>;
export type UpdateCustomerViolationSettingMutationResult = Apollo.MutationResult<UpdateCustomerViolationSettingMutation>;
export type UpdateCustomerViolationSettingMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerViolationSettingMutation, UpdateCustomerViolationSettingMutationVariables>;
export const AdvanceCustomerDocument = gql`
    mutation AdvanceCustomer($input: AdvanceCustomerOnboardingStepInput!) {
  advanceCustomerOnboardingStep(input: $input) {
    customer {
      id
      name
    }
  }
}
    `;
export type AdvanceCustomerMutationFn = Apollo.MutationFunction<AdvanceCustomerMutation, AdvanceCustomerMutationVariables>;

/**
 * __useAdvanceCustomerMutation__
 *
 * To run a mutation, you first call `useAdvanceCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvanceCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advanceCustomerMutation, { data, loading, error }] = useAdvanceCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvanceCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvanceCustomerMutation, AdvanceCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvanceCustomerMutation, AdvanceCustomerMutationVariables>(AdvanceCustomerDocument, baseOptions);
      }
export type AdvanceCustomerMutationHookResult = ReturnType<typeof useAdvanceCustomerMutation>;
export type AdvanceCustomerMutationResult = Apollo.MutationResult<AdvanceCustomerMutation>;
export type AdvanceCustomerMutationOptions = Apollo.BaseMutationOptions<AdvanceCustomerMutation, AdvanceCustomerMutationVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($id: ID!) {
  customer(id: $id) {
    id
    name
    customerOnboardingStep {
      id
      status
      onboardingStep {
        uiRouteId
      }
    }
    ipViolation {
      id
    }
    customerContract {
      id
      customerId
      term
      setupFee
      monthlyFee
    }
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
      }
export function useGetCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const UpdateCustomerOnboardingStatusDocument = gql`
    mutation UpdateCustomerOnboardingStatus($input: UpdateCustomerOnboardingStatusInput!) {
  updateCustomerOnboardingStatus(input: $input) {
    customer {
      id
      customerOnboardingStep {
        id
        onboardingStepId
        customerId
        status
        onboardingStep {
          uiRouteId
        }
      }
    }
  }
}
    `;
export type UpdateCustomerOnboardingStatusMutationFn = Apollo.MutationFunction<UpdateCustomerOnboardingStatusMutation, UpdateCustomerOnboardingStatusMutationVariables>;

/**
 * __useUpdateCustomerOnboardingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerOnboardingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerOnboardingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerOnboardingStatusMutation, { data, loading, error }] = useUpdateCustomerOnboardingStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerOnboardingStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerOnboardingStatusMutation, UpdateCustomerOnboardingStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerOnboardingStatusMutation, UpdateCustomerOnboardingStatusMutationVariables>(UpdateCustomerOnboardingStatusDocument, baseOptions);
      }
export type UpdateCustomerOnboardingStatusMutationHookResult = ReturnType<typeof useUpdateCustomerOnboardingStatusMutation>;
export type UpdateCustomerOnboardingStatusMutationResult = Apollo.MutationResult<UpdateCustomerOnboardingStatusMutation>;
export type UpdateCustomerOnboardingStatusMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerOnboardingStatusMutation, UpdateCustomerOnboardingStatusMutationVariables>;