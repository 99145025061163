/* Function to calculate file size format.
  Taken from https://stackoverflow.com/a/18650828/1682304 */

import { LetterApprovalStatus } from '../graphql';

// eslint-disable-next-line import/prefer-default-export
export const bytesToSize = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024; // Can be changed to 1000
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/*
  customerID setter function
 */
export const setCustomerID = (customerID: string) => {
  localStorage.setItem('customerID', JSON.stringify(customerID));
};

/*
  customerID getter function
 */
export const getCustomerID = () => {
  const customerID = localStorage.getItem('customerID');
  return JSON.parse(customerID!);
};

/* setBrandID to set brandID received from response
  of /me query.
 */
export const setBrandID = (brandID: string) => {
  localStorage.setItem('brandID', JSON.stringify(brandID));
};

export const getBrandID = () => {
  const brandID = localStorage.getItem('brandID');
  return JSON.parse(brandID!);
};

export const getBrandLetterApprovalStatus = (
  approvalStatus: LetterApprovalStatus,
): string => {
  /* eslint-disable indent */
  switch (approvalStatus) {
    case LetterApprovalStatus.Approved:
      return 'Approved';
    case LetterApprovalStatus.Generated:
      return 'Action Required';
    case LetterApprovalStatus.ReviewRequested:
      return 'Revision Requested';
    case LetterApprovalStatus.RevisionApplied:
      return 'Revision Applied';
    default:
      return '';
  }
};
