// eslint-disable-next-line react/no-children-prop
import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  useToast,
} from '@chakra-ui/core';
import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ButtonTypes,
  Input,
  Loader,
  LoaderTypes,
  Text,
  TextTypes,
} from '../../../../components';
import {
  useCreateCustomerContractMutation,
  useUpdateCustomerContractMutation,
} from '../../../../graphql';
import { CC_SETUP_AND_MONTHLY_FEE_PATTERN } from '../../../../utils/regex';
import {
  CC_MONTHLY_AND_SETUP_FEE_VALIDATION_MSG,
} from '../../../../variables/contants';

interface ICustomerContractsProps {
  isCreateCustomer: boolean,
  customerId: string,
  isLoading: boolean,
  term: number | null | undefined,
  setupFee: number | null | undefined,
  monthlyFee: number | null | undefined,
  refetch: () => void,
}

const CustomerContracts = ({
  isCreateCustomer,
  customerId,
  isLoading,
  term,
  setupFee,
  monthlyFee,
  refetch,
}: ICustomerContractsProps) => {
  const {
    register,
    handleSubmit,
    errors,
  } = useForm<{
    initialTerm: number,
    setupFee: number,
    monthlyFee: number,
  }>();

  const toast = useToast();

  const [
    createCustomerContractMutation,
    {
      loading: createCustomerContractLoading,
      error: createCustomerContractError
      ,
    },
  ] = useCreateCustomerContractMutation();

  const [updateCutomerContractMutation, {
    loading: updateCutomerContractLoading,
    error: updateCustomerContractError,
  }] = useUpdateCustomerContractMutation();

  const handleCreateCustomerContract = async (
    formValues: Record<string, number>,
  ) => {
    try {
      await createCustomerContractMutation({
        variables: {
          input: {
            id: customerId,
            setupFee: Number(formValues.setupFee),
            term: Number(formValues.initialTerm),
            monthlyFee: Number(formValues.monthlyFee),
          },
        },
      });
      toast({
        title: 'Customer Contract Created Successfully!',
        status: 'success',
      });
      refetch();
    } catch {
      toast({
        title: 'Error!',
        description: createCustomerContractError?.message,
        status: 'error',
      });
    }
  };

  const handleUpdateCustomerContract = async (
    formValues: Record<string, number>,
  ) => {
    try {
      await updateCutomerContractMutation({
        variables: {
          input: {
            id: customerId,
            setupFee: Number(formValues.setupFee),
            term: Number(formValues.initialTerm),
            monthlyFee: Number(formValues.monthlyFee),
          },
        },
      });
      toast({
        title: 'Customer Contract Updated Successfully!',
        status: 'success',
      });
      refetch();
    } catch {
      toast({
        title: 'Error!',
        description: updateCustomerContractError?.message,
        status: 'error',
      });
    }
  };

  const handleCustomerContracts = async (formValues:Record<string, number>) => {
    if (isCreateCustomer) {
      handleCreateCustomerContract(formValues);
    } else {
      handleUpdateCustomerContract(formValues);
    }
  };

  return (
    <Box width="100%">
      <Flex
        alignItems="center"
        marginBottom={5}
      >
        <Text
          type={TextTypes.header}
        >
          Customers Contracts
        </Text>
      </Flex>
      <Box
        // width="50%"
        flexGrow={1}
      >
        {
          isLoading ? (
            <Loader type={LoaderTypes.Content} />
          ) : (
            <form
              noValidate
              onSubmit={
                handleSubmit(handleCustomerContracts)
              }
              style={{
                width: '100%',
              }}
            >
              <FormControl
                marginBottom={4}
                isRequired={
                  Boolean(!term)
                }
              >
                <FormLabel
                  htmlFor="intialTerm"
                  aria-required={
                    Boolean(!term)
                  }
                >
                  Initial Term (In months)
                </FormLabel>
                <Input
                  isDisabled={
                    createCustomerContractLoading
                    || updateCutomerContractLoading
                  }
                  type="number"
                  ref={
                    register({
                      required: Boolean(!term),
                      pattern: {
                        value: /^\d+$/,
                        message: `Enter whole numbers only.
                        (For e.g. 1, 100, 25 etc.)`,
                      },
                    })
                  }
                  id="intialTerm"
                  name="initialTerm"
                  defaultValue={term ?? ''}
                />
                {errors.initialTerm && (
                  <FormHelperText fontSize="xs" id="setup-fee-helper-text">
                    {errors.initialTerm.message}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                marginBottom={4}
                isRequired={
                  Boolean(!setupFee)
                }
              >
                <FormLabel
                  htmlFor="setupFee"
                  aria-required={
                    Boolean(!setupFee)
                  }
                >
                  Setup Fee
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon>$</InputLeftAddon>
                  <Input
                    isDisabled={
                      createCustomerContractLoading
                      || updateCutomerContractLoading
                    }
                    type="number
                    "
                    inputMode="decimal"
                    ref={
                      register({
                        required: Boolean(!setupFee),
                        pattern: {
                          value: CC_SETUP_AND_MONTHLY_FEE_PATTERN,
                          message: CC_MONTHLY_AND_SETUP_FEE_VALIDATION_MSG,
                        },
                      })
                    }
                    id="setupFee"
                    name="setupFee"
                    defaultValue={term ?? ''}
                  />
                </InputGroup>
                {errors.setupFee && (
                  <FormHelperText fontSize="xs" id="setup-fee-helper-text">
                    {errors.setupFee.message}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl marginBottom={4}>
                <FormLabel htmlFor="monthlyFee">
                  Monthly Fee
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon>$</InputLeftAddon>
                  <Input
                    isDisabled={
                      createCustomerContractLoading
                      || updateCutomerContractLoading
                    }
                    type="number
                    "
                    inputMode="decimal"
                    ref={
                      register({
                        required: false,
                        pattern: {
                          value: CC_SETUP_AND_MONTHLY_FEE_PATTERN,
                          message: CC_MONTHLY_AND_SETUP_FEE_VALIDATION_MSG,
                        },
                      })
                    }
                    id="monthlyFee"
                    name="monthlyFee"
                    defaultValue={monthlyFee ?? ''}
                  />
                </InputGroup>
                {
                  errors.monthlyFee && (
                    <FormHelperText fontSize="xs" id="monthly-fee-helper-text">
                      {errors.monthlyFee.message}
                    </FormHelperText>
                  )
                }
              </FormControl>
              <Flex
                justifyContent="center"
              >
                <Button
                  isLoading={
                    createCustomerContractLoading
                    || updateCutomerContractLoading
                  }
                  isDisabled={
                    createCustomerContractLoading
                    || updateCutomerContractLoading
                  }
                  type="submit"
                  buttonType={ButtonTypes.Primary}
                  marginBottom={6}
                  width="100%"
                  maxWidth="250px"
                >
                  {`${isCreateCustomer ? 'Create' : 'Update'} Contract`}
                </Button>
              </Flex>
            </form>
          )
        }
      </Box>
    </Box>
  );
};

export default memo(CustomerContracts);
